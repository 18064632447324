function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const LOADING_ITEM_KEY = '#LOADING#';
export class LoadingParams {
  constructor() {
    let {
      name = LOADING_ITEM_KEY,
      mode = ''
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    _defineProperty(this, "name", void 0);
    _defineProperty(this, "mode", void 0);
    _defineProperty(this, "global", void 0);
    this.name = name || LOADING_ITEM_KEY;
    this.global = name === LOADING_ITEM_KEY;
    this.mode = mode;
  }
}