import { useCallback, useEffect, useState } from 'react';
const useProgressBarMessage = (completed, messages) => {
  const [messageToShow, setMessageToShow] = useState(0);
  const handleMessageToShow = useCallback(() => {
    const newMessageIndex = messageToShow + 1;
    if (newMessageIndex === messages.length) {
      setMessageToShow(0);
      return;
    }
    setMessageToShow(newMessageIndex);
  }, [messageToShow, messages]);
  useEffect(() => {
    const interval = setInterval(handleMessageToShow, 4000);
    if (completed) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [handleMessageToShow, completed]);
  return {
    messageToShow
  };
};
export default useProgressBarMessage;