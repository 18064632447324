function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import nanoid from 'nanoid';
import PubSub from 'pubsub-js';
const NOTIFY_ENVS_UPDATE = '@assertiva-ui/@RuntimeEnvs/configure';

/**
 * @description permite configurar os valores das variáveis de ambiente em tempo de execução.
 *
 *  Obs: para que as mudanças tenham efeito na mesma hora que serão utilizadas  não armazenar
 *  os valores de Envs() em variáveis ou atributos de class, em vez disso chamar os valores diretamente das Evens()
 *  ou chamar através funções, exemplo:
 *
 *
 * Prefira:
 *
 *```js
 * getGroups = ({ customerId } = {}) => {
 *  return this.axiosInstance.get(
 *      `${Envs().CADASTRO_URL}/customers${prepareUrl(customerId)}/groups`
 *    );
 *  };
 *```
 *
 * ou isso
 *
 *```js
 * const getUrl = ()=> `${Envs().CADASTRO_URL}/customers`;
 * getGroups = ({ customerId } = {}) => {
 *  return this.axiosInstance.get(
 *      `${getUrl()${prepareUrl(customerId)}/groups`
 *    );
 *  };
 *```
 *
 * Em vez de:
 *
 * ```js
 * getGroups = ({ customerId } = {}) => {
 *    return this.axiosInstance.get(
 *      `${this.BASE_URL}/customers${prepareUrl(customerId)}/groups`
 *    );
 *  };
 *```
 *
 */
class RuntimeEnvs {
  static hasRuntimeEnvs() {
    return Boolean(Object.values(RuntimeEnvs.getEnvs()).length);
  }

  /**
   *
   * @returns devolve apenas as variares de tempo de execução.
   * Se deseja obter todas as variáveis estáticas e/ou as de tempo de execução usar Envs().
   */
  static getEnvs() {
    return this.runtimeEnvs;
  }

  /**
   *
   * @param environments
   * @returns promise
   */
  static configure(environments) {
    if (!environments) Promise.reject(new Error('[RuntimeEnvs.configure]: Environments is required!'));
    const envNames = Object.keys(environments);
    const newEnvs = {
      ...RuntimeEnvs.runtimeEnvs
    };
    for (let i = 0; i <= envNames.length; i++) {
      const name = envNames[i];
      const value = environments[name];
      if (value) {
        newEnvs[name] = value;
      }
    }
    RuntimeEnvs.runtimeEnvs = newEnvs;
    RuntimeEnvs.notify(newEnvs);
    return Promise.resolve(newEnvs);
  }
}
_defineProperty(RuntimeEnvs, "runtimeEnvs", {});
/**
 *
 * @param callback que recebe como argumento as variareis de ambientes modificadas e o notifyId que é um id que é um valor novo a cada chamada do RuntimeEnvs.configure({...})
 * @returns retorna uma subscription
 */
_defineProperty(RuntimeEnvs, "subscribe", callback => {
  return PubSub.subscribe(NOTIFY_ENVS_UPDATE, (_, data) => {
    callback(data, nanoid());
  });
});
/**
 *
 * @param subscription
 * @returns  boolean | string
 */
_defineProperty(RuntimeEnvs, "unsubscribe", subscription => {
  if (!subscription) return false;
  return PubSub.unsubscribe(subscription);
});
_defineProperty(RuntimeEnvs, "notify", environments => {
  return PubSub.publish(NOTIFY_ENVS_UPDATE, environments);
});
export default RuntimeEnvs;