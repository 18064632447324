import { styled } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { grey } from '@material-ui/core/colors';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
export const StyledAvatar = styled(Avatar)(() => ({
  height: 160,
  width: 160,
  color: grey[400],
  background: 'transparent'
}));
export const StyledAccountCircleIcon = styled(AccountCircleIcon)(() => ({
  width: '100%',
  height: '100%'
}));