export const VIEW_GROUP_COMPANY = 'ADMINISTRATIVO:GRUPOS:VISUALIZAR:EMPRESA';
export const EDIT_USERS_STATUS = ['ADMINISTRATIVO:USUARIOS:STATUS:EDITAR', 'BACKOFFICE:CLIENTES:USUARIOS:STATUS:EDITAR'];
export const CREATE_USER_COMPANY_LEVEL = 'ADMINISTRATIVO:USUARIOS:CRIAR:EMPRESA';
export const CREATE_USER_GROUP_LEVEL = 'ADMINISTRATIVO:USUARIOS:CRIAR:GRUPO';
export const PASSWORD_CHANGE_REQUEST = ['ADMINISTRATIVO:USUARIOS:TROCADESENHA:SOLICITAR', 'BACKOFFICE:CLIENTES:USUARIOS:TROCADESENHA:SOLICITAR'];
export const EDIT_USERS_COMPANY_LEVEL = 'ADMINISTRATIVO:USUARIOS:EDITAR:EMPRESA';
export const EDIT_USERS_GROUP_LEVEL = 'ADMINISTRATIVO:USUARIOS:EDITAR:GRUPO';
export const VIEW_PERMISSIONS = ['BACKOFFICE:CLIENTES:PERFIS:PERMISSOES:VISUALIZAR', 'ADMINISTRATIVO:PERFIS:PERMISSOES:VISUALIZAR:EMPRESA'];
export const EDIT_CLIENTS_USERS = 'BACKOFFICE:CLIENTES:USUARIOS:EDITAR';
export const EDIT_STATUS_CLIENTS_USERS = 'BACKOFFICE:CLIENTES:USUARIOS:STATUS:EDITAR';
export const CREATE_USERS = 'BACKOFFICE:CLIENTES:USUARIOS:CRIAR';

// Profiles
export const CREATE_PROFILES_BACKOFFICE_INTERNAL = 'BACKOFFICE:CLIENTES:PERFIS:CRIAR';
export const EDIT_PROFILE_BACKOFFICE_INTERNAL = 'BACKOFFICE:CLIENTES:PERFIS:EDITAR';
export const VIEW_PROFILE_BACKOFFICE_INTERNAL = 'BACKOFFICE:CLIENTES:PERFIS:VISUALIZAR';
export const CREATE_PROFILES_COMPANY_LEVEL = 'ADMINISTRATIVO:PERFIS:CRIAR:EMPRESA';
export const EDIT_PROFILE_COMPANY_LEVEL = 'ADMINISTRATIVO:PERFIS:EDITAR:EMPRESA';
export const VIEW_PROFILES_COMPANY_LEVEL = 'ADMINISTRATIVO:PERFIS:VISUALIZAR:EMPRESA';
export const VIEW_CREATE_OR_EDIT_PROFILE_COMPANY_LEVEL = [CREATE_PROFILES_COMPANY_LEVEL, EDIT_PROFILE_COMPANY_LEVEL, VIEW_PROFILES_COMPANY_LEVEL, VIEW_PROFILE_BACKOFFICE_INTERNAL, EDIT_PROFILE_BACKOFFICE_INTERNAL];
export const EDIT_PROFILE_USER_LEVEL = 'ADMINISTRATIVO:PERFIL:EDITAR:USUARIO';
export const VIEW_REPORT_PRODUCTS = ['BACKOFFICE:RELATORIOS:PRODUTOS:VISUALIZAR', 'RELATORIOS:PRODUTOS:VISUALIZAR'];
export const RESEND_LINK = 'ADMINISTRATIVO:USUARIOS:LINK:REENVIAR';
export const RESEND_LINK_INTERNAL = 'BACKOFFICE:CLIENTES:USUARIOS:LINK:REENVIAR';
export const DELETE_USER_BACKOFFICE = 'BACKOFFICE:CLIENTES:USUARIOS:EXCLUIR';
export const DELETE_USER_COMPANY_BACKOFFICE = 'ADMINISTRATIVO:USUARIOS:EXCLUIR:EMPRESA';
export const DELETE_USER_GROUP_BACKOFFICE = 'ADMINISTRATIVO:USUARIOS:EXCLUIR:GRUPO';
export const JOURNEY_PERMISSIONS = ['AUTENTICA:FLUXO:BASIC', 'AUTENTICA:FLUXO:COMPLETO', 'AUTENTICA:FLUXO:CONNECTION', 'AUTENTICA:SMS:ENVIAR'];
export const SINGATURE_PERMISSION = ['AUTENTICA:ASSINATURAS', 'AUTENTICA:ASSINATURAS:SMS'];
export const ADMINISTRATIVO_PRIVACY_SECURITY = 'ADMINISTRATIVO:PRIVACIDADESESEGURANCA:VISUALIZAR:USUARIO';
export const ADMINISTRATIVO_PRIVACY_SECURITY_REGISTER = 'ADMINISTRATIVO:PRIVACIDADESESEGURANCA:MFA:CADASTRAR:USUARIO';
export const ADMINISTRATIVO_GRUPOS_VISUALIZAR_GRUPO = 'ADMINISTRATIVO:GRUPOS:VISUALIZAR:GRUPO';
export const ADMINISTRATIVO_GRUPOS_VISUALIZAR_EMPRESA = 'ADMINISTRATIVO:GRUPOS:VISUALIZAR:EMPRESA';
export const ADMINISTRATIVO_USUARIOS_VISUALIZAR_EMPRESA = 'ADMINISTRATIVO:USUARIOS:VISUALIZAR:EMPRESA';
export const ADMINISTRATIVO_USUARIOS_VISUALIZAR_GRUPO = 'ADMINISTRATIVO:USUARIOS:VISUALIZAR:GRUPO';
export const GESTAODACONTA_RELATORIOS_VISUALIZAR = 'GESTAODACONTA:RELATORIOS:VISUALIZAR';
export const ADMINISTRATIVO_PERFIL_EDITAR_USUARIO = 'ADMINISTRATIVO:PERFIL:EDITAR:USUARIO';
export const FINANCEIRO_BOLETOENOTAFISCAL_VISUALIZAR = 'FINANCEIRO:BOLETOENOTAFISCAL:VISUALIZAR';
export const FINANCEIRO_SALDO_VISUALIZAR = 'FINANCEIRO:SALDO:VISUALIZAR';
export const FINANCEIRO_EXTRATO_VISUALIZAR = 'FINANCEIRO:EXTRATO:VISUALIZAR';