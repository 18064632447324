const css = `.assertiva-ui-icon {
  height: inherit;
  width: inherit;
}
.assertiva-ui-icon.x-small {
  height: 14px;
  width: 14px;
}
.assertiva-ui-icon.small {
  height: 18px;
  width: 18px;
}
.assertiva-ui-icon.medium > {
  height: 24px;
  width: 24px;
}
.assertiva-ui-icon.large {
  height: 36px;
  width: 36px;
}
.assertiva-ui-icon.x-large {
  height: 64px;
  width: 64px;
}
.assertiva-ui-icon > path {
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvcnVubmVyL3dvcmsvYXNzZXJ0aXZhLXVpL2Fzc2VydGl2YS11aS9zcmMvY29tcG9uZW50cy9JY29ucyIsInNvdXJjZXMiOlsiX0ljb25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
