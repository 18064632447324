var _TrialCookieUtils;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import Cookies from 'js-cookie';
import AuthUtils from 'utils/AuthUtils';
class TrialCookieUtils {}
_TrialCookieUtils = TrialCookieUtils;
_defineProperty(TrialCookieUtils, "getFullName", () => {
  const user = AuthUtils.getUser();
  return "trial-period-expired-displayed-user-".concat(user.id || '');
});
_defineProperty(TrialCookieUtils, "getDomainConfiguration", () => ({
  domain: '.assertivasolucoes.com.br'
}));
_defineProperty(TrialCookieUtils, "setDisplayedCookieInDomain", () => {
  Cookies.set(_TrialCookieUtils.getFullName(), 'true', _TrialCookieUtils.getDomainConfiguration());
});
_defineProperty(TrialCookieUtils, "getTrialPeriodExpiredAlertWasDisplayed", () => {
  const alertDisplayed = Cookies.get(_TrialCookieUtils.getFullName());
  return !!alertDisplayed;
});
_defineProperty(TrialCookieUtils, "removeCookieFromDomain", () => {
  Cookies.remove(_TrialCookieUtils.getFullName(), _TrialCookieUtils.getDomainConfiguration());
});
export default TrialCookieUtils;