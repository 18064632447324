import { styled } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
export const StyledSuccessDialog = styled(Dialog)(_ref => {
  let {
    theme
  } = _ref;
  return {
    '& .SuccessDialog-container': {
      padding: theme.spacing(1),
      overflowY: 'hidden',
      width: '570px',
      '& .SuccessDialog-content': {
        marginBottom: theme.spacing(5),
        '& .SuccessDialog-image': {
          width: '208px',
          height: '208px'
        }
      },
      '& .SuccessDialog-close-button': {
        marginRight: theme.spacing(1)
      }
    }
  };
});