const scrollToTop = () => {
  window.scrollTo(0, 0);
};
const scrollTo = function () {
  let selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let timetout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  const NAVBAR_OFFSET = 114;
  function execute() {
    const element = document.querySelector(selector);
    if (element) {
      window.scroll({
        top: element.offsetTop - NAVBAR_OFFSET,
        behavior: 'smooth'
      });
    }
  }
  if (timetout) {
    setTimeout(execute, timetout);
  } else {
    execute();
  }
};
const ScrollUtils = {
  scrollTo,
  scrollToTop
};
export default ScrollUtils;