import { useState, useRef } from 'react';
import DeviceDetector from 'device-detector-js';
import Notifier from 'components/Notification/Notifier';
import LoadingManager from 'components/Loading/LoadingManager';
import ErrorUtils from 'utils/ErrorUtils';
import ValidationUtils from 'utils/ValidationUtils';
import useRulesValidation from 'hooks/useRulesValidation';
import OAuth2Service from 'services/OAuth2Service';
const usePasswordRecovery = _ref => {
  let {
    onlyCpf,
    onSuccess,
    onError
  } = _ref;
  const detector = new DeviceDetector();
  const recaptchaRef = useRef(null);
  const [state, setState] = useState({
    document: '',
    email: ''
  });
  const device = detector.parse(navigator.userAgent);
  const deviceInfo = {
    ...device,
    device: {
      ...device.device,
      resolution: "".concat(window.screen.width, "x").concat(window.screen.height)
    }
  };
  const {
    errors,
    validate,
    clearError
  } = useRulesValidation([{
    field: 'document',
    messageError: "".concat(onlyCpf ? 'CPF' : 'Documento', " \xE9 obrigat\xF3rio."),
    hasErrors: () => !state.document
  }, {
    field: 'document',
    messageError: "Documento inv\xE1lido.",
    hasErrors: () => !ValidationUtils.isValidCnpj(state.document) && !ValidationUtils.isValidCpf(state.document)
  }, {
    field: 'email',
    messageError: 'E-mail é obrigatório.',
    hasErrors: () => !state.email
  }, {
    field: 'email',
    messageError: 'E-mail inválido.',
    hasErrors: () => !ValidationUtils.isValidEmail(state.email)
  }]);
  const handleState = (name, value) => {
    setState(prev => ({
      ...prev,
      [name]: value
    }));
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (validate() && recaptchaRef) {
      recaptchaRef.current.execute();
    }
  };
  const doSubmit = token => {
    LoadingManager.show();
    OAuth2Service.recoveryPassword({
      document: state.document,
      email: state.email,
      recaptcha: token,
      deviceInfo
    }).then(response => {
      setState({
        document: '',
        email: ''
      });
      onSuccess(response.data.message);
    }).catch(error => {
      const message = ErrorUtils.normalizeErrorMessage(error);
      Notifier.error(message);
      if (onError) onError(message);
    }).finally(() => {
      LoadingManager.close();
    });
  };
  return {
    state,
    errors,
    recaptchaRef,
    handleState,
    clearError,
    handleSubmit,
    doSubmit
  };
};
export default usePasswordRecovery;