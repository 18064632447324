import IntercomAdapter from 'adapters/IntercomAdapter';
import UserGuidingAdapter from 'adapters/UserGuidingAdapter';
const VENDORS = [{
  key: 'IntercomUserInfo',
  adapter: IntercomAdapter,
  availableInBackoffices: false
}, {
  key: 'UserGuidingUserInfo',
  adapter: UserGuidingAdapter,
  availableInBackoffices: true
}];
export default VENDORS;