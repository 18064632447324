import { useEffect, useState } from 'react';
function getWindowScroll() {
  const {
    scrollY,
    scrollX
  } = window;
  return {
    scrollY,
    scrollX
  };
}
export default function useWindowScrolls() {
  const [windowScrolls, setWindowScrolls] = useState(getWindowScroll());
  useEffect(() => {
    function handleUpdateScroll() {
      setWindowScrolls(getWindowScroll());
    }
    window.addEventListener('scroll', handleUpdateScroll);
    return () => window.removeEventListener('resiscrollze', handleUpdateScroll);
  }, []);
  return windowScrolls;
}