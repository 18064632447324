export const COMPLAINT_REQUEST_TYPE = {
  id: 3,
  label: 'Reclamação'
};
export const REQUEST_TYPES = [{
  id: 1,
  label: 'Denúncia'
}, {
  id: 2,
  label: 'Elogio'
}, COMPLAINT_REQUEST_TYPE, {
  id: 4,
  label: 'Sugestão'
}];