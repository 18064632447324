import { UnitTime } from 'types/session';
const convertTimeToMilliseconds = (unitTime, value) => {
  switch (unitTime) {
    case UnitTime.HOURS:
      return value * 3600000;
    case UnitTime.MINUTES:
      return value * 60000;
    case UnitTime.SECONDS:
      return value * 1000;
    default:
      return value;
  }
};
const convertTimeToMinutes = (unitTime, value) => {
  switch (unitTime) {
    case UnitTime.HOURS:
      return value * 60;
    case UnitTime.MILLISSECONDS:
      return value / 60000;
    case UnitTime.SECONDS:
      return value / 60;
    default:
      return value;
  }
};
const unitToString = unitTime => {
  switch (unitTime) {
    case UnitTime.MILLISSECONDS:
      return 'milissegundo(s)';
    case UnitTime.MINUTES:
      return 'minuto(s)';
    case UnitTime.SECONDS:
      return 'segundo(s)';
    default:
      return 'hora(s)';
  }
};
export { convertTimeToMilliseconds, convertTimeToMinutes, unitToString };