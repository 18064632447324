import ProductUtils from 'utils/ProductUtils';
import createAxiosInstance from 'utils/createAxiosInstance';
import history from 'utils/browserHistory';
import { Envs } from 'constants/envs';
const axiosInstance = createAxiosInstance({
  onNotAuthenticate: () => {
    history.push('/');
  }
});
const getBaseUrl = () => {
  return ProductUtils.isBackofficeInternoEnv() ? Envs().CADASTRO_API_URL : Envs().CUSTOMER_API_URL;
};
export { axiosInstance, getBaseUrl };