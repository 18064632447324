import { Envs } from 'constants/envs';
import PRODUCT_IDS from 'constants/productIds';
import ProductsTrailEnum, { Origin, PlataformFeatures } from 'constants/products-trail';
import Notifier from 'components/Notification/Notifier';
import { API_ICON, AUTENTICA_ROUNDED_ICON, BASECERTA_ROUNDED_ICON, CREDITOMIX_ROUNDED_ICON, CREDITO_ICON, LOCALIZE_ROUNDED_ICON, RECUPERE_ROUNDED_ICON, SCORE_CREDITO_ICON, SCORE_RECUPERE_ICON, SIGNATURE_ROUNDED_ICON, SMS_ROUNDED_ICON, VEICULOS_ROUNDED_ICON, WEBPHONE_ROUNDED_ICON } from 'constants/urlImages';
import AnalyticsUtils from './analytics.v2';
import ProductTrailCookiesUtils from './ProductTrailCookiesUtils';
import ProductUtils from './ProductUtils';
import AuthUtils from './AuthUtils';
import history from 'utils/browserHistory';
const PRODUCTS_DESCRIPTION = {
  SCORE_CREDITO: 'Reduza o risco \n na concessão \n de crédito.',
  SCORE_RECUPERE: 'Priorize as dívidas\n pela propensão \n de pagamento.',
  RECUPERE: 'Faça a gestão \n da sua carteira \n de cobrança.',
  API: 'Integre nossos \n produtos ao seu \n sistema.',
  AUTENTICA: 'Valide a identidade \n via biometria facial e \n evite fraudes.',
  ASSINATURAS: 'Assine documentos \n digitalmente com \n validade jurídica.',
  BASECERTA: 'Atualize e enriqueça \n sua base de clientes\n PF e PJ.',
  CREDITO: 'Amplie suas possibilidades de concessão ao crédito.',
  CREDITO_MIX: 'Analise crédito de forma \n completa e reduza sua \n inadimplência.',
  GEOTIMIZE: 'Faça prospecção de empresas com geolocalização.',
  LOCALIZE: 'Confirme dados \n de pessoas \n e empresas.',
  SMS: 'Faça envios únicos \n ou em lote e \n monitore resultados.',
  VEICULOS: 'Consulte dados e \n outras informações \n de veículos.',
  WEBPHONE: 'Faça ligações com \n apenas um clique,\n de qualquer lugar.'
};
const PRODUCT_PATH = {
  [PlataformFeatures.API]: 'api',
  [PlataformFeatures.ASSINATURAS]: 'assinatura',
  [PlataformFeatures.AUTENTICA]: 'autentica',
  [PlataformFeatures.BASE_CERTA]: 'basecerta',
  [PlataformFeatures.CREDITOMIX]: 'creditomix',
  [PlataformFeatures.GEOTIMIZE]: 'geotimize',
  [PlataformFeatures.LOCALIZE]: 'localize',
  [PlataformFeatures.RECUPERE]: 'recupere',
  [PlataformFeatures.SMS]: 'sms',
  [PlataformFeatures.VEICULOS]: 'veiculos',
  [PlataformFeatures.WEBPHONE]: 'webphone',
  [PlataformFeatures.SCORE_CREDITO]: 'score-credito',
  [PlataformFeatures.SCORE_RECUPERE]: 'score-recupere'
};
const NO_ACCESS_MESSAGE = 'Ops! Parece que você não possui acesso ao produto escolhido. Para ter acesso, entre em contato com o administrador da sua conta.';

// TODO: Olhar a feature
const ga = _ref => {
  let {
    feature,
    trail,
    origin
  } = _ref;
  const value = "".concat(trail.toString(), "-").concat(PRODUCT_PATH[feature]);
  const event = origin ? " [".concat(origin, "]") : '';
  AnalyticsUtils.sendAnalytics({
    eventCategory: "[PLATAFORMA]".concat(event),
    eventAction: '[ESCOLHER]',
    eventLabel: value.toUpperCase()
  });
};
const redirectInterestedPage = (user, feature, urlProduct) => {
  if (user.id) {
    if (AuthUtils.getUser().isPendingByMFAValidation()) {
      history.push('/gestao-da-conta/inicio');
      return;
    }
    window.location.href = urlProduct;
  } else {
    Notifier.info(NO_ACCESS_MESSAGE, {
      autoHideDuration: 5000
    });
  }
};
export const productsTrail = (user, origin) => {
  return [{
    title: 'Cadastro e Onboarding',
    suggestion: 'Se você está validando informações, use também',
    color: '#8A00C1',
    trailId: ProductsTrailEnum.Onboarding,
    products: [{
      description: PRODUCTS_DESCRIPTION.LOCALIZE,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: LOCALIZE_ROUNDED_ICON,
      feature: PlataformFeatures.LOCALIZE,
      onClick: () => {
        ga({
          feature: PlataformFeatures.LOCALIZE,
          origin,
          trail: ProductsTrailEnum.Onboarding
        });
        if (user.hasProductById(PRODUCT_IDS.LOCALIZE)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.LOCALIZE,
            trailId: ProductsTrailEnum.Onboarding,
            feature: PlataformFeatures.LOCALIZE
          });
          window.location.href = Envs().LOCALIZE_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.LOCALIZE, Envs().LOCALIZE_URL);
        }
      },
      productId: PRODUCT_IDS.LOCALIZE,
      title: 'Localize'
    }, {
      description: PRODUCTS_DESCRIPTION.AUTENTICA,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: AUTENTICA_ROUNDED_ICON,
      feature: PlataformFeatures.AUTENTICA,
      onClick: () => {
        ga({
          feature: PlataformFeatures.AUTENTICA,
          origin,
          trail: ProductsTrailEnum.Onboarding
        });
        if (user.hasProductById(PRODUCT_IDS.AUTENTICA)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.AUTENTICA,
            trailId: ProductsTrailEnum.Onboarding,
            feature: PlataformFeatures.AUTENTICA
          });
          window.location.href = Envs().AUTENTICA_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.AUTENTICA, Envs().AUTENTICA_URL);
        }
      },
      productId: PRODUCT_IDS.AUTENTICA,
      title: 'Autentica'
    }, {
      description: PRODUCTS_DESCRIPTION.ASSINATURAS,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: SIGNATURE_ROUNDED_ICON,
      iconStyle: {
        marginLeft: '3px'
      },
      feature: PlataformFeatures.ASSINATURAS,
      onClick: () => {
        ga({
          feature: PlataformFeatures.ASSINATURAS,
          origin,
          trail: ProductsTrailEnum.Onboarding
        });
        if (user.hasProductById(PRODUCT_IDS.ASSINATURAS)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.ASSINATURAS,
            trailId: ProductsTrailEnum.Onboarding,
            feature: PlataformFeatures.ASSINATURAS
          });
          window.location.href = Envs().ASSINATURAS_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.ASSINATURAS, Envs().ASSINATURAS_URL);
        }
      },
      productId: PRODUCT_IDS.ASSINATURAS,
      title: 'Assinaturas'
    }]
  }, {
    title: 'Crédito e Fraude',
    suggestion: 'Se você está concedendo crédito, use também',
    color: '#008282',
    trailId: ProductsTrailEnum.Credit,
    products: [{
      description: PRODUCTS_DESCRIPTION.CREDITO,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: CREDITO_ICON,
      hide: !user.hasProductById(PRODUCT_IDS.CREDITO),
      feature: PlataformFeatures.CREDITO,
      onClick: () => {
        ga({
          feature: PlataformFeatures.CREDITO,
          origin,
          trail: ProductsTrailEnum.Credit
        });
        if (user.hasProductById(PRODUCT_IDS.CREDITO)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.CREDITO,
            trailId: ProductsTrailEnum.Credit,
            feature: PlataformFeatures.CREDITO
          });
          window.location.href = Envs().CREDITO_URL;
        }
      },
      productId: PRODUCT_IDS.CREDITO,
      title: 'Análise de \n Crédito'
    }, {
      description: PRODUCTS_DESCRIPTION.SCORE_CREDITO,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: SCORE_CREDITO_ICON,
      feature: PlataformFeatures.SCORE_CREDITO,
      onClick: () => {
        ga({
          feature: PlataformFeatures.SCORE_CREDITO,
          origin,
          trail: ProductsTrailEnum.Credit
        });
        if (user.hasProductById(PRODUCT_IDS.LOCALIZE)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.LOCALIZE,
            trailId: ProductsTrailEnum.Credit,
            feature: PlataformFeatures.SCORE_CREDITO
          });
          window.location.href = Envs().LOCALIZE_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.LOCALIZE, Envs().LOCALIZE_URL);
        }
      },
      productId: PRODUCT_IDS.LOCALIZE,
      title: 'Score de \n crédito'
    }, {
      description: PRODUCTS_DESCRIPTION.CREDITO_MIX,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: CREDITOMIX_ROUNDED_ICON,
      feature: PlataformFeatures.CREDITOMIX,
      onClick: () => {
        ga({
          feature: PlataformFeatures.CREDITOMIX,
          origin,
          trail: ProductsTrailEnum.Credit
        });
        if (user.hasProductById(PRODUCT_IDS.CREDITOMIX)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.CREDITOMIX,
            trailId: ProductsTrailEnum.Credit,
            feature: PlataformFeatures.CREDITOMIX
          });
          window.location.href = Envs().CREDITOMIX_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.CREDITOMIX, Envs().CREDITOMIX_URL);
        }
      },
      productId: PRODUCT_IDS.CREDITOMIX,
      title: 'Crédito \n Mix'
    }, {
      description: PRODUCTS_DESCRIPTION.AUTENTICA,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: AUTENTICA_ROUNDED_ICON,
      feature: PlataformFeatures.AUTENTICA,
      onClick: () => {
        ga({
          feature: PlataformFeatures.AUTENTICA,
          origin,
          trail: ProductsTrailEnum.Credit
        });
        if (user.hasProductById(PRODUCT_IDS.AUTENTICA)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.AUTENTICA,
            trailId: ProductsTrailEnum.Credit,
            feature: PlataformFeatures.AUTENTICA
          });
          window.location.href = Envs().AUTENTICA_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.AUTENTICA, Envs().AUTENTICA_URL);
        }
      },
      productId: PRODUCT_IDS.AUTENTICA,
      title: 'Autentica'
    }]
  }, {
    title: 'Relacionamento',
    suggestion: 'Se você está autenticando pessoas, use também',
    color: '#3000AA',
    trailId: ProductsTrailEnum.Relationship,
    products: [{
      description: PRODUCTS_DESCRIPTION.SMS,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: SMS_ROUNDED_ICON,
      feature: PlataformFeatures.SMS,
      onClick: () => {
        ga({
          feature: PlataformFeatures.SMS,
          origin,
          trail: ProductsTrailEnum.Relationship
        });
        if (user.hasProductById(PRODUCT_IDS.SMS)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.SMS,
            trailId: ProductsTrailEnum.Relationship,
            feature: PlataformFeatures.SMS
          });
          window.location.href = Envs().SMS_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.SMS, Envs().SMS_URL);
        }
      },
      productId: PRODUCT_IDS.SMS,
      title: 'SMS'
    }, {
      description: PRODUCTS_DESCRIPTION.WEBPHONE,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: WEBPHONE_ROUNDED_ICON,
      feature: PlataformFeatures.WEBPHONE,
      onClick: () => {
        ga({
          feature: PlataformFeatures.WEBPHONE,
          origin,
          trail: ProductsTrailEnum.Relationship
        });
        if (user.hasProductById(PRODUCT_IDS.WEBPHONE)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.WEBPHONE,
            trailId: ProductsTrailEnum.Relationship,
            feature: PlataformFeatures.WEBPHONE
          });
          window.location.href = Envs().WEBPHONE_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.WEBPHONE, Envs().WEBPHONE_URL);
        }
      },
      productId: PRODUCT_IDS.WEBPHONE,
      title: 'Webphone'
    }, {
      description: PRODUCTS_DESCRIPTION.BASECERTA,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: BASECERTA_ROUNDED_ICON,
      feature: PlataformFeatures.BASE_CERTA,
      onClick: () => {
        ga({
          feature: PlataformFeatures.BASE_CERTA,
          origin,
          trail: ProductsTrailEnum.Relationship
        });
        if (user.hasProductById(PRODUCT_IDS.BASECERTA)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.BASECERTA,
            trailId: ProductsTrailEnum.Relationship,
            feature: PlataformFeatures.BASE_CERTA
          });
          window.location.href = Envs().BASECERTA_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.BASE_CERTA, Envs().BASECERTA_URL);
        }
      },
      productId: PRODUCT_IDS.BASECERTA,
      title: 'Base Certa'
    }]
  }, {
    title: 'Cobrança',
    suggestion: 'Se você está realizando cobranças, use também',
    color: '#E18838',
    trailId: ProductsTrailEnum.Recovery,
    products: [{
      description: PRODUCTS_DESCRIPTION.RECUPERE,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: RECUPERE_ROUNDED_ICON,
      feature: PlataformFeatures.RECUPERE,
      onClick: () => {
        ga({
          feature: PlataformFeatures.RECUPERE,
          origin,
          trail: ProductsTrailEnum.Recovery
        });
        if (user.hasProductById(PRODUCT_IDS.RECUPERE)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.RECUPERE,
            trailId: ProductsTrailEnum.Recovery,
            feature: PlataformFeatures.RECUPERE
          });
          window.location.href = Envs().RECUPERE_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.RECUPERE, Envs().RECUPERE_URL);
        }
      },
      productId: PRODUCT_IDS.RECUPERE,
      title: 'Recupere'
    }, {
      description: PRODUCTS_DESCRIPTION.SCORE_RECUPERE,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: SCORE_RECUPERE_ICON,
      feature: PlataformFeatures.SCORE_RECUPERE,
      onClick: () => {
        ga({
          feature: PlataformFeatures.SCORE_RECUPERE,
          origin,
          trail: ProductsTrailEnum.Recovery
        });
        if (user.hasProductById(PRODUCT_IDS.LOCALIZE)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.LOCALIZE,
            trailId: ProductsTrailEnum.Recovery,
            feature: PlataformFeatures.SCORE_RECUPERE
          });
          window.location.href = Envs().LOCALIZE_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.LOCALIZE, Envs().LOCALIZE_URL);
        }
      },
      productId: PRODUCT_IDS.RECUPERE,
      title: 'Score \n Recupere'
    }, {
      description: 'Automatize seus \n envios de SMS na \n régua de cobrança.',
      disabled: user.isBlockedByNonPayment(),
      iconSrc: SMS_ROUNDED_ICON,
      feature: PlataformFeatures.SMS,
      onClick: () => {
        ga({
          feature: PlataformFeatures.SMS,
          origin,
          trail: ProductsTrailEnum.Recovery
        });
        if (user.hasProductById(PRODUCT_IDS.SMS)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.SMS,
            trailId: ProductsTrailEnum.Recovery,
            feature: PlataformFeatures.SMS
          });
          window.location.href = Envs().SMS_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.SMS, Envs().SMS_URL);
        }
      },
      productId: PRODUCT_IDS.SMS,
      title: 'SMS'
    }, {
      description: PRODUCTS_DESCRIPTION.WEBPHONE,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: WEBPHONE_ROUNDED_ICON,
      feature: PlataformFeatures.WEBPHONE,
      onClick: () => {
        ga({
          feature: PlataformFeatures.WEBPHONE,
          origin,
          trail: ProductsTrailEnum.Recovery
        });
        if (user.hasProductById(PRODUCT_IDS.WEBPHONE)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.WEBPHONE,
            trailId: ProductsTrailEnum.Recovery,
            feature: PlataformFeatures.WEBPHONE
          });
          window.location.href = Envs().WEBPHONE_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.WEBPHONE, Envs().WEBPHONE_URL);
        }
      },
      productId: PRODUCT_IDS.WEBPHONE,
      title: 'Webphone'
    }, {
      description: 'Atualize e valide \n sua base de dados \n PF e PJ.',
      disabled: user.isBlockedByNonPayment(),
      iconSrc: BASECERTA_ROUNDED_ICON,
      feature: PlataformFeatures.BASE_CERTA,
      onClick: () => {
        ga({
          feature: PlataformFeatures.BASE_CERTA,
          origin,
          trail: ProductsTrailEnum.Recovery
        });
        if (user.hasProductById(PRODUCT_IDS.BASECERTA)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.BASECERTA,
            trailId: ProductsTrailEnum.Recovery,
            feature: PlataformFeatures.BASE_CERTA
          });
          window.location.href = Envs().BASECERTA_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.BASE_CERTA, Envs().BASECERTA_URL);
        }
      },
      productId: PRODUCT_IDS.BASECERTA,
      title: 'Base Certa'
    }]
  }, {
    title: 'Mais soluções',
    suggestion: 'Quer mais soluções, use também',
    color: '#666666',
    trailId: ProductsTrailEnum.Others,
    products: [{
      description: PRODUCTS_DESCRIPTION.API,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: API_ICON,
      feature: PlataformFeatures.API,
      onClick: () => {
        ga({
          feature: PlataformFeatures.API,
          origin,
          trail: ProductsTrailEnum.Others
        });
        if (user.hasAPI()) {
          ProductTrailCookiesUtils.saveCookie({
            productId: -1,
            trailId: ProductsTrailEnum.Others,
            feature: PlataformFeatures.API
          });
          window.location.href = "".concat(Envs().PAINEL_URL, "/gestao-da-conta/area-do-desenvolvedor");
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.API, "".concat(Envs().PAINEL_URL, "/gestao-da-conta/area-do-desenvolvedor"));
        }
      },
      productId: 1000,
      title: 'API'
    }, {
      description: PRODUCTS_DESCRIPTION.VEICULOS,
      disabled: user.isBlockedByNonPayment(),
      iconSrc: VEICULOS_ROUNDED_ICON,
      feature: PlataformFeatures.VEICULOS,
      onClick: () => {
        ga({
          feature: PlataformFeatures.VEICULOS,
          origin,
          trail: ProductsTrailEnum.Others
        });
        if (user.hasProductById(PRODUCT_IDS.VEICULOS)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.VEICULOS,
            trailId: ProductsTrailEnum.Others,
            feature: PlataformFeatures.VEICULOS
          });
          window.location.href = Envs().VEICULOS_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.VEICULOS, Envs().VEICULOS_URL);
        }
      },
      productId: PRODUCT_IDS.VEICULOS,
      title: 'Veículos'
    }, {
      description: 'Colete assinaturas de \n contratos, confissão \n de dívidas e mais.',
      disabled: user.isBlockedByNonPayment(),
      iconSrc: SIGNATURE_ROUNDED_ICON,
      iconStyle: {
        marginLeft: '3px'
      },
      feature: PlataformFeatures.ASSINATURAS,
      onClick: () => {
        ga({
          feature: PlataformFeatures.ASSINATURAS,
          origin,
          trail: ProductsTrailEnum.Others
        });
        if (user.hasProductById(PRODUCT_IDS.ASSINATURAS)) {
          ProductTrailCookiesUtils.saveCookie({
            productId: PRODUCT_IDS.ASSINATURAS,
            trailId: ProductsTrailEnum.Others,
            feature: PlataformFeatures.ASSINATURAS
          });
          window.location.href = Envs().ASSINATURAS_URL;
        } else if (!user.isBlockedByNonPayment()) {
          redirectInterestedPage(user, PlataformFeatures.ASSINATURAS, Envs().ASSINATURAS_URL);
        }
      },
      productId: PRODUCT_IDS.ASSINATURAS,
      title: 'Assinaturas'
    }]
  }];
};
export const getProductTrailSuggestion = () => {
  const cookie = ProductTrailCookiesUtils.getCookie();
  if (cookie) {
    const {
      productId,
      trailId,
      feature
    } = JSON.parse(cookie);
    if (productId === ProductUtils.getProductIdByProductNameEnv() && trailId !== ProductsTrailEnum.Others) {
      const actualTrail = productsTrail(AuthUtils.getUser(), Origin.Suggestion).find(product => product.trailId === trailId);
      if (actualTrail && actualTrail.products.length > 1) {
        const otherProducts = actualTrail.products.filter(product => product.feature !== feature);
        const result = {
          ...actualTrail,
          products: otherProducts
        };
        return result;
      }
    }
  }
  return undefined;
};