// remove everything except numerics
export function removeNonNumerics(raw) {
  return raw ? raw.replace(/[^0-9]/g, '') : '';
}

/*eslint-disable */
// remove everything except numerics and dash
export function removeNonNumericsExceptDash(raw) {
  return raw ? raw.replace(/[^\d-]/g, '') : '';
}

// remove leading zeroes
export function removeLeadingZeroes(val) {
  return val ? val.replace(/^0+/, '') : '';
}
export function stringToCurrency(numberNum, precision) {
  let operator = numberNum.charAt(0) === '-' ? '-' : '';
  const onlyNumber = removeNonNumerics(numberNum);
  let decimalParcel = onlyNumber.substring(onlyNumber.length - precision);
  if (decimalParcel.length < precision) {
    const zeros = Array.from({
      length: precision - decimalParcel.length
    }, _ => '0').join('');
    decimalParcel = "".concat(zeros).concat(decimalParcel);
  }
  const integerParcel = onlyNumber.substring(0, onlyNumber.length - precision) || '0';
  return "".concat(operator).concat(integerParcel, ".").concat(decimalParcel);
}
export function removeEmpty(obj) {
  if (obj === undefined) return undefined;
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
}