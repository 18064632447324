import { styled } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
export const StyledAvatar = styled(Avatar)(_ref => {
  let {
    theme
  } = _ref;
  return {
    width: '44px',
    height: '44px',
    background: '#008282',
    fontSize: '16.5px',
    fontWeight: 300,
    lineHeight: '20px',
    border: "2px solid ".concat(theme.palette.common.white),
    boxShadow: theme.shadows[3]
  };
});