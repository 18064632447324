import { useState } from 'react';
const useRulesValidation = rules => {
  const [errors, setErrors] = useState({
    hasErrors: false,
    messages: {}
  });
  const validate = function () {
    for (var _len = arguments.length, fields = new Array(_len), _key = 0; _key < _len; _key++) {
      fields[_key] = arguments[_key];
    }
    const formErrors = {
      ...errors
    };
    const applyValidation = fieldName => {
      return fields.length === 0 || fields.includes(fieldName);
    };
    const isDisable = rule => {
      return rule.disabled !== undefined ? rule.disabled : false;
    };
    rules.forEach(rule => {
      if (applyValidation(rule.field) && !isDisable(rule) && rule.hasErrors() && !formErrors.messages[rule.field]) {
        formErrors.messages[rule.field] = rule.messageError;
      }
    });
    formErrors.hasErrors = rules.some(rule => rule.hasErrors());
    setErrors(formErrors);
    return !formErrors.hasErrors;
  };
  const clearError = fieldName => {
    const newErrors = {
      ...errors
    };
    if (fieldName) {
      delete newErrors.messages[fieldName];
    } else {
      newErrors.messages = {};
      newErrors.hasErrors = false;
    }
    setErrors(newErrors);
  };
  return {
    errors,
    validate,
    clearError
  };
};

// FIXME: remover após ajustar o eslint e o prettier
// eslint-disable-next-line prettier/prettier

export default useRulesValidation;