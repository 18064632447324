import { useEffect, useState } from 'react';
import { COUNTDOWN_DIALOG_TIME } from 'constants/session';
import { UnitTime } from 'types/session';
import Oauth2CookiesUtils from 'utils/Oauth2CookiesUtils';
const useAnimationInterval = function () {
  let onFinish = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : () => {};
  let onNotify = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
  const ANIMATION_EXECUTION_TIME = 1000;
  const DEFAULT_PROGRESS_TIME = 100;
  const timeToFinishSession = () => Oauth2CookiesUtils.timeToFinishSession(UnitTime.SECONDS);
  const [startTime, setStartTime] = useState(timeToFinishSession);
  const [timeLeft, setTimeLeft] = useState(timeToFinishSession);
  const [progress, setProgress] = useState(DEFAULT_PROGRESS_TIME);
  useEffect(() => {
    const progress = timeLeft / startTime * 100 || DEFAULT_PROGRESS_TIME;
    setProgress(progress);
  }, [timeLeft, startTime]);
  const resetAnimation = () => {
    const currentTime = timeToFinishSession();
    setStartTime(currentTime);
    setTimeLeft(currentTime);
  };
  const runAnimation = () => {
    return setInterval(() => {
      const currentTime = timeToFinishSession();
      if (currentTime <= 0) {
        onFinish();
        return;
      }
      if (currentTime <= COUNTDOWN_DIALOG_TIME) {
        onNotify();
      }
      setTimeLeft(currentTime);
    }, ANIMATION_EXECUTION_TIME);
  };
  return {
    timeLeft,
    progress,
    runAnimation,
    resetAnimation
  };
};
export default useAnimationInterval;