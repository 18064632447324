import { useState } from 'react';
import ValidationUtils from 'utils/ValidationUtils';
import MESSAGES from 'containers/EditOwnProfile/constants/MessagesConstants';
import EditProfileUtils from 'containers/EditOwnProfile/utils/EditProfileUtils';
import useFormState from 'hooks/useFormState';
const usePasswordChange = () => {
  const methods = useFormState(EditProfileUtils.getInitialPasswordFormState());
  const [passwordErrors, setPasswordErrors] = useState(EditProfileUtils.getInitialPasswordErrors());
  const [showPasswords, setShowPasswords] = useState(EditProfileUtils.getInitialPasswordShowState());
  const togglePasswordShow = passwordToShow => {
    setShowPasswords(prev => ({
      ...prev,
      [passwordToShow]: !prev[passwordToShow]
    }));
  };
  const {
    form
  } = methods;
  const hasErrorsInForm = () => {
    const actualErrors = EditProfileUtils.getInitialPasswordErrors();
    if (!form.currentPassword) {
      actualErrors.hasError = true;
      actualErrors.currentPassword.message = MESSAGES.CURRENT_PASSWORD_IS_REQUIRED;
    }
    if (!form.confirmNewPassword) {
      actualErrors.hasError = true;
      actualErrors.confirmNewPassword.message = MESSAGES.PASSWORD_CONFIRMATION_IS_REQUIRED;
    }
    if (!ValidationUtils.isValidPassword(form.confirmNewPassword)) {
      actualErrors.hasError = true;
      actualErrors.confirmNewPassword.message = MESSAGES.PASSWORD_IS_INVALID;
    }
    if (!form.newPassword) {
      actualErrors.hasError = true;
      actualErrors.newPassword.message = MESSAGES.NEW_PASSWORD_IS_REQUIRED;
    }
    if (!ValidationUtils.isValidPassword(form.newPassword)) {
      actualErrors.hasError = true;
      actualErrors.newPassword.message = MESSAGES.PASSWORD_IS_INVALID;
    }
    if (form.newPassword !== form.confirmNewPassword) {
      actualErrors.hasError = true;
      actualErrors.confirmNewPassword.message = MESSAGES.PASSWORDS_ARE_DIFFERENT;
    }
    if (form.currentPassword === form.confirmNewPassword) {
      actualErrors.hasError = true;
      actualErrors.confirmNewPassword.message = MESSAGES.PASSWORD_MUST_BE_DIFFERENT_ACTUAL;
    }
    setPasswordErrors(prev => ({
      ...prev,
      ...actualErrors
    }));
    return actualErrors.hasError || passwordErrors.hasError;
  };
  const handlePreventDefault = event => event.preventDefault();
  return {
    ...methods,
    showPasswords,
    togglePasswordShow,
    passwordErrors,
    hasErrorsInForm,
    handlePreventDefault
  };
};
export default usePasswordChange;