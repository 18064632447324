var _UserService;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { axiosInstance, getBaseUrl } from './baseService';
class UserService {}
_UserService = UserService;
_defineProperty(UserService, "prepareUrl", customerId => {
  return "".concat(getBaseUrl(), "/customers").concat(customerId ? "/".concat(customerId) : '');
});
/**
 * Lista de usuários
 *
 * Este endpoint é responsável por fornecer ao front-end uma listagem com
 * os usuários de um cliente.
 *
 * Permissões relacionadas:
 *
 * - ADMINISTRATIVO:USUARIOS:VISUALIZAR:EMPRESA
 * - ADMINISTRATIVO:USUARIOS:VISUALIZAR:GRUPO e
 * - ADMINISTRATIVO:USUARIOS:VISUALIZAR:USUARIO
 *
 * Regra: Usuários com a primeira permissão têm acesso à todos os usuários da
 * empresa, enquanto que os com a segunda, têm acesso à todos os usuários do
 * grupo e, por fim, os com a terceira, têm acesso somente ao seu próprio usuário.
 *
 */
_defineProperty(UserService, "getUsers", _ref => {
  let {
    customerId = ''
  } = _ref;
  return axiosInstance.get("".concat(_UserService.prepareUrl(customerId), "/users"));
});
_defineProperty(UserService, "getPermissionsProfile", _ref2 => {
  let {
    customerId,
    profileId
  } = _ref2;
  return axiosInstance.get("".concat(_UserService.prepareUrl(customerId), "/profiles/").concat(profileId));
});
_defineProperty(UserService, "updatedPermissionsProfile", (profile, customerId, profileId) => {
  return axiosInstance.put("".concat(_UserService.prepareUrl(customerId), "/profiles/").concat(profileId), profile);
});
_defineProperty(UserService, "updateUser", _ref3 => {
  let {
    customerId,
    user
  } = _ref3;
  const {
    id = 0,
    phone,
    mobile,
    birthdate,
    email,
    status = {},
    group = {},
    profile = {}
  } = user;
  return axiosInstance.put("".concat(_UserService.prepareUrl(customerId), "/users/").concat(id), {
    phone,
    mobile,
    birthdate,
    email,
    statusId: status.id,
    groupId: group.id,
    profileId: profile.id
  });
});
_defineProperty(UserService, "sendLinkToNewUsers", _ref4 => {
  let {
    customerId,
    groupId,
    profilesEmails
  } = _ref4;
  return axiosInstance.post("".concat(_UserService.prepareUrl(customerId), "/groups/").concat(groupId, "/users"), profilesEmails);
});
_defineProperty(UserService, "resendLinkToNewUsers", _ref5 => {
  let {
    customerId,
    email
  } = _ref5;
  return axiosInstance.post("".concat(_UserService.prepareUrl(customerId), "/users/register"), [email]);
});
_defineProperty(UserService, "requestNewPassword", _ref6 => {
  let {
    customerId,
    email
  } = _ref6;
  return axiosInstance.post("".concat(_UserService.prepareUrl(customerId), "/users/password"), [email]);
});
_defineProperty(UserService, "deleteUser", _ref7 => {
  let {
    customerId,
    userId
  } = _ref7;
  return axiosInstance.delete("".concat(_UserService.prepareUrl(customerId), "/users/").concat(userId));
});
export default UserService;