import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
export const StyledContainer = styled(Box)(props => {
  var _props$borderSize;
  const style = {
    border: "".concat((_props$borderSize = props.borderSize) !== null && _props$borderSize !== void 0 ? _props$borderSize : 2, "px solid ").concat(props.borderColor),
    backgroundColor: '#FFFFFF',
    cursor: props.cursor,
    display: 'flex',
    borderRadius: '50%',
    padding: '6px',
    ...props.customStyle
  };
  return style;
});