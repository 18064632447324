import { useState, useEffect } from 'react';
import ValidationUtils from '../utils/ValidationUtils';
export const rulesPassword = [{
  label: 'Mínimo de 8 caracteres.',
  check: 'hasNotMinEight',
  hasError: password => password.length < 8
}, {
  label: 'Ao menos um caractere especial.',
  check: 'hasNotSymbol',
  hasError: password => !password.match(/[^A-Za-z 0-9]/g)
}, {
  label: 'Ao menos uma letra maiúscula.',
  check: 'hasNotCapitalLetter',
  hasError: password => !password.match(/[A-Z]/g)
}, {
  label: 'Ao menos um número (0-9).',
  check: 'hasNotNumber',
  hasError: password => !password.match(/\d/g)
}, {
  label: 'Não conter sequências alfanuméricas ou termos óbvios, tais como: abc, aaa, bbb, 123, 321, senha, password, assertiva e etc.',
  check: 'hasSequence',
  hasError: password => ValidationUtils.hasWeakPasswordPattern(password)
}];
function usePasswordValidation(password) {
  const [errors, setErrors] = useState([]);
  const [strength, setStrength] = useState(0);
  useEffect(() => {
    setStrength(0);
    const preErrors = [];
    for (let i = 0; i < rulesPassword.length; i += 1) {
      if (rulesPassword[i].hasError(password)) {
        preErrors.push(rulesPassword[i].check);
      } else {
        setStrength(prev => prev + 1);
      }
    }
    setErrors(preErrors);
  }, [password]);
  return [errors, strength];
}
export default usePasswordValidation;