import { useFlag, useFlagsStatus, useUnleashClient, useUnleashContext, useVariant } from '@unleash/proxy-client-react';
function useFeatureFlag() {
  const {
    flagsError,
    flagsReady
  } = useFlagsStatus();
  const {
    setContextField
  } = useUnleashClient();
  const updateContext = useUnleashContext();
  const getFlag = name => useFlag(name);
  const getVariant = name => useVariant(name);
  return {
    flagsError,
    flagsReady,
    getFlag,
    getVariant,
    setContextField,
    updateContext
  };
}
export default useFeatureFlag;