export const MUI_THEME = {
  palette: {
    primary: {
      light: '#f3e5f5',
      main: '#8a00C1',
      dark: '#5f00b3'
    },
    secondary: {
      light: '#aeebe9',
      main: '#34d0d1',
      dark: '#008282',
      grey: '#00000099'
    },
    error: {
      main: '#b00020'
    },
    light: {
      light: '#00000029',
      main: '#ffffff',
      dark: '#00000099'
    }
  },
  props: {
    MuiFilledInput: {
      margin: 'dense'
    },
    MuiFormControl: {
      margin: 'dense'
    },
    MuiFormHelperText: {
      margin: 'dense'
    },
    MuiOutlinedInput: {
      margin: 'dense'
    },
    MuiTextField: {
      margin: 'dense',
      variant: 'outlined'
    },
    MuiInputBase: {
      margin: 'dense',
      variant: 'outlined'
    },
    MuiToggleButton: {
      size: 'small'
    }
    // Habilitando essas propriedades estão dando erro no console da biblioteca assertiva-ui
    // MuiSwipeableDrawer: {
    //   disableEnforceFocus: true,
    // },
    // MuiDrawer: {
    //   disableEnforceFocus: true,
    // },
  },
  shape: {
    borderRadius: 4
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'inherit'
      }
    },
    MuiButtonBase: {
      root: {
        textTransform: 'inherit'
      }
    },
    MuiToggleButton: {
      root: {
        textTransform: 'inherit'
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 1px 3px #00000033'
      }
    },
    MuiChip: {
      root: {
        fontWeight: 400,
        letterSpacing: '.89px'
      }
    }
  },
  typography: {
    subtitle1: {
      fontWeight: 500
    }
  }
};