import { styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';
export const StyledContainer = styled(Button)(_ref => {
  let {
    theme
  } = _ref;
  return {
    padding: theme.spacing(1),
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    width: '100%',
    textTransform: 'unset'
  };
});