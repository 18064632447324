var _ReportService;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import ProductUtils from '../utils/ProductUtils';
import { axiosInstance, getBaseUrl } from './baseService';
class ReportService {}
_ReportService = ReportService;
_defineProperty(ReportService, "isBackOfficeInterno", () => ProductUtils.isBackofficeInternoEnv());
_defineProperty(ReportService, "getConsolidatedConsumption", function () {
  let {
    date = '',
    endDate = '',
    startDate = '',
    customerId = '',
    groupId = '',
    productId = '',
    numPage = 0,
    numPageSize = 10
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (_ReportService.isBackOfficeInterno()) {
    return axiosInstance.get("".concat(getBaseUrl(), "/reports/consolidated-usage?startDate=").concat(startDate, "&endDate=").concat(endDate, "&customerId=").concat(customerId, "&groupId=").concat(groupId, "&productId=").concat(productId, "&numPage=").concat(numPage, "&numPageSize=").concat(numPageSize));
  }
  return axiosInstance.get("".concat(getBaseUrl(), "/reports/consolidated-usage?date=").concat(date, "&customerId=").concat(customerId, "&productId=").concat(productId, "&numPage=").concat(numPage, "&numPageSize=").concat(numPageSize));
});
_defineProperty(ReportService, "getProducts", _ref => {
  let {
    customerId = '',
    date = ''
  } = _ref;
  return axiosInstance.get("".concat(getBaseUrl(), "/reports/products?date=").concat(date, "&customerId=").concat(customerId));
});
_defineProperty(ReportService, "getConsolidatedConsumptionReportCSV", _ref2 => {
  let {
    date = '',
    endDate = '',
    startDate = '',
    productId = '',
    customerId = '',
    groupId = '',
    columns = ''
  } = _ref2;
  const columnsParameter = columns ? "&columns=".concat(columns) : '';
  const customerIdParameter = _ReportService.isBackOfficeInterno() ? "&customerId=".concat(customerId) : '';
  if (_ReportService.isBackOfficeInterno()) {
    return axiosInstance.get("".concat(getBaseUrl(), "/reports/consolidated-usage/export?startDate=").concat(startDate, "&endDate=").concat(endDate).concat(customerIdParameter, "&groupId=").concat(groupId, "&productId=").concat(productId).concat(columnsParameter));
  }
  return axiosInstance.get("".concat(getBaseUrl(), "/reports/consolidated-usage/export?date=").concat(date).concat(customerIdParameter, "&productId=").concat(productId).concat(columnsParameter));
});
export default ReportService;