import regex from 'constants/regex';
import { DateUtils } from 'utils';
const defaultTextWhenEmpty = function (text) {
  let defaultText = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Não informado';
  return (text || '').trim() || defaultText;
};
const getInitialPasswordErrors = () => {
  return {
    hasError: false,
    currentPassword: {
      message: ''
    },
    newPassword: {
      message: ''
    },
    confirmNewPassword: {
      message: ''
    }
  };
};
const getInitialPasswordFormState = () => ({
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
});
const getInitialPasswordShowState = () => ({
  currentPassword: false,
  newPassword: false,
  confirmNewPassword: false
});
const getInitialProfileFormState = () => {
  return {
    cpf: '',
    birthdate: null,
    email: '',
    groupName: '',
    name: '',
    mobile: '',
    phone: '',
    profileName: ''
  };
};
const getInitialProfileErrors = () => {
  return {
    hasError: false,
    name: {
      message: ''
    },
    mobile: {
      message: ''
    },
    phone: {
      message: ''
    },
    birthdate: {
      message: ''
    }
  };
};
const prepapeEditProfilePayload = formData => {
  var _DateUtils$getAsMomen;
  return {
    name: formData.name.trim(),
    mobile: (formData.mobile || '').replace(regex.notNumbers, '') || null,
    phone: (formData.phone || '').replace(regex.notNumbers, '') || null,
    birthdate: ((_DateUtils$getAsMomen = DateUtils.getAsMoment(formData.birthdate)) === null || _DateUtils$getAsMomen === void 0 ? void 0 : _DateUtils$getAsMomen.format('DD/MM/YYYY')) || null
  };
};
const EditProfileUtils = {
  defaultTextWhenEmpty,
  getInitialPasswordErrors,
  getInitialPasswordFormState,
  getInitialPasswordShowState,
  getInitialProfileFormState,
  getInitialProfileErrors,
  prepapeEditProfilePayload
};
export default EditProfileUtils;