import internalAnalyticsService from 'services/InternalAnalyticsService';
import ProductUtils from './ProductUtils';
import AuthUtils from './AuthUtils';
/**
 * Aciona a tag que acompanha quaisquers eventos emitidos com o nome 'assertivaTrackingTrigger'
 *
 */
const sendAnalytics = function (event) {
  let googleAnalytics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let internalAnalytics = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const customEventName = 'assertivaTrackingTrigger';
  const productId = ProductUtils.getProductIdByProductNameEnv();
  let parsedEvent = {
    eventCategory: event === null || event === void 0 ? void 0 : event.eventCategory,
    eventAction: event === null || event === void 0 ? void 0 : event.eventAction,
    eventLabel: event === null || event === void 0 ? void 0 : event.eventLabel
  };
  if (AuthUtils.getUser().isAssertiva()) {
    parsedEvent = {
      ...parsedEvent,
      eventValue: 1
    };
  }
  if (googleAnalytics) {
    try {
      var _window;
      const eventToEmit = {
        hitType: 'event',
        event: customEventName,
        ...parsedEvent
      };
      (_window = window) === null || _window === void 0 || (_window = _window.dataLayer) === null || _window === void 0 || _window.push(eventToEmit);
    } catch (error) {
      console.error(error);
    }
  }
  if (internalAnalytics) {
    try {
      internalAnalyticsService.postInternalMetric({
        productId,
        ...parsedEvent
      });
    } catch (error) {
      console.error(error);
    }
  }
};
const AnalyticsUtils = {
  sendAnalytics
};
export default AnalyticsUtils;