export default {
  USER_STATUS: {
    ACTIVE_ID: 1,
    INACTIVE_ID: 2,
    PENDING_ID: 3,
    ACTIVE: {
      id: 1,
      name: 'Ativo'
    },
    INACTIVE: {
      id: 2,
      name: 'Inativo'
    },
    PENDING: {
      id: 3,
      name: 'Pendente'
    },
    BLOCK_SECURITY: {
      id: 4,
      name: 'Bloqueado por segurança'
    }
  },
  MFA_STATUS: {
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',
    PENDING: 'Pendente',
    SUSPENDED: 'Suspenso'
  }
};