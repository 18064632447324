import { styled } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
const PagePaper = styled(Paper)(_ref => {
  let {
    theme
  } = _ref;
  return {
    padding: theme.spacing(1),
    minHeight: 550,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 3, 5, 3)
    }
  };
});
export default PagePaper;