import CookiesUtil from './CookiesUtil';
const PRODUCT_TRAIL_COOKIE_NAME = 'trail';
const COOKIES_OPTIONS = {
  domain: '.assertivasolucoes.com.br',
  path: '/'
};
const saveCookie = info => {
  CookiesUtil.saveItem(PRODUCT_TRAIL_COOKIE_NAME, info, COOKIES_OPTIONS);
};
const clearCookie = () => {
  CookiesUtil.clearItem(PRODUCT_TRAIL_COOKIE_NAME);
};
const getCookie = () => {
  return CookiesUtil.getItem(PRODUCT_TRAIL_COOKIE_NAME);
};
const ProductTrailCookiesUtils = {
  saveCookie,
  clearCookie,
  getCookie
};
export default ProductTrailCookiesUtils;