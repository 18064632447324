import { useCallback, useEffect, useState } from 'react';
import NotificationService from 'services/NotificationService';
const useNotificationHub = () => {
  const [notifications, setNotifications] = useState([]);
  const [notificationQueue, setNotificationQueue] = useState(0);
  const [renderedNotifications, setRenderedNotifications] = useState([]);
  const nextNotification = () => {
    if (notificationQueue <= notifications.length) {
      setTimeout(() => {
        setNotificationQueue(notificationQueue + 1);
      }, 1500);
    }
  };
  const setRead = id => {
    nextNotification();
    NotificationService.patchNotificationsById(id, {
      read: true
    }).then(() => nextNotification()).catch(error => console.error(error));
  };
  const handleRenderedNotifications = useCallback(() => {
    setRenderedNotifications(notifications.filter((_, idx) => idx === notificationQueue));
  }, [notifications, notificationQueue]);
  useEffect(() => {
    handleRenderedNotifications();
  }, [handleRenderedNotifications]);
  return {
    renderedNotifications,
    setNotifications,
    setRead
  };
};
export default useNotificationHub;