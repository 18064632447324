export default {
  DAYS_WEEK: {
    WEEKDAYS: ['segunda', 'terca', 'quarta', 'quinta', 'sexta'],
    WEEKENDS: ['sabado', 'domingo']
  },
  STATUS: {
    ATIVO: 'Status Ativo',
    INATIVO: 'Status Inativo'
  },
  CONSUMPTIONLIMIT: {
    EXCEEDED: "Voc\xEA excedeu o limite dispon\xEDvel.",
    CURRENCY: 'Seu limite de consumo é de '
  }
};