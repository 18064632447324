import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';
const useStyles = makeStyles(() => ({
  table: {
    minWidth: props => props.minWidth
  }
}));
const useDynamicColumnsTable = _ref => {
  let {
    minWidth,
    columns,
    rows,
    enableScroll
  } = _ref;
  const classes = useStyles({
    minWidth
  });
  const [state, setState] = useState({
    checkableColumns: [],
    itemsExpand: []
  });
  const handleState = (key, value) => {
    setState(prev => ({
      ...prev,
      [key]: value
    }));
  };
  const filterValuesBySelector = (array, field) => array.map(value => value[field]).filter(value => value);
  const filterItemsBySelector = (array, field) => array.map(value => value[field] && value).filter(value => value);
  const columnAccessors = filterValuesBySelector(columns, 'accessor');
  const columnAccessorsExpand = filterValuesBySelector(columns, 'expandAccessor');
  const listColumnsAccessors = filterItemsBySelector(columns, 'accessor');
  const listColumnsAccessorsExpand = filterItemsBySelector(columns, 'expandAccessor');
  const getActiveProperty = col => {
    return Object.prototype.hasOwnProperty.call(col, 'active') ? col.active : true;
  };
  useEffect(() => {
    handleState('checkableColumns', listColumnsAccessors.map(col => ({
      ...col,
      name: col.label,
      key: col.accessor,
      checked: getActiveProperty(col),
      active: getActiveProperty(col)
    })));
  }, [columns]);
  useEffect(() => {
    const existExpandAcessor = row => {
      const obj = listColumnsAccessorsExpand.find(item => !_isEmpty(row[item.expandAccessor]));
      return obj && obj.expanded;
    };
    const setListExpand = rows.map(row => Boolean(existExpandAcessor(row)));
    handleState('itemsExpand', setListExpand);
  }, [rows]);
  const updateExpanded = (value, index) => {
    const {
      itemsExpand
    } = state;
    const itemsExpandCopy = [...itemsExpand];
    itemsExpandCopy[index] = value;
    handleState('itemsExpand', itemsExpandCopy);
  };
  const normalizeColumns = array => {
    return array.map(item => ({
      ...item,
      active: getActiveProperty(item)
    }));
  };
  const normalizeSelectedColumns = array => array.map(col => ({
    ...col,
    active: col.checked
  }));
  const isActiveColumn = accessor => {
    const {
      checkableColumns
    } = state;
    return checkableColumns.some(col => col.checked && col.key === accessor);
  };
  const generateNewRows = (array, sorting) => {
    return array.map(value => {
      const row = {};
      sorting.map(key => {
        if (value[key]) {
          row[key] = value[key];
          return row;
        }
        return row;
      });
      return row;
    });
  };
  const normalizedColumns = normalizeColumns(listColumnsAccessors);
  const TableContainerScroll = enableScroll && {
    style: {
      height: "350px",
      overflow: 'auto'
    }
  };
  return {
    state,
    classes,
    handleState,
    columnAccessors,
    columnAccessorsExpand,
    normalizeSelectedColumns,
    listColumnsAccessorsExpand,
    isActiveColumn,
    generateNewRows,
    normalizedColumns,
    TableContainerScroll,
    updateExpanded
  };
};
export default useDynamicColumnsTable;