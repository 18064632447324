import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
const StyledLikeDislike = styled(Box)({
  display: 'flex',
  '& .MuiButtonBase-root': {
    '& svg': {
      fontSize: 20
    },
    '&.like-button.filled svg': {
      color: '#27AE60'
    },
    '&.dislike-button.filled svg': {
      color: '#EB5757'
    },
    '&:hover': {
      background: 'none',
      '&.like-button svg': {
        color: '#27AE60'
      },
      '&.dislike-button svg': {
        color: '#EB5757'
      }
    }
  }
});
export default StyledLikeDislike;