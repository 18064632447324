import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import browserHistory from 'utils/browserHistory';
export const getQueryParams = options => {
  const searchString = typeof (options === null || options === void 0 ? void 0 : options.searchString) === 'string' ? options === null || options === void 0 ? void 0 : options.searchString : window.location.search;
  let queryParams = qs.parse(searchString, {
    ignoreQueryPrefix: true
  });
  if (_isEmpty(queryParams)) {
    var _queryParams, _options$initialPage, _queryParams2, _options$initialSize;
    queryParams = {
      ...queryParams,
      page: ((_queryParams = queryParams) === null || _queryParams === void 0 ? void 0 : _queryParams.page) || (options === null || options === void 0 || (_options$initialPage = options.initialPage) === null || _options$initialPage === void 0 ? void 0 : _options$initialPage.toString()) || '0',
      size: ((_queryParams2 = queryParams) === null || _queryParams2 === void 0 ? void 0 : _queryParams2.size) || (options === null || options === void 0 || (_options$initialSize = options.initialSize) === null || _options$initialSize === void 0 ? void 0 : _options$initialSize.toString()) || '10'
    };
  }
  return queryParams;
};
export const setQueryParams = (queryParams, options) => {
  var _options$getQueryPara;
  const currentQueryParams = (options === null || options === void 0 || (_options$getQueryPara = options.getQueryParams) === null || _options$getQueryPara === void 0 ? void 0 : _options$getQueryPara.call(options)) || getQueryParams();
  let newQueryParams = {
    ...currentQueryParams,
    ...queryParams
  };
  if (options !== null && options !== void 0 && options.forceReset) {
    newQueryParams = {
      ...queryParams,
      page: 0,
      size: currentQueryParams.size
    };
  }
  if (options !== null && options !== void 0 && options.withoutPageAndSize) {
    newQueryParams = queryParams;
  }
  const search = qs.stringify(newQueryParams);
  if (options !== null && options !== void 0 && options.setSearchString) {
    options.setSearchString(search);
    return;
  }
  browserHistory.replace({
    pathname: window.location.pathname,
    search
  });
};
export const getFilters = function () {
  let getQueryParamsFn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getQueryParams;
  const filters = getQueryParamsFn();
  delete filters.page;
  delete filters.size;
  return filters;
};
export const getFiltersKeys = function () {
  let filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getFilters();
  return Object.keys(filters);
};
export const getHasFilters = function () {
  let filtersKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getFiltersKeys();
  return filtersKeys.length > 0;
};
export const eraseFilter = (filterKey, options) => {
  var _options$getQueryPara2;
  const queryParams = (options === null || options === void 0 || (_options$getQueryPara2 = options.getQueryParams) === null || _options$getQueryPara2 === void 0 ? void 0 : _options$getQueryPara2.call(options)) || getQueryParams();
  if (!queryParams[filterKey]) {
    throw new Error("Erro ao remover filtro ".concat(filterKey, ". Verifique se essa chave existe na URL (search)."));
  }
  delete queryParams[filterKey];
  const setQueryParamsFn = (options === null || options === void 0 ? void 0 : options.setQueryParams) || setQueryParams;
  setQueryParamsFn(queryParams, {
    forceReset: true
  });
};
export const eraseAllFilters = function () {
  let filtersToPreserve = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let options = arguments.length > 1 ? arguments[1] : undefined;
  const setQueryParamsFn = (options === null || options === void 0 ? void 0 : options.setQueryParams) || setQueryParams;
  setQueryParamsFn(filtersToPreserve, {
    forceReset: true
  });
};