import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Envs } from 'constants/envs';
export default function useTrialPeriodExpired(inPainel) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleToggleOpenDialog = open => {
    setOpen(open);
  };
  const onCloseRedirectToHomePainel = () => {
    if (inPainel) {
      history.push('/inicio');
    } else {
      window.location.href = "".concat(Envs().PAINEL_URL, "/inicio");
    }
  };
  const onCancelDialog = () => {
    handleToggleOpenDialog(false);
    onCloseRedirectToHomePainel();
  };
  const handleRedirectPlansPageForHire = () => {
    if (inPainel) {
      history.push('/contratar/planos');
    } else {
      window.location.href = "".concat(Envs().PAINEL_URL, "/contratar/planos");
    }
  };
  return {
    open,
    handleToggleOpenDialog,
    onCancelDialog,
    handleRedirectPlansPageForHire
  };
}