import { useState, useEffect } from 'react';
import AUNotifier from 'components/Notification/Notifier';
import { STATES_CITIES_BR_JSON } from '../constants/urlJson';
const msgError = 'Problema no arquivo estático estados e cidades via S3.';
const useStatesCities = value => {
  const [states, setStates] = useState(value);
  useEffect(() => {
    fetch(STATES_CITIES_BR_JSON).then(response => response.json()).then(data => {
      setStates(data.estados);
    }).catch(e => {
      console.error(e);
      const empty = [];
      setStates(empty);
      AUNotifier.error(msgError);
    });
  }, []);
  return {
    setStates,
    states
  };
};
export default useStatesCities;