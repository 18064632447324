import AUDIO_URLS from 'constants/urlAudios';
const play = src => {
  const audio = new Audio(src);
  audio.play();
};
const alertSessionEnd = () => {
  play(AUDIO_URLS.ALERT_SESSION_END);
};
const AudioUtils = {
  play,
  alertSessionEnd
};
export default AudioUtils;