export const PASSWORD_STRENGTH_OPTION = [{
  label: 'Força da senha',
  color: 'gray'
}, {
  label: 'Muito fraca',
  color: 'red'
}, {
  label: 'Fraca',
  color: 'orange'
}, {
  label: 'Regular',
  color: 'green'
}, {
  label: 'Boa',
  color: 'green'
}, {
  label: 'Excelente',
  color: 'green'
}];