const css = `.ProgressBarMessage {
  margin-bottom: 6px;
  min-height: 15px;
}
.ProgressBarMessage .message {
  opacity: 0;
  position: absolute;
}
.ProgressBarMessage .message.show {
  animation: show 1s ease-out forwards;
  animation-delay: 1s;
}
.ProgressBarMessage .message.hide {
  animation: hide 1s ease-out forwards;
}
.ProgressBarMessage .message.completed {
  opacity: 1;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvcnVubmVyL3dvcmsvYXNzZXJ0aXZhLXVpL2Fzc2VydGl2YS11aS9zcmMvY29tcG9uZW50cy9Qcm9ncmVzc0Jhci9Qcm9ncmVzc0Jhck1lc3NhZ2UiLCJzb3VyY2VzIjpbIlByb2dyZXNzQmFyTWVzc2FnZS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0k7RUFDQTs7QUFFQTtFQUNJO0VBQ0E7O0FBRUE7RUFDSTtFQUNBOztBQUdKO0VBQ0k7O0FBR0o7RUFDSTs7QUFJUjtFQUNJO0lBQ0U7O0VBR0Y7SUFDRTs7O0FBSU47RUFDSTtJQUNJOztFQUdKO0lBQ0UifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
