const PATHS = {
  ROOT_PATH: '/',
  PAINEL_PATHS: {
    BALANCE_AND_STATEMENT: '/gestao-da-conta/saldo-e-extrato',
    DEVELOPER_AREA: '/gestao-da-conta/area-do-desenvolvedor',
    EDIT_PROFILE: '/gestao-da-conta/editar/perfil',
    MANAGEMENT_PANEL: '/gestao-da-conta/pagina-inicial',
    PAYMENTS: '/gestao-da-conta/pagamentos',
    REPORTS_PANEL: '/gestao-da-conta/relatorios',
    START: '/inicio',
    UNIT_PRICES: '/gestao-da-conta/preco-unitario',
    PRIVACITY_SECURITY: '/gestao-da-conta/privacidade-e-seguranca',
    MANAGEMENT_USERS: '/grupos-e-usuarios/grupos'
  }
};
export default PATHS;