import { useState, useEffect, useCallback } from 'react';
import StringUtils from 'utils/StringUtils';
function useDocumentFormat(value, defaultValue, documentFormatProps) {
  const options = {
    onChangeCallBack: () => {},
    onBlurCallBack: () => {},
    type: '',
    padStartZeroOnBlur: true,
    ...documentFormatProps
  };
  const [document, setDocument] = useState('');
  const removeMask = document => document.replace(/\D/g, '');
  const formatDocument = useCallback(function (string) {
    let padStart = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const {
      type
    } = options;
    const clearString = string ? removeMask(string) : '';
    switch (type.toUpperCase()) {
      case 'CPF':
        return StringUtils.formatDocument(clearString.padStart(11, padStart && clearString.length >= 3 ? '0' : ''), 'CPF');
      case 'CNPJ':
        return StringUtils.formatDocument(clearString.padStart(14, padStart && clearString.length >= 3 ? '0' : ''), 'CNPJ');
      default:
        return StringUtils.formatDocument(string);
    }
  }, [options]);
  useEffect(() => {
    if (value !== undefined) setDocument(formatDocument(value));
  }, [value]);
  useEffect(() => {
    if (defaultValue !== undefined) setDocument(formatDocument(defaultValue));
  }, [defaultValue]);
  const callbackChange = useCallback(element => {
    const {
      value
    } = element;
    const {
      onChangeCallBack
    } = options;
    const maskedValue = formatDocument(value);
    setDocument(maskedValue);
    onChangeCallBack(element, maskedValue, removeMask(maskedValue));
  }, [options]);
  const callbackBlur = useCallback(element => {
    const {
      value
    } = element;
    const {
      onBlurCallBack,
      padStartZeroOnBlur
    } = options;
    const maskedValue = formatDocument(value, padStartZeroOnBlur);
    setDocument(maskedValue);
    onBlurCallBack(element, maskedValue, removeMask(maskedValue));
  }, [options]);
  const onChange = useCallback(e => {
    e.preventDefault();
    callbackChange(e.currentTarget);
  }, [callbackChange, formatDocument]);
  const onBlur = useCallback(e => {
    e.preventDefault();
    callbackBlur(e.currentTarget);
  }, [callbackBlur]);
  return [document, removeMask(document), onChange, onBlur];
}
export default useDocumentFormat;