var _OAuth2Service;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import axios from 'axios';
import qs from 'qs';
import Oauth2CookiesUtils from 'utils/Oauth2CookiesUtils';
import { Envs } from 'constants/envs';
import ProductUtils from 'utils/ProductUtils';
import FunctionUtils from 'utils/FunctionUtils';
const ASTVSCOPE = {
  astvscope: 'BACKOFFICE'
};
class OAuth2Service {
  static prepareAuthorization() {
    return btoa(FunctionUtils.joinParamsWith(...arguments)('~ASSERTIVA~'));
  }
  static getAppCredentials() {
    const CLIENT_ID = String(Envs().CLIENT_ID);
    const CLIENT_SECRET = String(Envs().CLIENT_SECRET);
    return btoa("".concat(CLIENT_ID, ":").concat(CLIENT_SECRET));
  }
  static recoveryPassword(_ref) {
    let {
      document,
      email,
      recaptcha,
      deviceInfo
    } = _ref;
    let data;
    const deviceHeaders = {
      device: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.device.type,
      os: "".concat(deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.os.name, " ").concat(deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.os.platform).trim(),
      osVersion: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.os.version,
      brand: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.device.brand,
      model: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.device.model,
      browser: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.client.name,
      browserVersion: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.client.version,
      screenResolution: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.device.resolution
    };
    if (ProductUtils.isBackofficeInternoEnv()) {
      data = {
        cpf: document,
        email
      };
    } else {
      data = {
        document,
        email,
        recaptcha
      };
    }
    return axios({
      data,
      headers: {
        ...deviceHeaders
      },
      method: 'post',
      url: OAuth2Service.USERS_PASSWORD_URL
    });
  }
  static changePassword(_ref2) {
    let {
      password,
      passwordCheck,
      hash
    } = _ref2;
    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: OAuth2Service.prepareAuthorization(password, passwordCheck, hash)
      },
      method: 'put',
      url: OAuth2Service.USERS_PASSWORD_URL
    });
  }
  static registerPassword(_ref3) {
    let {
      password,
      passwordCheck,
      hash
    } = _ref3;
    const token = OAuth2Service.prepareAuthorization(password, passwordCheck, hash);
    return axios.post(OAuth2Service.TRIAL_USERS_PASSWORD_URL, {
      password: token
    });
  }
  static getHeaderScope() {
    return ProductUtils.isBackofficeInternoEnv() ? ASTVSCOPE : undefined;
  }
  static getRequestConfigHeaders() {
    return {
      headers: {
        Authorization: Oauth2CookiesUtils.getTokenAsAuthorization(),
        ...OAuth2Service.getHeaderScope()
      }
    };
  }
  static logout() {
    return axios.delete("".concat(this.getOAuthUrl(), "/session"), OAuth2Service.getRequestConfigHeaders());
  }
  static getSessionInfo() {
    let {
      productId = ''
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return axios.get("".concat(OAuth2Service.getOAuthUrl(), "/session?productId=").concat(productId), {
      ...OAuth2Service.getRequestConfigHeaders(),
      withCredentials: true
    });
  }
  static authenticate(_ref4) {
    let {
      code,
      redirectUrl,
      deviceInfo
    } = _ref4;
    const deviceHeaders = {
      device: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.device.type,
      os: "".concat(deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.os.name, " ").concat(deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.os.platform).trim(),
      osVersion: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.os.version,
      brand: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.device.brand,
      model: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.device.model,
      browser: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.client.name,
      browserVersion: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.client.version,
      screenResolution: deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.device.resolution
    };
    return axios({
      withCredentials: true,
      url: "".concat(this.getOAuthUrl(), "/oauth/token"),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: OAuth2Service.getAppCredentials(),
        ...OAuth2Service.getHeaderScope(),
        ...deviceHeaders
      },
      method: 'post',
      data: qs.stringify({
        grant_type: 'authorization_code',
        redirect_uri: redirectUrl,
        code
      })
    });
  }
  static refreshToken() {
    return axios({
      withCredentials: true,
      url: "".concat(this.getOAuthUrl(), "/oauth/token"),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: OAuth2Service.getAppCredentials(),
        ...OAuth2Service.getHeaderScope()
      },
      method: 'post',
      data: qs.stringify({
        grant_type: 'refresh_token'
      })
    });
  }
  static getAuthorizationCode(_ref5) {
    let {
      email,
      password,
      redirectUrl,
      recaptchaToken,
      state = '',
      deviceInfo
    } = _ref5;
    const CLIENT_ID = String(process.env.REACT_APP_CLIENT_ID);
    const params = {
      client_id: CLIENT_ID,
      redirect_uri: redirectUrl,
      state,
      response_type: 'code'
    };
    const deviceHeaders = {
      device: deviceInfo.device.type,
      os: "".concat(deviceInfo.os.name, " ").concat(deviceInfo.os.platform).trim(),
      osVersion: deviceInfo.os.version,
      brand: deviceInfo.device.brand,
      model: deviceInfo.device.model,
      browser: deviceInfo.client.name,
      browserVersion: deviceInfo.client.version,
      screenResolution: deviceInfo.device.resolution
    };
    return axios.get("".concat(this.getOAuthUrl(), "/oauth/authorize"), {
      headers: {
        Authorization: OAuth2Service.prepareAuthorization(email, password),
        recaptchaToken,
        ...OAuth2Service.getHeaderScope(),
        ...deviceHeaders
      },
      params
    });
  }
}
_OAuth2Service = OAuth2Service;
_defineProperty(OAuth2Service, "getOAuthUrl", () => "".concat(Envs().API_GATEWAY_URL, "/oauth2"));
_defineProperty(OAuth2Service, "getBaseUrl", () => ProductUtils.isBackofficeInternoEnv() ? Envs().CADASTRO_API_URL : Envs().CUSTOMER_API_URL);
_defineProperty(OAuth2Service, "USERS_PASSWORD_URL", "".concat(_OAuth2Service.getBaseUrl(), "/users/password"));
_defineProperty(OAuth2Service, "TRIAL_USERS_PASSWORD_URL", "".concat(_OAuth2Service.getBaseUrl(), "/customers/trial/users/password"));
export default OAuth2Service;