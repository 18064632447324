import { Box, styled } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
export const StyledStepsComponents = styled(Box)(() => ({
  '& .CheckCircle-green': {
    color: '#51C256'
  },
  '& .CheckCircle-black': {
    color: '#000000'
  }
}));
export const StyledMenuItem = styled(MenuItem)(() => ({
  maxWidth: '505px',
  height: '120px',
  whiteSpace: 'normal',
  '& .MuiTypography-body1': {
    fontWeight: 'bold'
  }
}));