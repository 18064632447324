var _TestUtils;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Envs } from 'constants/envs';
import MOCK_SESSION from '../mocks/session';
class TestUtils {}
_TestUtils = TestUtils;
/**
 *
 * @param rest veja a explicação do método `defaultHandlers`
 * @returns veja a explicação do método `defaultHandlers`
 */
_defineProperty(TestUtils, "oauth2TokenHandler", rest => rest.post("".concat(Envs().API_GATEWAY_URL, "/oauth2/oauth/token"), (req, res, ctx) => res(ctx.text(''))));
/**
 *
 * @param rest veja a explicação do método `defaultHandlers`
 * @returns veja a explicação do método `defaultHandlers`
 */
_defineProperty(TestUtils, "oauth2SessionHandler", rest => rest.get("".concat(Envs().API_GATEWAY_URL, "/oauth2/session"), (req, res, ctx) => res(ctx.json(MOCK_SESSION))));
/**
 * @deprecated usar nova `defaultHandlersHttp` para MSW na versão >2.x
 * @param rest passar a função rest importada da lib [msw](https://mswjs.io/). 
 * 
 * (Obs: Essa lib é [recomendada oficialmente pelo time do react-testing-library](https://testing-library.com/docs/react-testing-library/example-intro/))
 * @returns retorna os handlers padrões para mockar as requisições feitas por
 * baixo dos panos pelo assertiva-ui (token e session).
 * 
 * Você pode usar assim:
 * 
 * ```ts
    import { rest } from 'msw';
    import { setupServer } from 'msw/node';
  *  const server = setupServer(
      ...TestUtils.defaultHandlers(rest),
      rest.get(domainsUrl, (req, res, ctx) => res(ctx.json(mockDomains)))
    );
 * ```
 */
_defineProperty(TestUtils, "defaultHandlers", rest => [_TestUtils.oauth2TokenHandler(rest), _TestUtils.oauth2SessionHandler(rest), rest.post("".concat(Envs().API_URL, "/analytics/v3/metric"), (req, res, ctx) => res(ctx.status(200)))]);
_defineProperty(TestUtils, "defaultHandlersHttp", (http, HttpResponse) => [http.post("".concat(Envs().API_GATEWAY_URL, "/oauth2/oauth/token"), () => HttpResponse.text('')), http.get("".concat(Envs().API_GATEWAY_URL, "/oauth2/session"), () => HttpResponse.json(MOCK_SESSION)), http.post("".concat(Envs().API_URL, "/analytics/v3/metric"), () => new HttpResponse(null, {
  status: 200
}))]);
export default TestUtils;