var _RoutesUtils;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import _isEmpty from 'lodash/isEmpty';
import { Envs } from 'constants/envs';
import PATHS from 'constants/paths';
import { GESTAO_DA_CONTA_ID } from 'constants/productIds';
import { useSaldoExtratoFF } from 'hooks';
import AuthUtils from 'utils/AuthUtils';
import FunctionsUtils from 'utils/FunctionUtils';
import ProductUtils from 'utils/ProductUtils';
import { commonProductRoutes } from './commonRoutes';
import { newCommonPainelRoutes } from './newCommonRoutes';
const isValidPathLinkNested = route => {
  const {
    link,
    path,
    nested
  } = route;
  if (!nested && !link && !path) {
    return new Error('path is required when link is null or empty.');
  }
  if (!['string', 'array', 'undefined'].includes(FunctionsUtils.getTypeOf(path))) {
    return new Error("Failed prop type: Invalid prop 'path' of type `".concat(typeof path, "`, expected `string` or `array string`."));
  }
  return null;
};
class RoutesUtils {}
_RoutesUtils = RoutesUtils;
_defineProperty(RoutesUtils, "normalizeParam", param => {
  if (!param) return param;
  return param && FunctionsUtils.getTypeOf(param) === 'function' ? param() : param;
});
_defineProperty(RoutesUtils, "renderIf", route => {
  if (!route) return false;
  return route.renderIf && FunctionsUtils.getTypeOf(route.renderIf) === 'function' ? route.renderIf() : true;
});
_defineProperty(RoutesUtils, "normalizeRoute", route => {
  const hasNested = route.nested && !_isEmpty(route.nested) && {
    nested: route.nested.filter(route => _RoutesUtils.isAvailableRoute(route))
  };
  return {
    ...route,
    collapsable: _RoutesUtils.normalizeParam(route.collapsable),
    label: _RoutesUtils.normalizeParam(route.label),
    path: _RoutesUtils.normalizeParam(route.path),
    redirect: _RoutesUtils.normalizeParam(route.redirect),
    link: _RoutesUtils.normalizeParam(route.link),
    ...hasNested
  };
});
_defineProperty(RoutesUtils, "hasPermission", route => {
  if (!route) return false;
  return !route.permissions || AuthUtils.getUser().hasPermission(route.permissions);
});
_defineProperty(RoutesUtils, "isAvailableRoute", route => {
  return _RoutesUtils.renderIf(route) && _RoutesUtils.hasPermission(route);
});
_defineProperty(RoutesUtils, "filterNestedHasPermission", nested => nested.filter(_RoutesUtils.hasPermission));
_defineProperty(RoutesUtils, "filterAvailableRoutes", routes => {
  if (!routes) return [];
  return routes.filter(route => {
    isValidPathLinkNested(route);
    return _RoutesUtils.isAvailableRoute(route);
  }).map(route => ({
    ..._RoutesUtils.normalizeRoute(route)
  }));
});
_defineProperty(RoutesUtils, "getCommonProductRoutes", routes => {
  const finalRoutes = commonProductRoutes;
  const productId = ProductUtils.getProductIdByProductNameEnv();
  const productRouteIndex = finalRoutes.findIndex(route => productId === route.productId);
  if (productId === GESTAO_DA_CONTA_ID) {
    return finalRoutes;
  }
  if (productRouteIndex === -1 || ProductUtils.isBackofficeInternoEnv()) {
    return routes;
  }
  const productRoute = routes.find(route => productId === route.productId);
  finalRoutes[productRouteIndex] = {
    ...finalRoutes[productRouteIndex],
    link: undefined,
    absolute: false,
    ...productRoute,
    startOpen: true,
    nested: productRoute ? productRoute.nested : routes
  };
  return finalRoutes;
});
_defineProperty(RoutesUtils, "getCurrentProductRoutes", routes => {
  const productRoute = routes.find(route => ProductUtils.getProductIdByProductNameEnv() === route.productId);
  return productRoute;
});
_defineProperty(RoutesUtils, "setActionToCurrentProductRoute", (routes, action) => {
  const productId = ProductUtils.getProductIdByProductNameEnv();
  return routes.map(route => {
    if (route.nested) {
      return {
        ...route,
        nested: route.nested.map(child => {
          if (child.productId === productId) {
            return {
              ...child,
              action,
              link: undefined,
              absolute: false
            };
          }
          return child;
        })
      };
    }
    return route;
  });
});
_defineProperty(RoutesUtils, "setCallbackToNestedRoutes", (routes, callback) => {
  return routes.map(route => {
    if (route.nested) {
      return {
        ...route,
        nested: route.nested.map(child => ({
          ...child,
          onClickCallback: () => callback(child)
        }))
      };
    }
    return route;
  });
});
_defineProperty(RoutesUtils, "getCommonPainelRoutes", routes => {
  const {
    FEATURE_FLAG_SESSAO_SALDO
  } = useSaldoExtratoFF();
  const finalRoutes = newCommonPainelRoutes(FEATURE_FLAG_SESSAO_SALDO);
  const painelRoute = routes.find(route => route.productId === GESTAO_DA_CONTA_ID);
  const painelRouteIndex = finalRoutes.findIndex(route => route.productId === GESTAO_DA_CONTA_ID);
  if (painelRoute) {
    finalRoutes[painelRouteIndex] = {
      ...finalRoutes[painelRouteIndex],
      link: undefined,
      absolute: false,
      ...painelRoute,
      startOpen: true
    };
  }
  return finalRoutes;
});
_defineProperty(RoutesUtils, "isManagementUsersRouteInBackofficeCliente", () => {
  const currentPath = window.location.pathname.split('/')[1];
  const isManagementUsers = PATHS.PAINEL_PATHS.MANAGEMENT_USERS.includes(currentPath);
  return Boolean(ProductUtils.isBackofficeClienteEnv() && isManagementUsers);
});
_defineProperty(RoutesUtils, "getPainelRouteWithCorrectLink", route => {
  if (_RoutesUtils.isManagementUsersRouteInBackofficeCliente() && route.path === PATHS.PAINEL_PATHS.MANAGEMENT_USERS) {
    return {
      ...route,
      absolute: false,
      link: window.location.pathname
    };
  }
  if (!ProductUtils.isBackofficeClienteEnv() && !route.link || _RoutesUtils.isManagementUsersRouteInBackofficeCliente()) {
    return {
      ...route,
      absolute: true,
      link: "".concat(Envs().PAINEL_URL).concat(route.path || '')
    };
  }
  return route;
});
_defineProperty(RoutesUtils, "resolvePainelRoutesLinks", routes => {
  return routes.map(route => {
    if (route.nested) {
      return {
        ...route,
        nested: route.nested.map(_RoutesUtils.getPainelRouteWithCorrectLink)
      };
    }
    return _RoutesUtils.getPainelRouteWithCorrectLink(route);
  });
});
export default RoutesUtils;