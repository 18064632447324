import { styled } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
export const StyledPhoneCard = styled('div')(_ref => {
  let {
    theme
  } = _ref;
  return {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    minHeight: 190
  };
});
export const StyledPhoneCardTitle = styled(Typography)(_ref2 => {
  let {
    theme
  } = _ref2;
  return {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '28.8px'
  };
});
export const DontDisturbIcon = styled(PhoneDisabledIcon)({
  color: '#FFBB43'
});
export const DontDisturbChip = styled(Chip)({
  backgroundColor: '#FFEED0'
});
export const StyledPhoneCardBox = styled(Box)(_ref3 => {
  let {
    theme
  } = _ref3;
  return {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, 300px )',
    gap: theme.spacing(2)
  };
});