import { Envs } from 'constants/envs';
/**
 * Function used to send analytics to Google Tag Manager
 * @param {Object} params
 */
export const sendAnalytics = _ref => {
  let {
    event,
    page,
    value
  } = _ref;
  const productName = Envs().PRODUCT_NAME || '';
  let eventObj = {
    event: "".concat(productName, "-").concat(page, "-").concat(event)
  };
  if (value) {
    eventObj = {
      ...eventObj,
      value
    };
  }
  try {
    var _window;
    (_window = window) === null || _window === void 0 || (_window = _window.dataLayer) === null || _window === void 0 || _window.push(eventObj);
  } catch (error) {
    console.error(error);
  }
};