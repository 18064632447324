function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import AccessDaysUtils from 'utils/accessDaysUtils';
import CONSTANTS from '../containers/constants/userGroup';
import { lastWordString } from '../containers/UserGroup/utils/UserGroupUtils';
class UserGroupModel {
  constructor() {
    let group = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    _defineProperty(this, "errors", {
      hasErrors: false,
      messages: ''
    });
    _defineProperty(this, "nameErrors", {
      hasErrors: false,
      messages: ''
    });
    _defineProperty(this, "groupName", '');
    _defineProperty(this, "consumptionLimit", 0);
    _defineProperty(this, "ips", []);
    _defineProperty(this, "limitationCustomer", 0);
    _defineProperty(this, "days", []);
    _defineProperty(this, "status", false);
    _defineProperty(this, "accessStartTime", new Date());
    _defineProperty(this, "accessEndTime", new Date());
    _defineProperty(this, "accessStartTimeWeekends", new Date());
    _defineProperty(this, "accessEndTimeWeekends", new Date());
    _defineProperty(this, "users", []);
    _defineProperty(this, "profiles", []);
    _defineProperty(this, "hasSomeErrorInGroupName", () => !this.groupName || this.groupName.trim() === '');
    _defineProperty(this, "getStatus", status => ({
      label: status ? CONSTANTS.STATUS.ATIVO : CONSTANTS.STATUS.INATIVO,
      toParams: status ? {
        id: 1,
        name: lastWordString(CONSTANTS.STATUS.ATIVO)
      } : {
        id: 2,
        name: lastWordString(CONSTANTS.STATUS.INATIVO)
      }
    }));
    _defineProperty(this, "setStatus", status => {
      if (status.id === 1) {
        return true;
      }
      return false;
    });
    this.groupName = group.groupName || '';
    this.status = group.status || false;
    this.ips = group.ips || [];
    this.days = group.days || [];
    this.profiles = group.profiles || [];
    this.users = group.users || [];
    this.accessStartTime = new Date();
    this.accessEndTime = new Date();
    this.accessStartTimeWeekends = new Date();
    this.accessEndTimeWeekends = new Date();
    this.users = [];
  }
  validateGroupName() {
    if (this.hasSomeErrorInGroupName()) {
      this.nameErrors = {
        hasErrors: true,
        messages: 'Nome é obrigatório.'
      };
      return this.nameErrors;
    }
    this.nameErrors = {
      hasErros: false,
      messages: ''
    };
    return this.nameErrors;
  }
  validateConsumptionLimit() {
    if (this.consumptionLimit === 0) {
      this.errors = {
        hasErros: true,
        messages: 'Limite de consumo inválido.'
      };
      return this.errors;
    }
    if (this.consumptionLimit > this.limitationCustomer) {
      this.errors = {
        hasErros: true,
        messages: CONSTANTS.CONSUMPTIONLIMIT.EXCEEDED
      };
      return this.errors;
    }
    this.errors = {
      hasErros: false,
      messages: ''
    };
    return this.errors;
  }

  /**
   *
   * O formulário de grupo só é responsável por validar os usuários no caso de criação de novo grupo.
   * Ao adicionar usuários a um grupo já criado (edição), outro formulário faz as validações, não este.
   *
   */
  hasErrorsUsersAddGroup(groupId) {
    const isEditGroup = Boolean(groupId);
    if (isEditGroup) return false;
    const isAllUsersValid = this.users.every(u => u.emails && u.emails.length > 0 && u.idPerfil);
    return !isAllUsersValid;
  }
  toParams(groupId) {
    const obj = {};
    if (groupId) {
      obj.status = this.getStatus(this.status).toParams;
    }
    obj.name = this.groupName;
    obj.limitationPrice = this.consumptionLimit;
    obj.ips = this.ips ? this.ips.map(value => value) : [];
    obj.accessLimitation = AccessDaysUtils.generateDays(this.days, this.accessStartTime, this.accessEndTime, this.accessStartTimeWeekends, this.accessEndTimeWeekends);
    const deleteUsersEmptys = this.users.filter(val => val.emails && val.emails.length > 0 && val.idPerfil);
    if (deleteUsersEmptys.length > 0) {
      obj.profiles = deleteUsersEmptys.map(users => ({
        id: users.idPerfil,
        emails: users.emails
      }));
    }
    return obj;
  }
}
export default UserGroupModel;