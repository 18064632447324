/* eslint-disable no-plusplus */
import axios from 'axios';
import _isUndefined from 'lodash/isUndefined';
import messages from 'constants/messages';
import AuthUtils from './AuthUtils';
import ErrorUtils from './ErrorUtils';
import NotificationCookiesUtils from './NotificationCookiesUtils';
import Oauth2CookiesUtils from './Oauth2CookiesUtils';
/**
 * @param timeout timeout das requisições em milissegundos. Default: 60000 (um minuto)
 * @param autoRefreshToken por default o interceptor fará o refresh token automaticamente,
 *  caso não deseje esse comportamento informar esse parametro como autoRefreshToken: false
 * @param onResponseReject callback chamado nos responses das requisições por falha
 * @param onResponseResolve callback chamado nos responses das requisições com sucesso
 * @param onRequestReject callback chamado nos requestes das requisições com falha
 * @param onRequestResolve callback chamado nos requestes das requisições com sucesso
 * @param onNotAuthenticate callback chamado quando o usuário não estiver autenticado
 */
const createAxiosInstance = function () {
  let {
    timeout = 60000,
    autoRefreshToken = true,
    onResponseReject = () => {},
    onResponseResolve = () => {},
    onRequestResolve = () => {},
    onRequestReject = () => {},
    onNotAuthenticate = () => {},
    ...others
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const axiosInstance = axios.create({
    timeout,
    ...others
  });
  const isTokenExpiredError = error => {
    var _error$response;
    return (error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 401;
  };
  const handleNotAuthorized = error => {
    const errorMessage = ErrorUtils.normalizeErrorMessage(error);
    if (!_isUndefined(Oauth2CookiesUtils.getToken())) {
      if ([messages.SHARED_LOGIN, messages.TIME_LIMIT].includes(errorMessage) && errorMessage) {
        NotificationCookiesUtils.setFinisedSessionNotificationCookie(errorMessage);
      } else {
        NotificationCookiesUtils.setFinisedSessionNotificationCookie(messages.INVALID_SESSION);
      }
    }
    Oauth2CookiesUtils.clearCookies();
    onNotAuthenticate(error);
  };
  axiosInstance.interceptors.request.use(async request => {
    try {
      if (!Oauth2CookiesUtils.getToken()) {
        await AuthUtils.refreshToken();
      } else if (autoRefreshToken) {
        await AuthUtils.lazyRefreshToken();
      }
      request.headers.Authorization = Oauth2CookiesUtils.getTokenAsAuthorization();
    } catch (error) {
      if (isTokenExpiredError(error)) {
        handleNotAuthorized(error);
        return Promise.resolve('Ok!');
      } else {
        onRequestReject(error);
      }
      return Promise.reject(error);
    }
    onRequestResolve(request);
    return request;
  }, error => {
    if (isTokenExpiredError(error)) {
      handleNotAuthorized(error);
      return Promise.resolve('Ok!');
    }
    onResponseReject(error);
    return Promise.reject(error);
  });
  axiosInstance.interceptors.response.use(response => {
    onResponseResolve(response);
    return response;
  }, async error => {
    if (isTokenExpiredError(error)) {
      handleNotAuthorized(error);
      return Promise.resolve('Ok!');
    }
    onResponseReject(error);
    return Promise.reject(error);
  });
  return axiosInstance;
};
export default createAxiosInstance;