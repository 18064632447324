/* 
  1	Ativo
  2	Inativo
  3	Bloqueado por pendência financeira
  4	Blacklist
  5	Pendente
  6	Fraudador
  7	Pendente de regularização na Receita Federal
  8	Bloqueio por pendência na Receita Federal
*/
export default {
  ACTIVE: 1,
  INACTIVE: 2,
  FINANCIAL_PENDING_BLOCK: 3,
  BLACKLIST: 4,
  PENDING: 5,
  FRAUDSTER: 6,
  RF_PENDING_REGULARIZATION: 7,
  RF_PENDING_BLOCK: 8
};