import RuntimeEnvs from 'utils/RuntimeEnvs';
function getProcessEnvByName(name, defaultValue) {
  return process.env["REACT_APP_ASSERTIVA_".concat(name)] || defaultValue;
}
function applyDefaultEnvsWhenFound(environments) {
  const envsRunTime = RuntimeEnvs.getEnvs();
  const envNames = Object.keys(environments);
  const newEnvironment = {
    ...environments
  };
  for (let i = 0; i <= envNames.length; i++) {
    const name = envNames[i];
    const currentValue = newEnvironment[name];
    if (name) {
      newEnvironment[name] = getProcessEnvByName(name, currentValue);
    }
    if (envsRunTime[name]) {
      newEnvironment[name] = envsRunTime[name];
    }
  }
  return newEnvironment;
}
function applySpecificEnvsWhenFound(environments) {
  return applyDefaultEnvsWhenFound(environments);
}
export { applyDefaultEnvsWhenFound, applySpecificEnvsWhenFound };