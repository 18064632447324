const css = `.users-profile .label-multiple-mails {
  margin-left: 10px;
}
.users-profile .title-profile {
  margin-left: 0px;
  margin-top: 20px;
}
.users-profile .description-profile {
  margin-left: 8px;
}
.users-profile .fade {
  display: block;
  animation: fadeInFromNone 1s ease-out;
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvcnVubmVyL3dvcmsvYXNzZXJ0aXZhLXVpL2Fzc2VydGl2YS11aS9zcmMvY29udGFpbmVycy9Vc2VyR3JvdXAvY29tcG9uZW50cy9Vc2VyR3JvdXBQcm9maWxlIiwic291cmNlcyI6WyJVc2VyR3JvdXBQcm9maWxlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRTs7QUFFRjtFQUNFO0VBQ0E7O0FBRUY7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7OztBQUlKO0VBQ0U7SUFDRTtJQUNBOztFQUdGO0lBQ0U7SUFDQTs7RUFHRjtJQUNFO0lBQ0EifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
