import { useState } from 'react';

// Services
import MFAService from 'services/MFAService';
import Notifier from 'components/Notification/Notifier';
import LoadingManager from 'components/Loading/LoadingManager';

// Utils
import { ErrorUtils } from 'utils';
import Store from '../../UsersTableStore';
const useResetMFADialog = customerId => {
  const [openSuccessMFAResetDialog, setOpenSuccessMFAResetDialog] = useState(false);
  const toggleSuccessMFAResetDialog = () => setOpenSuccessMFAResetDialog(!openSuccessMFAResetDialog);
  const handleResetMFA = async () => {
    try {
      LoadingManager.show();
      await MFAService.resetUserMFA(Store.state.selectedUser.id);
      toggleSuccessMFAResetDialog();
      Store.reloadUsers(customerId);
    } catch (error) {
      Notifier.error(ErrorUtils.normalizeErrorMessage(error));
    } finally {
      LoadingManager.close();
      Store.toggleResetMFADialog();
    }
  };
  return {
    toggleResetMFADialog: Store.toggleResetMFADialog,
    openSuccessMFAResetDialog,
    toggleSuccessMFAResetDialog,
    handleResetMFA
  };
};
export default useResetMFADialog;