var _ProfileService;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { PROFILES } from 'containers/Profiles/components/CreateProfileForm/constants';
import ProductUtils from 'utils/ProductUtils';
import { axiosInstance, getBaseUrl } from './baseService';
class ProfileService {}
_ProfileService = ProfileService;
_defineProperty(ProfileService, "getNormalizedUrl", () => {
  return "".concat(getBaseUrl()).concat(!ProductUtils.isBackofficeInternoEnv() ? '/customers' : '');
});
_defineProperty(ProfileService, "getCustomerProfiles", function (_ref) {
  let {
    customerId = 0
  } = _ref;
  let profileId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (ProductUtils.isBackofficeInternoEnv() && !customerId) {
    console.error('[ProfileService] getCustomerProfiles: customerId is required.');
  }
  return axiosInstance.get("".concat(getBaseUrl(), "/customers").concat(ProductUtils.isBackofficeInternoEnv() ? "/".concat(customerId) : '', "/profiles").concat(profileId ? "/".concat(profileId) : ''));
});
_defineProperty(ProfileService, "getOwnProfile", () => {
  return axiosInstance.get("".concat(_ProfileService.getNormalizedUrl(), "/users/profile"));
});
_defineProperty(ProfileService, "updateOwnProfile", payload => {
  return axiosInstance.put("".concat(_ProfileService.getNormalizedUrl(), "/users/profile"), payload);
});
_defineProperty(ProfileService, "sendNewPassword", _ref2 => {
  let {
    oldPassword,
    newPassword
  } = _ref2;
  return axiosInstance.put("".concat(_ProfileService.getNormalizedUrl(), "/users/profile/password"), {
    oldPassword,
    newPassword
  });
});
_defineProperty(ProfileService, "getProducts", customerId => {
  if (ProductUtils.isBackofficeInternoEnv()) {
    return axiosInstance.get("".concat(getBaseUrl(), "/customers/").concat(customerId, "/contracts/products"));
  }
  return axiosInstance.get("".concat(getBaseUrl(), "/customers/contracts/products"));
});
_defineProperty(ProfileService, "getBaseProfiles", customerId => {
  const customer = {
    customerId
  };
  const requestOne = _ProfileService.getCustomerProfiles(customer, PROFILES.ADMINISTRATOR.ID);
  const requestTwo = _ProfileService.getCustomerProfiles(customer, PROFILES.MANAGER.ID);
  const requestThree = _ProfileService.getCustomerProfiles(customer, PROFILES.FINANCIAL.ID);
  const requestFour = _ProfileService.getCustomerProfiles(customer, PROFILES.OPERATOR.ID);
  return [requestOne, requestTwo, requestThree, requestFour];
});
_defineProperty(ProfileService, "createNewPersonalizedProfile", (personalizedProfile, customerId) => {
  if (ProductUtils.isBackofficeInternoEnv()) {
    return axiosInstance.post("".concat(getBaseUrl(), "/customers/").concat(customerId, "/profiles"), personalizedProfile);
  }
  return axiosInstance.post("".concat(getBaseUrl(), "/customers/profiles"), personalizedProfile);
});
export default ProfileService;