const MESSAGES = {
  BIRTHDATE_IS_INVALID: 'Informe uma data válida.',
  BIRTHDATE_NOT_ALLOWED: 'Data de nascimento não permitida.',
  CURRENT_PASSWORD_IS_REQUIRED: 'Senha atual é obrigatória.',
  MOBILE_IS_INVALID: 'Informe um celular válido.',
  NAME_IS_REQUIRED: 'Informe o nome.',
  NEW_PASSWORD_IS_REQUIRED: 'Nova senha é obrigatória.',
  PASSWORDS_ARE_DIFFERENT: 'As senhas informadas não são correspondentes.',
  PASSWORD_CONFIRMATION_IS_REQUIRED: 'A confirmação da senha é obrigatória.',
  PASSWORD_IS_INVALID: 'A senha informada não atende os requisitos necessários.',
  PASSWORD_MUST_BE_DIFFERENT_ACTUAL: 'A nova senha deve ser diferente em relação à senha atual.',
  PHONE_IS_INVALID: 'Informe um telefone fixo válido.'
};
export default MESSAGES;