import Cookies from 'js-cookie';
import { COOKIES_OPTIONS } from 'constants/cookies';
import { getCookieName } from './CookiesUtil';
const FINISHED_SESSION_NOTIFICATION = 'finishedSessionNotification';
export const finishedSessionNotificationCookie = getCookieName(FINISHED_SESSION_NOTIFICATION);
class NotificationCookiesUtils {
  static getFinisedSessionNotificationCookie() {
    return Cookies.get(finishedSessionNotificationCookie);
  }
  static setFinisedSessionNotificationCookie(message) {
    Cookies.set(finishedSessionNotificationCookie, message, COOKIES_OPTIONS);
  }
  static removeFinisedSessionNotificationCookie() {
    Cookies.remove(finishedSessionNotificationCookie, COOKIES_OPTIONS);
  }
}
export default NotificationCookiesUtils;