function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable @typescript-eslint/no-empty-function */
import PubSub from 'pubsub-js';
import UserService from 'services/UserService';
import ErrorUtils from 'utils/ErrorUtils';
import ArrayUtils from 'utils/ArrayUtils';
import UserModel from 'models/UserModel';
import LoadingManager from 'components/Loading/LoadingManager';
import Notifier from 'components/Notification/Notifier';
import MESSAGES from 'constants/messages';
import AuthUtils from 'utils/AuthUtils';
import { CREATE_USER_COMPANY_LEVEL, CREATE_USER_GROUP_LEVEL, CREATE_USERS } from 'constants/permissions';
import CONSTANTS from '../constants';
const NOTIFY_STATE_ADD_NEW_USER = 'NOTIFY_STATE_ADD_NEW_USER';
const FIRST_ITEM_INDEX = 0;
const {
  USER_STATUS
} = CONSTANTS;
class AddNewUSerDialogStore {
  constructor() {
    var _this = this;
    _defineProperty(this, "subscription", null);
    _defineProperty(this, "initialState", Object.freeze({
      emails: [],
      profiles: [],
      groups: [],
      selectedProfile: {
        id: 0,
        name: 'Perfil'
      },
      selectedGroup: {
        id: 0,
        name: 'Grupo'
      },
      errors: {
        hasErros: false,
        messages: {}
      },
      disabledHandleGroups: false,
      disabledHandleProfiles: false
    }));
    _defineProperty(this, "state", {
      ...this.initialState
    });
    _defineProperty(this, "customerId", 0);
    // FIXME: Verificação provisória com base nos perfis e grupo
    _defineProperty(this, "hasCreateUsersPermissionIntoGroupOrProfile", _ref => {
      let {
        profile,
        group
      } = _ref;
      const user = AuthUtils.getUser();
      if (user.hasPermission(CREATE_USERS)) {
        return true;
      }
      switch (user.getProfileName().toLowerCase()) {
        case 'administrador':
          /* O administrador pode criar qualquer usuário em qualquer grupo ou perfil da empresa desde que tenha permissão. */
          return user.hasPermission(CREATE_USER_COMPANY_LEVEL);
        case 'gerente':
          /* O gerente só criar usuários do perfil operador e caso tenha permissão */
          return user.hasPermission(CREATE_USER_GROUP_LEVEL) && (!group || user.group.id === group.id) && (!profile || (profile.name || '').toLowerCase() === 'operador');
        default:
          return false;
      }
    });
    _defineProperty(this, "init", _ref2 => {
      let {
        customerId,
        groupId,
        selectedProfile,
        groups,
        profiles,
        emails,
        hideGroup
      } = _ref2;
      if (customerId) {
        this.customerId = customerId;
      }
      if (groups) {
        this.state.groups = groups.filter(group => this.hasCreateUsersPermissionIntoGroupOrProfile({
          group
        }));
        if (this.state.groups.length === 1) {
          this.state.disabledHandleGroups = true;
          this.state.selectedGroup = {
            ...this.state.groups[FIRST_ITEM_INDEX]
          };
        }
      }
      if (profiles) {
        this.state.profiles = profiles.filter(profile => this.hasCreateUsersPermissionIntoGroupOrProfile({
          profile
        }));
        if (this.state.profiles.length === 1) {
          this.state.disabledHandleProfiles = true;
          this.state.selectedProfile = {
            ...this.state.profiles[FIRST_ITEM_INDEX]
          };
        } else if (selectedProfile) {
          this.handleProfile(selectedProfile.id);
        }
      }
      if (emails) {
        this.state.emails = emails;
      }
      if (hideGroup && groupId) {
        this.handleGroup(groupId);
      } else if (hideGroup && !groupId) {
        console.error('[AddNewUSerDialog]: groupId é obrigatório quando hideGroup é true.');
      }
      this.notify();
    });
    _defineProperty(this, "notify", () => {
      PubSub.publish(NOTIFY_STATE_ADD_NEW_USER, {
        ...this.state
      });
    });
    _defineProperty(this, "subscribe", callback => {
      this.subscription = PubSub.subscribe(NOTIFY_STATE_ADD_NEW_USER, (_, state) => {
        callback(state);
      });
    });
    _defineProperty(this, "unsubscribe", () => {
      if (this.subscription) {
        PubSub.unsubscribe(this.subscription);
      }
    });
    _defineProperty(this, "reset", () => {
      this.state = {
        ...this.initialState
      };
      this.notify();
    });
    _defineProperty(this, "handleProfile", profileId => {
      if (profileId) {
        try {
          const profile = ArrayUtils.findByParams(this.state.profiles, {
            id: profileId
          });
          if (profile) {
            this.state.selectedProfile = profile;
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        this.state.selectedProfile = {
          ...this.initialState.selectedProfile
        };
      }
      this.notify();
    });
    _defineProperty(this, "handleGroup", groupId => {
      if (groupId) {
        try {
          const group = ArrayUtils.findByParams(this.state.groups, {
            id: groupId
          });
          if (group) {
            this.state.selectedGroup = group;
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        this.state.selectedGroup = {
          ...this.initialState.selectedGroup
        };
      }
      this.notify();
    });
    _defineProperty(this, "handleEmails", emails => {
      this.state.emails = [...emails];
      this.notify();
    });
    _defineProperty(this, "hasProfileSelected", () => {
      return this.state.selectedProfile.id !== 0;
    });
    _defineProperty(this, "saveUser", function () {
      let callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : () => {};
      _this.validate();
      if (_this.state.errors.hasErros) return;
      const profile = {
        ..._this.state.selectedProfile
      };
      delete profile.products; // o back reclama se mandar products junto
      delete profile.description; // o back reclama se mandar a description junto

      const group = ArrayUtils.findByParams(_this.state.groups, {
        id: _this.state.selectedGroup.id
      });
      LoadingManager.show();
      UserService.sendLinkToNewUsers({
        customerId: _this.customerId,
        groupId: _this.state.selectedGroup.id,
        profilesEmails: [{
          emails: [..._this.state.emails],
          profileId: profile.id
        }]
      }).then(response => {
        Notifier.success(response.data.message, {
          autoHideDuration: 6000
        });
        _this.state.emails.forEach(email => {
          /* FIXME: O back deveria devolver o(s) usuário(s) criado(s), como não devolve
           *  precisamos criar um usuário pra entrar na listagem e evitar fazer um novo request
           *  para atualizar a mesma.
           */
          const user = new UserModel({
            id: 0,
            email,
            name: '',
            profile,
            group,
            status: USER_STATUS.PENDING
          });
          callback(user);
        });
        _this.state.selectedProfile = {
          ..._this.initialState.selectedProfile
        };
        _this.state.selectedGroup = {
          ..._this.initialState.selectedGroup
        };
        _this.notify();
      }).catch(error => {
        Notifier.error(ErrorUtils.normalizeErrorMessage(error));
      }).finally(() => {
        LoadingManager.close();
      });
    });
    _defineProperty(this, "clearErrors", name => {
      if (name) {
        delete this.state.errors.messages[name];
      } else {
        this.state.errors.messages = {};
      }
      this.notify();
    });
    _defineProperty(this, "validate", () => {
      this.clearErrors();
      if (!this.state.emails.length) {
        this.state.errors.messages.emails = MESSAGES.REQUIRED_AT_LEAST_ONCE_EMAIL;
      }
      if (!this.state.selectedGroup.id) {
        this.state.errors.messages.group = MESSAGES.REQUIRED_GROUP;
      }
      if (!this.state.selectedProfile.id) {
        this.state.errors.messages.profile = MESSAGES.REQUIRED_PROFILE;
      }
      if (Object.keys(this.state.errors.messages).length) {
        this.state.errors.hasErros = true;
      } else {
        this.state.errors.hasErros = false;
      }
      this.notify();
    });
  }
}
export default new AddNewUSerDialogStore();