import { useRef, useState } from 'react';
import DeviceDetector from 'device-detector-js';
import AuthUtils from 'utils/AuthUtils';
import LoadingManager from 'components/Loading/LoadingManager';
import ValidationUtils from 'utils/ValidationUtils';
import useRulesValidation from 'hooks/useRulesValidation';
const useLogin = _ref => {
  let {
    onSuccess,
    onError
  } = _ref;
  const recaptchaRef = useRef(null);
  const [state, setState] = useState({
    email: '',
    password: '',
    showPassword: false,
    mfa: null
  });
  const {
    errors,
    validate,
    clearError
  } = useRulesValidation([{
    field: 'email',
    messageError: 'E-mail é obrigatório.',
    hasErrors: () => !state.email
  }, {
    field: 'email',
    messageError: 'E-mail inválido.',
    hasErrors: () => !ValidationUtils.isValidEmail(state.email)
  }, {
    field: 'password',
    messageError: 'Senha é obrigatória.',
    hasErrors: () => !state.password
  }, {
    field: 'password',
    messageError: 'Senha deve possuir no mínimo 8 caracteres.',
    hasErrors: () => !ValidationUtils.isLengthGte(state.password, 8)
  }]);
  const handleState = (key, value) => {
    setState(prev => ({
      ...prev,
      [key]: value
    }));
  };
  const togglePasswordView = () => {
    handleState('showPassword', !state.showPassword);
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (validate() && recaptchaRef) {
      recaptchaRef.current.execute();
    }
  };
  const doSubmit = recaptchaToken => {
    const {
      email,
      password,
      mfa
    } = state;
    const detector = new DeviceDetector();
    const device = detector.parse(navigator.userAgent);
    const deviceInfo = {
      ...device,
      device: {
        ...device.device,
        resolution: "".concat(window.screen.width, "x").concat(window.screen.height)
      }
    };
    LoadingManager.show();
    AuthUtils.login({
      email,
      password,
      redirectUrl: window.location.href,
      recaptchaToken,
      deviceInfo
    }).then(authenticateResponse => {
      if (authenticateResponse.data.mfa) {
        handleState('mfa', authenticateResponse.data.mfa);
        return;
      }
      onSuccess(authenticateResponse);
    }).catch(error => {
      if (onError) onError(error);
    }).finally(() => LoadingManager.close());
  };
  return {
    state,
    errors,
    recaptchaRef,
    validate,
    doSubmit,
    handleSubmit,
    handleState,
    togglePasswordView,
    clearError
  };
};
export default useLogin;