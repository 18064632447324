const css = `.empty-data .empty-data-image {
  min-height: 70px;
  min-width: 70px;
}
.empty-data .empty-data-subtitle {
  font-size: 16px;
  letter-spacing: 0.09px;
  color: #00000060;
  opacity: 1;
  font-weight: 500;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvcnVubmVyL3dvcmsvYXNzZXJ0aXZhLXVpL2Fzc2VydGl2YS11aS9zcmMvY29tcG9uZW50cy9FbXB0eURhdGEiLCJzb3VyY2VzIjpbIkVtcHR5RGF0YS5zY3NzIiwiLi4vLi4vYXNzZXRzL3N0eWxlcy9fdmFyaWFibGVzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0U7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBLE9DWGU7RURZZjtFQUNBIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
