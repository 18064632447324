function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import GroupService from 'services/GroupService';
import AuthUtils from 'utils/AuthUtils';
import AUNotifier from '../../../components/Notification/Notifier';
import ErrorUtils from '../../../utils/ErrorUtils';
import AULoadingManager from '../../../components/Loading/LoadingManager';
const FlagsPermission = {
  CREATE_GROUP: ['BACKOFFICE:CLIENTES:GRUPOS:CRIAR', 'ADMINISTRATIVO:GRUPOS:CRIAR:EMPRESA'],
  UPDATE_GROUP: ['BACKOFFICE:CLIENTES:GRUPOS:EDITAR', 'ADMINISTRATIVO:GRUPOS:EDITAR:EMPRESA', 'ADMINISTRATIVO:GRUPOS:EDITAR:GRUPO'],
  VIEW_GROUP: ['BACKOFFICE:CLIENTES:GRUPOS:VISUALIZAR', 'ADMINISTRATIVO:GRUPOS:VISUALIZAR:EMPRESA', 'ADMINISTRATIVO:GRUPOS:VISUALIZAR:GRUPO']
};
class UserGroupListStore {
  constructor() {
    _defineProperty(this, "getGroups", async _ref => {
      let {
        customerId
      } = _ref;
      AULoadingManager.show();
      try {
        const res = await GroupService.getGroups({
          customerId
        }).catch(error => {
          AULoadingManager.close();
          AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
        });
        const {
          data
        } = res;
        AULoadingManager.close();
        return data;
      } catch (error) {
        AULoadingManager.close();
        return [];
      }
    });
    _defineProperty(this, "hasCreateGroupByPermission", () => AuthUtils.getUser().hasPermission(FlagsPermission.CREATE_GROUP));
    _defineProperty(this, "hasUpdateGroupByPermission", () => AuthUtils.getUser().hasPermission(FlagsPermission.UPDATE_GROUP));
    _defineProperty(this, "hasViewGroupByPermission", () => AuthUtils.getUser().hasPermission(FlagsPermission.VIEW_GROUP));
  }
}
export default new UserGroupListStore();