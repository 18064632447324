const css = `.confirmation-screen .register {
  background-size: auto 100vh;
  background-repeat: no-repeat;
  height: 100vh;
}
.confirmation-screen .register .card {
  width: 520px;
}
.confirmation-screen .register .card .header {
  margin: 20px 0px 0px 0px;
}
.confirmation-screen .register .card .confirmation-icon {
  width: 150px;
  height: 150px;
}
.confirmation-screen .register .card .content {
  text-align: center;
}
.confirmation-screen .register .card .content .button {
  width: 300px;
  margin-bottom: 10px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvcnVubmVyL3dvcmsvYXNzZXJ0aXZhLXVpL2Fzc2VydGl2YS11aS9zcmMvY29tcG9uZW50cy9Db25maXJtYXRpb25TY3JlZW4iLCJzb3VyY2VzIjpbIkNvbmZpcm1hdGlvblNjcmVlbi5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBRUE7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTs7QUFFQTtFQUNFO0VBQ0EifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
