var _MFAService;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import Oauth2CookiesUtils from 'utils/Oauth2CookiesUtils';
import { axiosInstance, getBaseUrl } from './baseService';
class MFAService {
  static postSendSMS(token) {
    const url = token ? "".concat(getBaseUrl(), "/mfa/external/sms") : "".concat(getBaseUrl(), "/mfa/sms");
    return axios({
      url,
      headers: {
        Authorization: token ? token : Oauth2CookiesUtils.getTokenAsAuthorization()
      },
      method: 'post',
      data: {}
    });
  }
  static postVerifyCode(data) {
    const {
      code,
      token
    } = data;
    const url = token ? "".concat(getBaseUrl(), "/mfa/external/code") : "".concat(getBaseUrl(), "/mfa/code");
    return axios({
      url,
      headers: {
        Authorization: token ? token : Oauth2CookiesUtils.getTokenAsAuthorization()
      },
      method: 'post',
      data: {
        code
      }
    });
  }
}
_MFAService = MFAService;
_defineProperty(MFAService, "prepareUrl", customerId => {
  return "".concat(getBaseUrl(), "/mfa/users").concat(customerId ? "/".concat(customerId) : '');
});
_defineProperty(MFAService, "resetUserMFA", customerId => {
  return axiosInstance.put("".concat(_MFAService.prepareUrl(customerId), "/reset"));
});
export default MFAService;