function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { REQUIRED_FUNCIONALITYS_BY_PRODUCT, REQUIRED_PERMISSIONS_BY_PRODUCT } from 'constants/funcionalitys';
class FuncionalityUtils {}
_defineProperty(FuncionalityUtils, "dontAllowRemoveFuncionalityByProduct", (productId, functionalityId) => {
  if (REQUIRED_FUNCIONALITYS_BY_PRODUCT[productId]) {
    return REQUIRED_FUNCIONALITYS_BY_PRODUCT[productId].includes(functionalityId);
  }
  return false;
});
_defineProperty(FuncionalityUtils, "dontAllowRemovePermissionByProduct", (productId, permissionId) => {
  if (REQUIRED_PERMISSIONS_BY_PRODUCT[productId]) {
    return REQUIRED_PERMISSIONS_BY_PRODUCT[productId].includes(permissionId);
  }
  return false;
});
export default FuncionalityUtils;