import { styled } from '@material-ui/core';
import Box from '@material-ui/core/Box';
export const StyledUserInfo = styled(Box)(() => ({
  '& .UserInfo-avatar': {
    color: '#6f13f4',
    backgroundColor: '#ffffff',
    fontWeight: 500,
    fontSize: '1.1rem',
    letterSpacing: '0.06em'
  },
  '& .UserInfo-userName': {
    lineHeight: 1,
    marginTop: '7px'
  },
  '& .UserInfo-editUserOption': {
    paddingTop: 0,
    letterSpacing: '0.06em'
  }
}));