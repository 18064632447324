export const initialPagination = {
  page: 0,
  rowsPerPage: 5
};
export const initialFilters = {
  name: '',
  email: '',
  userStatus: '',
  mfaStatus: '',
  group: '',
  profile: ''
};
export const FILTERS_LABELS = {
  USUARIO: 'Usuário',
  STATUS: 'Status do usuário',
  GROUP: 'Grupo',
  PROFILE: 'Perfil',
  MFA_STATUS: 'Autenticação por dois fatores'
};
export const FILTERS_KEYS = {
  Nome: 'name',
  'E-mail': 'email',
  Status: 'userStatus',
  Grupo: 'group',
  Perfil: 'profile',
  'Autenticação por dois fatores': 'mfaStatus'
};