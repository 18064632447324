export default {
  email: /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/,
  phone: /^(?:(?:\+|\00)?(\55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
  cep: /^[0-9]{2}\.?[0-9]{3}-?[\d]{3}$/,
  integer: /^[0-9\s]*$/,
  cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
  cpfWithAndWithoutMask: /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{11}$)/,
  cnpj: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
  cnpjWithAndWithoutMask: /(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)|(^\d{14}$)/,
  ip: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  urlWithHttp: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  allUrls: /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gi,
  password: /^(?=(?:[^A-Z]*[A-Z]))(?=(?:[^0-9]*[0-9])).{8,}$/,
  multipleSpaces: / +(?= )/g,
  NotEmail: /[^a-zA-Z0-9-_.@]/,
  NotAlphabeticSpace: /[^a-zA-Za-Ź\s]+/g,
  NotNumericSpaceHyphen: /[^0-9\s-]+/g,
  NotAlphabeticSpaceHyphen: /[^a-zA-Za-Ź\s-]+/g,
  NotAlphanumericPointHyphen: /[^a-zA-Z0-9a-Ź\s-.]+/g,
  NotAlphanumericSpaceHyphen: /[^a-zA-Z0-9a-Ź\s-]+/g,
  NotAlphanumericSpaceHyphenAmpersand: /[^a-zA-Z0-9a-Ź\s\\&\\-]+/g,
  OnlyLetters: /^[A-Za-z-._áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*$/,
  OnlyNumbers: /^[0-9\b]+$/,
  RepeatedNumbers: /\b(\d)\1+\b/,
  NumericSequence: /^(01234567|12345678|23456789)$/,
  atLeastFirstAndLastName: /(^[a-zA-Z-Á-Ź']{2,40}\s)([a-zA-Z-Á-Ź'+$]{2,40})/,
  notNumbers: /[^0-9]/g,
  partialName: /[^a-zA-Z\u00C0-\u00FF\s]+/g
};