export function getSteps() {
  return ['Escolha o nome para o perfil e qual será a sua base', 'Defina quais produtos deseja dar acesso ao perfil', 'Escolha as funcionalidades desejadas por produto', 'Finalizando o perfil'];
}
export function getStepContent(step) {
  switch (step) {
    case 0:
      return "A partir de um perfil base, crie novos perfis e gerencie o acesso dos seus colaboradores aos produtos e funcionalidades do portal.";
    case 1:
      return 'Selecione quais produtos você quer que este perfil personalizado tenha acesso:';
    case 2:
      return "Escolha um produto de cada vez e altere o acesso \xE0s suas funcionalidades:";
    case 3:
      return "Confira todos os dados e confirme a cria\xE7\xE3o do perfil no bot\xE3o abaixo.  ";
    default:
      return '';
  }
}
export const PROFILES = {
  ADMINISTRATOR: {
    ID: 1,
    NAME: 'Administrador'
  },
  FINANCIAL: {
    ID: 2,
    NAME: 'Financeiro'
  },
  MANAGER: {
    ID: 4,
    NAME: 'Gerente'
  },
  OPERATOR: {
    ID: 3,
    NAME: 'Operador'
  }
};
export const INITIAL_PROFILE = {
  description: '',
  id: PROFILES.ADMINISTRATOR.ID,
  name: PROFILES.ADMINISTRATOR.NAME
};
export const PRODUCTS = {
  ASSERTIVA_SCORE: {
    ID: 8,
    NAME: 'Assertiva Score'
  },
  AUTENTICA: {
    ID: 10,
    NAME: 'Autentica'
  },
  BASE_CERTA: {
    ID: 9,
    NAME: 'Base Certa'
  },
  GEOTIMIZE: {
    ID: 7,
    NAME: 'Geotimize'
  },
  LOCALIZE: {
    ID: 1,
    NAME: 'Assertiva Localize'
  },
  VEICULOS: {
    ID: 12,
    NAME: 'Veículos'
  },
  SMS: {
    ID: 5,
    NAME: 'SMS'
  }
};
export const ID_PRODUCTS = [PRODUCTS.LOCALIZE.ID, PRODUCTS.SMS.ID, PRODUCTS.GEOTIMIZE.ID, PRODUCTS.ASSERTIVA_SCORE.ID, PRODUCTS.BASE_CERTA.ID, PRODUCTS.AUTENTICA.ID, PRODUCTS.VEICULOS.ID];
export const STEPS = {
  BASE_PROFILE_STEP: 0,
  PRODUCTS_STEP: 1,
  FUNCTIONALITIES_STEP: 2,
  CREATE_PROFILE_STEP: 3
};