const css = `.users-table-ui-container {
  overflow-x: auto;
}
.users-table-ui-container .users-table-ui {
  border: none !important;
}
.users-table-ui-container .email {
  white-space: nowrap;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvcnVubmVyL3dvcmsvYXNzZXJ0aXZhLXVpL2Fzc2VydGl2YS11aS9zcmMvY29udGFpbmVycy9Vc2Vyc1RhYmxlIiwic291cmNlcyI6WyJVc2Vyc1RhYmxlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7QUFFQTtFQUNFOztBQUdGO0VBQ0UifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
