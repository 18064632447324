import { useState, useEffect } from 'react';
import ProfileService from 'services/ProfileService';
import { FunctionUtils, ErrorUtils } from 'utils';
import Notifier from 'components/Notification/Notifier';
import _ from 'lodash';
import { ID_PRODUCTS, INITIAL_PROFILE } from './constants';
const initialState = {
  profileName: '',
  profileDescription: '',
  selectedProfile: INITIAL_PROFILE,
  selectedProducts: ID_PRODUCTS,
  products: []
};
const useCreateProfileForm = customerId => {
  const [profileForm, setProfileForm] = useState(initialState);
  const [baseProfiles, setBaseProfiles] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const handleProfileState = (name, value) => {
    const changedState = FunctionUtils.updateProperty(profileForm, name, value);
    setProfileForm(changedState);
  };
  const loadProducts = () => {
    ProfileService.getProducts(customerId).then(response => {
      const products = response.data;
      const changedProducts = products.map(product => {
        const {
          id,
          name
        } = product;
        return {
          id,
          name,
          functionalities: product.functionality.map(permission => {
            return {
              ...permission,
              active: true
            };
          })
        };
      });
      handleProfileState('products', changedProducts);
    }).catch(error => {
      Notifier.error(ErrorUtils.normalizeErrorMessage(error));
    });
  };
  useEffect(() => {
    loadProducts();
  }, []);
  const loadProfiles = () => {
    Promise.all(ProfileService.getBaseProfiles(customerId)).then(values => {
      const baseProfiles = [values[0].data, values[1].data, values[2].data, values[3].data];
      setBaseProfiles(_.sortBy(baseProfiles, ['id']));
    }).catch(error => {
      Notifier.error(ErrorUtils.normalizeErrorMessage(error));
    });
  };
  const handleCreateNewProfile = () => {
    const selectedProducts = profileForm.products.filter(product => profileForm.selectedProducts.includes(product.id));
    const formattedProducts = selectedProducts.map(product => {
      return {
        id: product.id,
        functionalities: product.functionalities.map(permission => {
          const {
            id,
            active
          } = permission;
          return {
            id,
            active
          };
        })
      };
    });
    const personalizedProfile = {
      name: profileForm.profileName,
      description: profileForm.profileDescription,
      baseProfileId: profileForm.selectedProfile.id,
      products: formattedProducts
    };
    return ProfileService.createNewPersonalizedProfile(personalizedProfile, customerId);
  };
  return {
    loadProfiles,
    baseProfiles,
    activeStep,
    setActiveStep,
    profileForm,
    handleProfileState,
    handleCreateNewProfile,
    loadProducts
  };
};
export default useCreateProfileForm;