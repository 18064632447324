const css = `.au-group-card-content .label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvcnVubmVyL3dvcmsvYXNzZXJ0aXZhLXVpL2Fzc2VydGl2YS11aS9zcmMvY29udGFpbmVycy9Vc2VyR3JvdXAvY29tcG9uZW50cy9Vc2VyR3JvdXBDYXJkIiwic291cmNlcyI6WyJVc2VyR3JvdXBDYXJkLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRTtFQUNBO0VBQ0EifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
