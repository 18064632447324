export default {
  document: {
    cpf: '999.999.999-99',
    cnpj: '99.999.999/9999-99'
  },
  date: {
    br_default: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  },
  cep: '99999-999',
  mobile_phone: {
    br_default: '(99) 99999-9999'
  },
  landline_phone: {
    br_default: '(99) 9999-9999'
  },
  phone_0800: {
    br_default: '9999 999 9999'
  }
};