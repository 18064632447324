export const ADMINISTRATIVO_ID = 4;
export const AUTENTICA_ID = 10;
export const ASSINATURAS_ID = 16;
export const FINANCEIRO_ID = 2;
export const GESTAO_DA_CONTA_ID = 6;
export const LOCALIZE_ID = 1;
export const RELATORIOS_ID = 3;
export const SMS_ID = 5;
export const GEOTIMIZE_ID = 7;
export const BASECERTA_ID = 9;
export const CREDITOMIX_ID = 11;
export const RECUPERE_ID = 13;
export const VEICULOS_ID = 12;
export const WEBPHONE_ID = 14;
export const CREDITO_ID = 17;
export default {
  ADMINISTRATIVO: ADMINISTRATIVO_ID,
  AUTENTICA: AUTENTICA_ID,
  ASSINATURAS: ASSINATURAS_ID,
  FINANCEIRO: FINANCEIRO_ID,
  GESTAO_DA_CONTA: GESTAO_DA_CONTA_ID,
  LOCALIZE: LOCALIZE_ID,
  RELATORIOS: RELATORIOS_ID,
  SMS: SMS_ID,
  GEOTIMIZE: GEOTIMIZE_ID,
  BASECERTA: BASECERTA_ID,
  CREDITOMIX: CREDITOMIX_ID,
  VEICULOS: VEICULOS_ID,
  RECUPERE: RECUPERE_ID,
  WEBPHONE: WEBPHONE_ID,
  CREDITO: CREDITO_ID
};