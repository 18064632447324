import { useEffect } from 'react';
import useLatest from './useLatest';
const useBlockerPrompt = function (handler) {
  let condition = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (process.env.NODE_ENV !== 'production') {
    if (typeof handler !== 'function' && handler != null) {
      throw new TypeError("Expected `handler` to be of type `function` but received type `".concat(typeof handler, "`"));
    }
  }
  const handlerRef = useLatest(handler);
  const handleBeforeunload = event => {
    let returnValue = '';
    if (handlerRef.current != null) {
      returnValue = handlerRef.current(event);
    }

    // eslint-disable-next-line no-param-reassign
    event.returnValue = returnValue;
    return returnValue;
  };
  const removeEvent = () => {
    window.removeEventListener('beforeunload', handleBeforeunload);
  };
  useEffect(() => {
    if (condition) {
      window.addEventListener('beforeunload', handleBeforeunload);
    }
    return () => removeEvent();
  }, []);
  return removeEvent;
};
export default useBlockerPrompt;