import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AULoadingManager from 'components/Loading/LoadingManager';
import AuthUtils from 'utils/AuthUtils';

// FIXME: os tipos para o route e data serão criados depois

const useRoute = _ref => {
  let {
    loadingBeforeRenderRoutes,
    route,
    handleCallback
  } = _ref;
  const location = useLocation();
  const [state, setState] = useState({
    readyToRender: false,
    isAuthenticated: false,
    isLoading: false,
    lastPath: undefined
  });
  const path = route.path || route.link;
  const handleAuthenticated = useCallback(async () => {
    const loadingActivated = () => {
      return loadingBeforeRenderRoutes && (typeof route.loadingBeforeRender === 'boolean' ? route.loadingBeforeRender : true);
    };
    if (!state.isLoading) {
      if (loadingActivated()) {
        AULoadingManager.show();
      }
      await AuthUtils.isAuthenticated({
        enableSessionExpiredMessageEnable: Boolean(AuthUtils.getToken())
      }).then(async _ref2 => {
        let {
          isAuthenticated
        } = _ref2;
        if (AuthUtils.getUser().isBlockedByMFAValidation()) {
          await AuthUtils.logout(false, true);
          setState(prev => ({
            ...prev,
            lastPath: path,
            isAuthenticated,
            readyToRender: true
          }));
          return false;
        }
        setState(prev => ({
          ...prev,
          lastPath: path,
          isAuthenticated,
          readyToRender: true
        }));
      }).finally(() => {
        if (loadingActivated()) {
          AULoadingManager.close();
        }
        setState(prev => ({
          ...prev,
          isLoading: false
        }));
      });
    }
  }, [path]);
  useEffect(() => {
    handleAuthenticated();
    return () => {
      setState({
        readyToRender: false,
        isLoading: false,
        isAuthenticated: false,
        lastPath: undefined
      });
    };
  }, []);
  useEffect(() => {
    (async () => {
      await AuthUtils.lazyRefreshToken();
    })();
  }, [location.pathname]);
  return {
    state,
    location
  };
};
export default useRoute;