const ELEMENTS_IDS = {
  FORM: 'edit-profile-form-id',
  AVATAR_USER_NAME: 'edit-profile-avatar-name-id',
  AVATAR_PROFILE_NAME: 'edit-profile-profile-name-id',
  FORM_GROUP_INPUT: 'edit-profile-group-input-id',
  FORM_EMAIL_INPUT: 'edit-profile-email-input-id',
  FORM_CPF_INPUT: 'edit-profile-cpf-input-id',
  FORM_BIRTHDATE_INPUT: 'edit-profile-birthdate-input-id',
  FORM_NAME_INPUT: 'edit-profile-name-input-id',
  FORM_PHONE_INPUT: 'edit-profile-phone-input-id',
  FORM_MOBILE_INPUT: 'edit-profile-mobile-input-id',
  FORM_SUBMIT: 'edit-profile-form-submit-id',
  FORM_CANCEL: 'edit-profile-form-cancel-id',
  NEW_SIDEBAR: 'sidebar-new',
  OLD_SIDEBAR: 'sidebar-old'
};
export default ELEMENTS_IDS;