export const FEATURE_FLAG_CONFIG_DEV = {
  url: 'https://parquinho-k8s-dev.assertivasolucoes.com.br/unleash-proxy/proxy',
  clientKey: 'f888e9ab-ca9e-48d9-bfb5-e92bbac19d09',
  refreshInterval: 60,
  appName: 'fcc-flagging',
  environment: 'dev'
};
export const FEATURE_FLAG_CONFIG_PROD = {
  url: 'https://portalv3-k8s-prd.assertivasolucoes.com.br/unleash-proxy/proxy',
  clientKey: '07a572d9-db8b-460c-aa7c-2902bfee2221',
  refreshInterval: 60,
  appName: 'fcc-flagging',
  environment: 'prod'
};
export const GET_FEATURE_FLAG_CONFIG = () => {
  const {
    REACT_APP_APP_ENV
  } = process.env;
  if (REACT_APP_APP_ENV === 'production') {
    return {
      ...FEATURE_FLAG_CONFIG_PROD
    };
  }
  return {
    ...FEATURE_FLAG_CONFIG_DEV
  };
};