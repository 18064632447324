var _RedirectUtils;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import qs from 'qs';
import { Envs } from 'constants/envs';
import browserHistory from './browserHistory';
class RedirectUtils {
  static prepareRedirectUrl(host, redirectURL) {
    return RedirectUtils.createRedirectURL(host, redirectURL);
  }
}
_RedirectUtils = RedirectUtils;
_defineProperty(RedirectUtils, "isSafeUrl", function () {
  let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  const ASSERTIVA_SUBDOMAIN_REGEX = /https?:\/\/?(?:([^*]+)\.)?assertivasolucoes.com.br/;
  const LOCALHOST_REGEX = /^https?:\/\/(localhost|127\.0\.0\.1)(:[0-9]+)?\/?(\/[.\w+\\/]*)?$/;
  return ASSERTIVA_SUBDOMAIN_REGEX.test(url) || LOCALHOST_REGEX.test(url);
});
_defineProperty(RedirectUtils, "redirectByUrlOrigin", _ref => {
  let {
    search,
    defaultRedirect
  } = _ref;
  const queryString = qs.parse(search.slice(1));
  if (queryString.redirectURL && _RedirectUtils.isSafeUrl(queryString.redirectURL) && !_RedirectUtils.isRedirectURLEqualsOrigin(queryString.redirectURL)) {
    window.location.href = queryString.redirectURL;
    return;
  }
  if (defaultRedirect) browserHistory.push(defaultRedirect);
});
_defineProperty(RedirectUtils, "isRedirectURLEqualsOrigin", redirectURL => {
  if (!redirectURL) return false;
  return redirectURL.includes(window.location.origin);
});
_defineProperty(RedirectUtils, "createRedirectURL", function (destination) {
  let redirectURL = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "".concat(window.location.origin).concat(window.location.pathname);
  return "".concat(destination, "?redirectURL=").concat(redirectURL);
});
_defineProperty(RedirectUtils, "redirectWithRedirectURL", function () {
  let destination = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "".concat(Envs().PAINEL_URL, "/login");
  window.location.href = _RedirectUtils.createRedirectURL(destination);
});
export default RedirectUtils;