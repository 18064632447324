import { styled } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
export const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  justifyItems: 'center',
  height: '100%'
});
export const StyledMenuContainer = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  /* width */
  '&::-webkit-scrollbar': {
    width: '5px'
  },
  /* Track */
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1'
  },
  /* Handle */
  '&::-webkit-scrollbar-thumb': {
    background: '#888'
  },
  /* Handle on hover */
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555'
  }
});
export const StyledLougoutButton = styled(Button)(_ref => {
  let {
    theme
  } = _ref;
  return {
    color: theme.palette.grey[900],
    width: '100%',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 400,
    textTransform: 'unset'
  };
});