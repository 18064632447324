function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import moment from 'moment';
import PubSub from 'pubsub-js';
import AULoadingManager from 'components/Loading/LoadingManager';
import AUNotifier from 'components/Notification/Notifier';
import { VIEW_REPORT_PRODUCTS } from 'constants/permissions';
import ReportService from 'services/ReportService';
import AuthUtils from 'utils/AuthUtils';
import ErrorUtils from 'utils/ErrorUtils';
import ProductUtils from 'utils/ProductUtils';
const NOTIFY_UPDATE_FILTER_CONSOLIDATED_CONSUMPTION = 'NOTIFY_UPDATE_FILTER_CONSOLIDATED_CONSUMPTION';
const getCurrentDate = () => {
  return moment().set({
    hour: 0,
    minutes: 0,
    second: 0,
    millisecond: 0
  });
};
class ReportFilterStore {
  constructor() {
    var _this = this;
    _defineProperty(this, "subscription", null);
    _defineProperty(this, "initialState", Object.freeze({
      isValidDate: true,
      date: getCurrentDate(),
      endDate: null,
      startDate: null,
      selectedCustomer: null,
      selectedProduct: null,
      selectedGroup: null,
      products: [],
      customers: [],
      hasPermissionToViewProducts: AuthUtils.getUser().hasPermission(VIEW_REPORT_PRODUCTS),
      isBackofficeInterno: false,
      hideCustomer: false,
      hideProduct: false,
      requiredCustomer: false
    }));
    _defineProperty(this, "state", {
      ...this.initialState
    });
    _defineProperty(this, "notify", () => {
      PubSub.publish(NOTIFY_UPDATE_FILTER_CONSOLIDATED_CONSUMPTION, {
        ...this.state
      });
    });
    _defineProperty(this, "subscribe", callback => {
      this.subscription = PubSub.subscribe(NOTIFY_UPDATE_FILTER_CONSOLIDATED_CONSUMPTION, (_, state) => {
        callback(state);
      });
      this.notify();
    });
    _defineProperty(this, "unsubscribe", () => {
      if (this.subscription) {
        PubSub.unsubscribe(this.subscription);
      }
    });
    _defineProperty(this, "init", _ref => {
      let {
        fields,
        hideCustomer,
        hideProduct,
        requiredCustomer
      } = _ref;
      this.state = {
        ...this.state,
        ...fields,
        hideCustomer,
        hideProduct,
        requiredCustomer,
        isBackofficeInterno: ProductUtils.isBackofficeInternoEnv()
      };
      if (!hideCustomer && !this.state.isBackofficeInterno) {
        this.state.selectedCustomer = {
          ...AuthUtils.getUser().getCompany()
        };
        if (!this.state.selectedCustomer || this.state.selectedCustomer && !this.state.selectedCustomer.id) {
          console.error('[DateCustomerProductFilter] Company inside current user not found.');
        }
      }
      const {
        customerId,
        date
      } = this.toFilterParams();
      this.handleProduct({
        customerId,
        date
      });
      this.notify();
    });
    _defineProperty(this, "reset", () => {
      this.state = {
        ...this.initialState
      };
      this.notify();
    });
    _defineProperty(this, "handleState", (name, value) => {
      const newState = {
        ...this.state,
        [name]: value
      };
      this.state = newState;
      if (['date', 'selectedCustomer'].includes(name)) {
        const params = this.toFilterParams();
        const {
          customerId,
          date
        } = params;
        this.state.selectedProduct = null;
        this.handleProduct({
          customerId,
          date
        });
      }
      this.notify();
    });
    _defineProperty(this, "handleProduct", _ref2 => {
      let {
        customerId,
        date
      } = _ref2;
      if (this.state.hideProduct || !this.state.hasPermissionToViewProducts) return;
      if (!customerId || !date) {
        console.error('[ReportFilter] error on params on handleProduct');
        return;
      }
      AULoadingManager.show();
      ReportService.getProducts({
        date,
        customerId
      }).then(response => {
        this.state.products = response.data;
      }).catch(error => {
        this.state.products = [];
        AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
      }).finally(() => {
        AULoadingManager.close();
        this.notify();
      });
    });
    _defineProperty(this, "toFilterParams", function () {
      let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        ..._this.state
      };
      const product = state.selectedProduct || {};
      const customer = state.selectedCustomer || {};
      const group = state.selectedGroup || {};
      return {
        date: state.isValidDate && state.date ? state.date.format('YYYY-MM-DD') : '',
        endDate: state.endDate ? state.endDate.format('YYYY-MM-DD') : '',
        startDate: state.startDate ? state.startDate.format('YYYY-MM-DD') : '',
        productId: product.id || '',
        customerId: customer.id || '',
        groupId: group.id || ''
      };
    });
  }
}
export default new ReportFilterStore();