/* eslint-disable no-new */
import { DESKTOP_NOTIFICATION_VARIANTS } from 'constants/desktopNotificationsConstants';
import AudioUtils from 'utils/AudioUtils';
import { sendAnalytics } from './analytics';
const permissionIsGranted = () => Notification.permission === 'granted';
const requestPermission = () => Notification.requestPermission();
const shouldAskForPermission = () => {
  if (!('Notification' in window)) return false;
  return !['granted', 'denied'].includes(Notification.permission);
};
const createNotification = config => {
  const notification = new Notification(config.title, config === null || config === void 0 ? void 0 : config.options);
  if (notification && config.onClick) {
    notification.addEventListener('click', config.onClick);
  }
  if (notification && config.analytics) {
    sendAnalytics(config.analytics);
  }
};
const notify = (notification, customNotificationAudioSrc) => {
  if (!('Notification' in window)) {
    // Check if the browser supports notifications
    console.warn('This browser does not support desktop notification');
    return;
  }
  try {
    if (customNotificationAudioSrc) {
      AudioUtils.play(customNotificationAudioSrc);
    } else if (notification !== null && notification !== void 0 && notification.sound) {
      AudioUtils.play(notification === null || notification === void 0 ? void 0 : notification.sound);
    }
  } catch (error) {
    console.error(error);
  }
  try {
    if (permissionIsGranted()) {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      createNotification(notification);
    } else if (Notification.permission !== 'denied') {
      // We need to ask the user for permission
      requestPermission().then(permission => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          createNotification(notification);
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
};
const notifySessionEnd = () => {
  notify(DESKTOP_NOTIFICATION_VARIANTS.NOTIFY_SESSION_END);
};
const DesktopNotificationsUtils = {
  notify,
  notifySessionEnd,
  requestPermission,
  permissionIsGranted,
  shouldAskForPermission
};
export default DesktopNotificationsUtils;