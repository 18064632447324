function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import PubSub from 'pubsub-js';
import UserModel from 'models/UserModel';
import UserService from 'services/UserService';
import GroupService from 'services/GroupService';
import ProfileService from 'services/ProfileService';
import FunctionUtils from 'utils/FunctionUtils';
import ArrayUtils from 'utils/ArrayUtils';
import ErrorUtils from 'utils/ErrorUtils';
import Notifier from 'components/Notification/Notifier';
import LoadingManager from 'components/Loading/LoadingManager';
import AuthUtils from 'utils/AuthUtils';
import { CREATE_USER_COMPANY_LEVEL, CREATE_USER_GROUP_LEVEL, CREATE_USERS } from 'constants/permissions';
import CONSTANTS from '../constants';
import { initialPagination } from './constants';
const {
  USER_STATUS
} = CONSTANTS;
const USER_TABLE_NOTIFY = 'NOTIFY_USER_TABLE';
class UserTableStore {
  constructor() {
    var _this = this;
    _defineProperty(this, "subscription", null);
    _defineProperty(this, "initialState", {
      // FIXME: o back precisa devolver os status em um endpoint
      status: [USER_STATUS.ACTIVE, USER_STATUS.INACTIVE, USER_STATUS.PENDING],
      groups: [],
      profiles: [],
      users: [],
      invokedUsers: false,
      selectedProfile: {},
      selectedUser: {
        name: ''
      },
      openAddNewUserDialog: false,
      openEditUserDialog: false,
      openProfileDialog: false,
      openDeleteUserDialog: false,
      openDisableEnableUserDialog: false,
      openMFAQuestionDialog: false,
      openResetMFADialog: false,
      mfa: {
        status: '',
        question: '',
        answer: ''
      },
      pagination: initialPagination,
      hasCreateUserPermission: () => AuthUtils.getUser().hasPermission([CREATE_USER_COMPANY_LEVEL, CREATE_USER_GROUP_LEVEL, CREATE_USERS])
    });
    _defineProperty(this, "state", {
      ...this.initialState
    });
    _defineProperty(this, "customerId", 0);
    _defineProperty(this, "groupId", 0);
    _defineProperty(this, "init", _ref => {
      let {
        customerId,
        groupId,
        viewMode,
        users,
        groups,
        profiles,
        loadUsers,
        loadGroups,
        loadProfiles,
        pagination,
        checkCustomerIdPresence
      } = _ref;
      this.customerId = customerId;
      this.groupId = Number(groupId);
      if (pagination) {
        this.state.pagination = pagination;
      }
      if (checkCustomerIdPresence && !customerId) {
        console.error('[UsersTable] customerId não foi informado.');
        return;
      }
      if (loadUsers) {
        LoadingManager.show();
        UserService.getUsers({
          customerId
        }).then(response => {
          this.state.users = response.data.map(user => new UserModel(user));
        }).catch(error => {
          Notifier.error(ErrorUtils.normalizeErrorMessage(error));
        }).finally(() => {
          this.state.invokedUsers = true;
          LoadingManager.close();
          this.notify();
        });
      } else if (users) {
        this.state.users = users;
        this.state.invokedUsers = true;
        this.notify();
      } else {
        console.error('[UsersTable] users é obrigatório quando loadUsers é false.');
      }
      if (!viewMode) {
        if (loadGroups) {
          LoadingManager.show();
          GroupService.getGroups({
            customerId
          }).then(response => {
            this.state.groups = response.data.map(group => ({
              id: group.id,
              name: group.name
            }));
          }).catch(error => {
            Notifier.error(ErrorUtils.normalizeErrorMessage(error));
          }).finally(() => {
            LoadingManager.close();
            this.notify();
          });
        } else if (groups) {
          this.state.groups = groups;
          this.notify();
        } else {
          console.error('[UsersTable] groups é obrigatório quando loadGroups é false.');
        }
        if (loadProfiles) {
          LoadingManager.show();
          ProfileService.getCustomerProfiles({
            customerId
          }).then(response => {
            this.state.profiles = response.data.map(profile => ({
              id: profile.id,
              name: profile.name,
              description: profile.description
            }));
          }).catch(error => {
            Notifier.error(ErrorUtils.normalizeErrorMessage(error));
          }).finally(() => {
            LoadingManager.close();
            this.notify();
          });
        } else if (profiles) {
          this.state.profiles = profiles;
          this.notify();
        } else {
          console.error('[UsersTable] profiles é obrigatório quando loadProfiles é false.');
        }
      }
    });
    _defineProperty(this, "notify", () => {
      PubSub.publish(USER_TABLE_NOTIFY, {
        ...this.state
      });
    });
    _defineProperty(this, "reset", () => {
      this.state = {
        ...this.initialState
      };
      this.notify();
    });
    _defineProperty(this, "filterUsersByGroupIfHasGroupId", function () {
      let users = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return users.filter(user => {
        var _user$group;
        return _this.groupId && user !== null && user !== void 0 && (_user$group = user.group) !== null && _user$group !== void 0 && _user$group.id ? user.group.id === _this.groupId : true;
      });
    });
    _defineProperty(this, "getOrderedUsers", () => {
      const users = this.filterUsersByGroupIfHasGroupId(this.state.users);
      const total = this.state.users.length;
      const newPagination = {
        ...this.state.pagination
      };
      return {
        users: {
          paginated: FunctionUtils.sliceArrayPerPage(users, newPagination.page, newPagination.rowsPerPage),
          all: users,
          total
        }
      };
    });
    _defineProperty(this, "subscribe", callback => {
      this.subscription = PubSub.subscribe(USER_TABLE_NOTIFY, (_, state) => {
        callback(state);
      });
      this.notify();
    });
    _defineProperty(this, "unsubscribe", () => {
      if (this.subscription) {
        PubSub.unsubscribe(this.subscription);
      }
    });
    _defineProperty(this, "toggleDisableEnableUserDialog", user => {
      const open = !this.state.openDisableEnableUserDialog;
      this.state.openDisableEnableUserDialog = open;
      this.state.selectedUser = open ? user : {};
      this.notify();
    });
    _defineProperty(this, "toggleDeleteUserDialog", user => {
      const open = !this.state.openDeleteUserDialog;
      this.state.openDeleteUserDialog = open;
      this.state.selectedUser = open ? user : {};
      this.notify();
    });
    _defineProperty(this, "toggleEditUserDialog", user => {
      const open = !this.state.openEditUserDialog;
      this.state.openEditUserDialog = open;
      this.state.selectedUser = open ? user : {};
      this.notify();
    });
    _defineProperty(this, "toggleProfileDialog", user => {
      const open = !this.state.openProfileDialog;
      this.state.openProfileDialog = open;
      this.state.selectedUser = open ? user : {};
      this.notify();
    });
    _defineProperty(this, "toggleAddNewUserDialog", () => {
      this.state.openAddNewUserDialog = !this.state.openAddNewUserDialog;
      this.notify();
    });
    _defineProperty(this, "toggleMFAQuestionDialog", user => {
      var _user$mfa, _user$mfa2, _user$mfa3;
      this.state.mfa = {
        status: (user === null || user === void 0 || (_user$mfa = user.mfa) === null || _user$mfa === void 0 ? void 0 : _user$mfa.status) || '',
        question: (user === null || user === void 0 || (_user$mfa2 = user.mfa) === null || _user$mfa2 === void 0 ? void 0 : _user$mfa2.question) || '',
        answer: (user === null || user === void 0 || (_user$mfa3 = user.mfa) === null || _user$mfa3 === void 0 ? void 0 : _user$mfa3.answer) || ''
      };
      this.state.openMFAQuestionDialog = !this.state.openMFAQuestionDialog;
      this.notify();
    });
    _defineProperty(this, "toggleResetMFADialog", user => {
      const open = !this.state.openResetMFADialog;
      this.state.openResetMFADialog = open;
      this.state.selectedUser = open ? user : {};
      this.notify();
    });
    _defineProperty(this, "saveUser", user => {
      if (user.id) {
        ArrayUtils.replaceByParams(this.state.users, {
          id: user.id
        }, user);
      }
      this.state.openAddNewUserDialog = false;
      this.notify();
    });
    _defineProperty(this, "reloadUsers", customerId => {
      LoadingManager.show();
      UserService.getUsers({
        customerId
      }).then(response => {
        this.state.users = response.data.map(user => new UserModel(user));
      }).catch(error => {
        Notifier.error(ErrorUtils.normalizeErrorMessage(error));
      }).finally(() => {
        this.state.invokedUsers = true;
        LoadingManager.close();
        this.notify();
      });
    });
    _defineProperty(this, "resendLinkToNewUser", user => {
      if (user && user.email) {
        LoadingManager.show();
        UserService.resendLinkToNewUsers({
          customerId: this.customerId,
          email: user.email
        }).then(response => {
          Notifier.success(response.data.message);
        }).catch(error => {
          Notifier.error(ErrorUtils.normalizeErrorMessage(error));
        }).finally(() => {
          LoadingManager.close();
        });
      } else {
        console.error('[UsersTable] usuário inválido ou não possui e-mail.');
      }
    });
    _defineProperty(this, "handlePagination", (attr, value) => {
      this.state.pagination = {
        ...this.state.pagination,
        [attr]: value
      };
      this.notify();
    });
    _defineProperty(this, "handleDisableEnableUserDialog", () => {
      const user = this.state.selectedUser;
      const newStatus = user.status.id === USER_STATUS.ACTIVE_ID ? USER_STATUS.INACTIVE_ID : USER_STATUS.ACTIVE_ID;
      user.status = this.state.status.find(s => s.id === newStatus);
      LoadingManager.show();
      UserService.updateUser({
        customerId: this.customerId,
        user
      }).then(response => {
        Notifier.success(response.data.message);
        this.saveUser(user);
        this.toggleDisableEnableUserDialog();
      }).catch(error => {
        Notifier.error(ErrorUtils.normalizeErrorMessage(error));
      }).finally(() => {
        LoadingManager.close();
      });
    });
    _defineProperty(this, "resetSelectedProfile", () => {
      this.state = {
        ...this.state,
        selectedProfile: {}
      };
      this.notify();
    });
    _defineProperty(this, "handleSelectProfile", profileId => {
      const {
        profiles
      } = this.state;
      const selectedProfile = profiles.find(profile => profile.id === profileId);
      this.state = {
        ...this.state,
        selectedProfile
      };
      this.notify();
    });
    _defineProperty(this, "removeUserOfState", user => {
      const {
        users
      } = this.state;
      this.state.users = users.filter(u => u.id !== user.id);
      this.state.selectedUser = {};
      this.state.openDeleteUserDialog = false;
      this.notify();
    });
    _defineProperty(this, "submitRemoveUser", () => {
      const user = this.state.selectedUser;
      LoadingManager.show();
      UserService.deleteUser({
        customerId: this.customerId,
        userId: user.id
      }).then(response => {
        this.removeUserOfState(user);
        Notifier.success(response.data.message);
      }).catch(error => {
        Notifier.error(ErrorUtils.normalizeErrorMessage(error));
      }).finally(() => LoadingManager.close());
    });
  }
  getUsers(customerId) {
    LoadingManager.show();
    GroupService.getGroups({
      customerId
    }).then(response => {
      this.state.groups = response.data.map(group => ({
        id: group.id,
        name: group.name
      }));
    }).catch(error => {
      Notifier.error(ErrorUtils.normalizeErrorMessage(error));
    }).finally(() => {
      LoadingManager.close();
      this.notify();
    });
  }
}
export default new UserTableStore();