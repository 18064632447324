import _get from 'lodash/get';
import ValidationUtils from 'utils/ValidationUtils';
import FunctionUtils from 'utils/FunctionUtils';
export default {
  isValidByRules: (object, rules) => {
    let hasErros = false;
    const messages = {};
    if (!object || !rules) throw new Error('object and roles is required');
    Object.keys(rules).forEach(option => {
      const value = _get(object, option);
      if (rules[option].required && (FunctionUtils.isEmpty(value) || FunctionUtils.getTypeOf(value) === 'string' && value.trim().length === 0)) {
        messages[option] = rules[option].required;
        hasErros = true;
      }
      [{
        isEmail: ValidationUtils.isValidEmail
      }, {
        isDocument: ValidationUtils.isValidDocument
      }, {
        isCpf: ValidationUtils.isValidCpf
      }, {
        isCnpj: ValidationUtils.isValidCnpj
      }, {
        isPhone: ValidationUtils.isValidPhone
      }, {
        isMobilePhone: phone => ValidationUtils.isValidPhone(phone, 'mobile')
      }, {
        isLandlinePhone: phone => ValidationUtils.isValidPhone(phone, 'landline')
      }, {
        isZipCode: ValidationUtils.isValidZipCode
      }, {
        isPassword: ValidationUtils.isValidPassword
      }].forEach(validation => {
        const name = Object.keys(validation).pop();
        const isValid = name && validation[name];
        if (name && rules[option][name] && value) {
          if (!isValid(value)) {
            messages[option] = rules[option][name];
            hasErros = true;
          }
        }
      });
      if (option && rules[option].validateIf) {
        const {
          isValid,
          message
        } = rules[option].validateIf();
        if (!isValid) {
          messages[option] = message;
          hasErros = true;
        }
      }
    });
    return {
      hasErros,
      messages
    };
  },
  validateIf: function () {
    let isValidFunc = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : () => false;
    let message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return {
      isValid: isValidFunc(),
      message
    };
  }
};