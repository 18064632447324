import { applyDefaultEnvsWhenFound, applySpecificEnvsWhenFound } from './applyEnvsWhenFound';
import env from './env';
const test = () => {
  const {
    API_GATEWAY_URL,
    NOTIFIER_API_URL
  } = applyDefaultEnvsWhenFound({
    API_GATEWAY_URL: 'https://parquinho-k8s-tst.assertivasolucoes.com.br',
    NOTIFIER_API_URL: 'https://notifier-tst.assertivasolucoes.com.br'
  });
  return applySpecificEnvsWhenFound({
    ...env(),
    // api
    API_URL: 'https://api-tst.assertivasolucoes.com.br',
    API_GATEWAY_URL,
    CADASTRO_API_URL: "".concat(API_GATEWAY_URL, "/cadastro-backoffice"),
    CAMPAIGNS_API_URL: "".concat(API_GATEWAY_URL, "/cadastro-backoffice/campaigns"),
    CUSTOMER_API_URL: "".concat(API_GATEWAY_URL, "/backoffice-cliente"),
    COMUNIKA_API_URL: "".concat(API_GATEWAY_URL, "/tst-"),
    RECUPERE_API_URL: "".concat(API_GATEWAY_URL, "/tst-"),
    NOTIFIER_API_URL,
    NOTIFIER_CUSTOMER_API_URL: "".concat(NOTIFIER_API_URL, "/backoffice-cliente"),
    NOTIFIER_CADASTRO_API_URL: "".concat(NOTIFIER_API_URL, "/cadastro-backoffice"),
    REPORT_API_URL: 'https://ws-tst.assertivasolucoes.com.br',
    // apps
    AUTENTICA_URL: 'https://autentica-release.assertivasolucoes.com.br',
    ASSINATURAS_URL: 'https://assinaturas-release.assertivasolucoes.com.br',
    PAINEL_URL: 'https://painel-release.assertivasolucoes.com.br',
    LOCALIZE_URL: 'https://localize-release.assertivasolucoes.com.br',
    CADASTRO_URL: 'https://painel-release.assertivasolucoes.com.br/grupos-e-usuarios/grupos',
    SMS_URL: 'https://sms-release.assertivasolucoes.com.br',
    GEOTIMIZE_URL: 'https://geotimize-release.assertivasolucoes.com.br',
    BASECERTA_URL: 'https://basecerta-release.assertivasolucoes.com.br',
    CREDITOMIX_URL: 'https://creditomix-release.assertivasolucoes.com.br',
    VEICULOS_URL: 'https://veiculos-release.assertivasolucoes.com.br',
    RECUPERE_URL: 'https://recupere-release.assertivasolucoes.com.br',
    WEBPHONE_URL: 'https://webphone-release.assertivasolucoes.com.br',
    CREDITO_URL: 'https://credito-release.assertivasolucoes.com.br'
  });
};
export default test;