import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';
import LoadingManager from 'components/Loading/LoadingManager';
import messages from 'constants/messages';
import PATHS from 'constants/paths';
import { AuthEvents } from 'types/events';
import { UnitTime } from 'types/session';
import { NotificationCookiesUtils, RedirectUtils } from 'utils';
import AuthUtils from 'utils/AuthUtils';
import Oauth2CookiesUtils from 'utils/Oauth2CookiesUtils';
import { convertTimeToMinutes } from 'utils/SessionUtils';
import AnalyticsUtils from 'utils/analytics.v2';
import useAnimationInterval from './useAnimationInterval';
const useSessionCountdown = onLogout => {
  let animation;
  const user = AuthUtils.getUser();
  const [showCountdownDialog, setShowCountdownDialog] = useState(false);
  const redirectURL = useMemo(() => RedirectUtils.prepareRedirectUrl(''), [window.location.origin]);
  const onFinish = useCallback(() => {
    if (!isLastSession) {
      AnalyticsUtils.sendAnalytics({
        eventCategory: '[LOGIN][INACTIVITY]',
        eventAction: '[LOGOUT]',
        eventLabel: 'Expirou por inatividade'
      });
    }
    NotificationCookiesUtils.setFinisedSessionNotificationCookie(messages.TIME_LIMIT);
    if (onLogout) onLogout(PATHS.ROOT_PATH);
  }, []);
  const handleFinish = useCallback(() => {
    if (animation) {
      clearInterval(animation);
    }
    setShowCountdownDialog(prevState => {
      if (prevState) {
        return false;
      }
      return prevState;
    });
    onFinish();
  }, []);
  const handleNotify = () => {
    setShowCountdownDialog(prevState => {
      if (!prevState) {
        return true;
      }
      return prevState;
    });
  };
  const handleRenewSession = () => {
    LoadingManager.show();
    AuthUtils.refreshToken({
      reloadUserInfo: false
    }).then(() => {
      setShowCountdownDialog(false);
      RedirectUtils.redirectByUrlOrigin({
        search: redirectURL
      });
    }).catch(error => {
      var _error$response;
      setShowCountdownDialog(false);
      LoadingManager.close();
      if ((error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 401) {
        onFinish();
      }
    }).finally(() => {
      setShowCountdownDialog(false);
      LoadingManager.close();
    });
  };
  const isLastSession = useMemo(() => {
    return _isEqual(moment(Oauth2CookiesUtils.getAccessTokenMaxAgeCookie()).format('HH:mm'), moment(user.getFinalAccessTime()).format('HH:mm'));
  }, [Oauth2CookiesUtils.getAccessTokenMaxAgeCookie, user.getFinalAccessTime]);
  const {
    progress,
    resetAnimation,
    runAnimation,
    timeLeft
  } = useAnimationInterval(handleFinish, handleNotify);
  const handleResetAnimation = () => {
    resetAnimation();
    if (animation) {
      clearInterval(animation);
    }
    setShowCountdownDialog(false);
  };
  const roundedTimeLeft = useMemo(() => {
    return Math.ceil(convertTimeToMinutes(UnitTime.SECONDS, timeLeft));
  }, [timeLeft]);
  useLayoutEffect(() => {
    const sub = AuthUtils.subscribeEvents(event => {
      if ([AuthEvents.TokenRefreshed, AuthEvents.PostLogin].includes(event)) {
        handleResetAnimation();
        animation = runAnimation();
      }
    });
    animation = runAnimation();
    return () => {
      if (animation) {
        clearInterval(animation);
      }
      AuthUtils.unsubscribeEvents(sub);
    };
  }, []);
  return {
    handleRenewSession,
    isLastSession,
    progress,
    roundedTimeLeft,
    showCountdownDialog,
    timeLeft
  };
};
export default useSessionCountdown;