/* eslint-disable react-hooks/exhaustive-deps */

import { PAINEL_UI_SESSAO_SALDO } from 'constants/featureFlags';
import CustomerPlanUtils from 'utils/CustomerPlanUtils';
import useFeatureFlag from './useFeatureFlag';
export default function useSaldoExtratoFF() {
  const {
    updateContext,
    flagsError,
    flagsReady,
    getFlag
  } = useFeatureFlag();
  const FEATURE_FLAG_SESSAO_SALDO = CustomerPlanUtils.isPunctual() || getFlag(PAINEL_UI_SESSAO_SALDO) && flagsReady;
  return {
    flagsError,
    flagsReady,
    FEATURE_FLAG_SESSAO_SALDO,
    updateContext
  };
}