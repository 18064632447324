import { styled } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge.MuiBadge-anchorOriginTopLeftRectangle': {
    height: '14px',
    width: '14px'
  },
  '& .MuiBadge-badge': {
    minWidth: 'inherit',
    borderRadius: '7px'
  },
  '& .MuiBadge-badge.MuiBadge-colorError': {
    backgroundColor: '#EB5757'
  }
});
export const StyledText = styled('p')(() => ({
  fontSize: '10px'
}));