var _ValidationUtils;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import FunctionUtils from 'utils/FunctionUtils';
import { DDD } from '../constants/DDD';
import regex from '../constants/regex';
const REJECTED_CPF = FunctionUtils.generateRejectedDocuments('CPF');
const REJECTED_CNPJ = FunctionUtils.generateRejectedDocuments('CNPJ');
class ValidationUtils {
  static stripString(string) {
    return (string || '').toString().replace(/\D/g, '');
  }

  /**
   *
   * @param phone string
   * @param type string | 'mobile' | 'landline' | '0800' | 'all'
   * @returns boolean
   */

  static verifierDigitCpf(numbers) {
    const split = numbers.split('');
    const size = split.length;
    let sumTotal = 0;
    for (let i = size, j = 0; i > 0; i--, j++) {
      sumTotal += Number(split[j]) * (i + 1);
    }
    const mod = sumTotal * 10 % 11;
    return mod === 10 ? 0 : mod;
  }
  static verifierDigitCnpj(numbers) {
    const reverse = numbers.split('').reverse();
    const size = reverse.length;
    let sumTotal = 0;
    let multiplierIndex = 2;
    for (let i = 0; i < size; i++) {
      sumTotal += Number(reverse[i]) * multiplierIndex;
      multiplierIndex = multiplierIndex >= 9 ? 2 : multiplierIndex + 1;
    }
    const mod = sumTotal * 10 % 11;
    return mod === 10 ? 0 : mod;
  }

  /**
   * @param document CNPJ
   * @param strict quando true valida o CNPJ considerando a máscara e/ou apenas os 14 dígitos.
   * @returns boolean
   */

  static generateCpf() {
    let cpf = '';
    for (let i = 0; i < 9; i += 1) {
      cpf += Math.floor(Math.random() * 10);
    }
    cpf += ValidationUtils.verifierDigitCpf(cpf);
    cpf += ValidationUtils.verifierDigitCpf(cpf);
    return cpf;
  }
  static generateCnpj() {
    let cnpj = '';
    for (let i = 0; i < 12; i += 1) {
      cnpj += Math.floor(Math.random() * 10);
    }
    cnpj += ValidationUtils.verifierDigitCnpj(cnpj);
    cnpj += ValidationUtils.verifierDigitCnpj(cnpj);
    return cnpj;
  }
}
_ValidationUtils = ValidationUtils;
_defineProperty(ValidationUtils, "isValidPhone", (phone, type) => {
  // retira todos os caracteres menos os números
  const telefone = _ValidationUtils.stripString(phone);

  // verifica se é um número 0800 válido
  if (type && ['0800', 'all'].includes(type) && telefone.length === 11 && telefone.startsWith('0800')) return true;

  // verifica se tem a qtde de numero correto
  if (type && ['mobile', 'landline'].includes(type)) {
    if (type === 'mobile' && telefone.length !== 11) {
      return false;
    }
    if (type === 'landline' && telefone.length !== 10) {
      return false;
    }
  } else if (!(telefone.length >= 10 && telefone.length <= 11)) {
    return false;
  }

  // Se tiver 11 caracteres, verificar se começa com 9 o celular
  if (telefone.length === 11 && parseInt(telefone.substring(2, 3), 10) !== 9) return false;

  // verifica se não é nenhum numero digitado errado (propositalmente)
  for (let n = 0; n < 10; n += 1) {
    const nString = n.toString();
    // um for de 0 a 9.
    // estou utilizando o metodo Array(q+1).join(n) onde "q" é a quantidade e n é o
    // caractere a ser repetido
    if (telefone === new Array(11).join(nString) || telefone === new Array(12).join(nString)) return false;
  }

  // verifica se o DDD é valido (sim, da pra verificar rsrsrs)
  if (DDD.indexOf(parseInt(telefone.substring(0, 2), 10)) === -1) return false;

  // E por ultimo verificar se o numero é realmente válido. Até 2016 um celular pode
  // ter 8 caracteres, após isso somente numeros de telefone e radios (ex. Nextel)
  // vão poder ter numeros de 8 digitos (fora o DDD), então esta função ficará inativa
  // até o fim de 2016, e se a ANATEL realmente cumprir o combinado, os numeros serão
  // validados corretamente após esse período.
  // NÃO ADICIONEI A VALIDAÇÂO DE QUAIS ESTADOS TEM NONO DIGITO, PQ DEPOIS DE 2016 ISSO NÃO FARÁ DIFERENÇA
  // Não se preocupe, o código irá ativar e desativar esta opção automaticamente.
  // Caso queira, em 2017, é só tirar o if.
  if (new Date().getFullYear() < 2017) return true;
  if (telefone.length === 10 && [2, 3, 4, 5, 7].indexOf(parseInt(telefone.substring(2, 3), 10)) === -1) return false;

  // se passar por todas as validações acima, então está tudo certo
  return true;
});
_defineProperty(ValidationUtils, "isValidEmail", email => {
  return regex.email.test(email);
});
/** @deprecated isValidCep. Usar a função isValidZipCode */
_defineProperty(ValidationUtils, "isValidCep", function () {
  let number = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  console.warn('[ValidationUtils] isValidCep method is deprecated, please use isValidZipCode');
  return regex.cep.test(number);
});
_defineProperty(ValidationUtils, "isValidZipCode", function () {
  let number = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return regex.cep.test(number);
});
_defineProperty(ValidationUtils, "isLengthGte", (string, valueLength) => {
  return string.length >= valueLength;
});
_defineProperty(ValidationUtils, "isValidCnpj", function (document) {
  let strict = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const striped = _ValidationUtils.stripString(document);
  function isPartialValid() {
    if (!striped || REJECTED_CNPJ.includes(striped)) {
      return false;
    }
    if (strict) {
      return regex.cnpjWithAndWithoutMask.test(document);
    }
    if (striped.length === 14) {
      return true;
    }
    return false;
  }
  if (!isPartialValid()) return false;
  let verifyDocument = striped.slice(0, 12);
  verifyDocument += _ValidationUtils.verifierDigitCnpj(verifyDocument);
  verifyDocument += _ValidationUtils.verifierDigitCnpj(verifyDocument);
  return verifyDocument.slice(12, 14) === striped.slice(12, 14);
});
/**
 *
 * @param document CPF
 * @param strict quando true valida o CPF considerando a máscara e/ou apenas os 14 dígitos.
 * @returns boolean
 */
_defineProperty(ValidationUtils, "isValidCpf", function (document) {
  let strict = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const striped = _ValidationUtils.stripString(document);
  function isPartialValid() {
    if (!striped || REJECTED_CPF.includes(striped)) {
      return false;
    }
    if (strict) {
      return regex.cpfWithAndWithoutMask.test(document);
    }
    if (striped.length === 11) {
      return true;
    }
    return false;
  }
  if (!isPartialValid()) return false;
  let verifyDocument = striped.slice(0, 9);
  verifyDocument += _ValidationUtils.verifierDigitCpf(verifyDocument);
  verifyDocument += _ValidationUtils.verifierDigitCpf(verifyDocument);
  return verifyDocument.slice(9, 11) === striped.slice(9, 11);
});
_defineProperty(ValidationUtils, "isValidDocument", function (document) {
  let strict = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return _ValidationUtils.isValidCnpj(document, strict) || _ValidationUtils.isValidCpf(document, strict);
});
_defineProperty(ValidationUtils, "isValidInteger", function () {
  let number = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return regex.integer.test(number);
});
_defineProperty(ValidationUtils, "hasWeakPasswordPattern", password => {
  if (!password) return true;
  const createWeakPasswordRegex = function () {
    for (var _len = arguments.length, sequences = new Array(_len), _key = 0; _key < _len; _key++) {
      sequences[_key] = arguments[_key];
    }
    return sequences.map(p => "(?:".concat(p, ")|(?:").concat(p.split('').reverse().join(''), ")")).join('|');
  };
  const hasRepetitiveSequence = sequence => {
    return sequence.toLocaleLowerCase().split('').map((_, i) => {
      const before = i - 1;
      const current = i;
      const next = i + 1;
      return sequence[current] === sequence[before] && sequence[current] === sequence[next];
    }).some(item => item);
  };
  const forbiddenSequenceRegex = createWeakPasswordRegex('123', '456', '789', 'abc', 'password', 'senha', 'assertiva', 'segredo');
  return new RegExp(forbiddenSequenceRegex, 'gi').test(password) || hasRepetitiveSequence(password);
});
_defineProperty(ValidationUtils, "isValidPassword", function () {
  let password = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return regex.password.test(password) && !_ValidationUtils.hasWeakPasswordPattern(password);
});
/** @deprecated regex */
_defineProperty(ValidationUtils, "regex", () => {
  console.warn('[ValidationUtils] regex method is deprecated');
  return regex;
});
export default ValidationUtils;