import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  step: {
    '& .MuiStepLabel-labelContainer': {
      paddingTop: theme.spacing(2)
    },
    '& .MuiStepIcon-completed': {
      color: '#51C256'
    }
  },
  stepContent: {}
}));