import { applyDefaultEnvsWhenFound, applySpecificEnvsWhenFound } from './applyEnvsWhenFound';
import env from './env';
const development = () => {
  const {
    API_GATEWAY_URL,
    NOTIFIER_API_URL
  } = applyDefaultEnvsWhenFound({
    API_GATEWAY_URL: 'https://parquinho-k8s-dev.assertivasolucoes.com.br',
    NOTIFIER_API_URL: 'https://notifier-dev.assertivasolucoes.com.br'
  });
  return applySpecificEnvsWhenFound({
    ...env(),
    // api
    API_URL: 'https://api-dev.assertivasolucoes.com.br',
    API_GATEWAY_URL,
    CADASTRO_API_URL: "".concat(API_GATEWAY_URL, "/cadastro-backoffice"),
    CAMPAIGNS_API_URL: "".concat(API_GATEWAY_URL, "/cadastro-backoffice/campaigns"),
    CUSTOMER_API_URL: "".concat(API_GATEWAY_URL, "/backoffice-cliente"),
    COMUNIKA_API_URL: "".concat(API_GATEWAY_URL, "/dev-"),
    RECUPERE_API_URL: "".concat(API_GATEWAY_URL, "/dev-"),
    NOTIFIER_API_URL,
    NOTIFIER_CUSTOMER_API_URL: "".concat(NOTIFIER_API_URL, "/backoffice-cliente"),
    NOTIFIER_CADASTRO_API_URL: "".concat(NOTIFIER_API_URL, "/cadastro-backoffice"),
    REPORT_API_URL: 'https://ws-dev.assertivasolucoes.com.br',
    // apps
    AUTENTICA_URL: 'https://autentica-dev.assertivasolucoes.com.br',
    ASSINATURAS_URL: 'https://assinaturas-dev.assertivasolucoes.com.br',
    PAINEL_URL: 'http://painel-dev.assertivasolucoes.com.br',
    LOCALIZE_URL: 'https://localize-dev.assertivasolucoes.com.br',
    CADASTRO_URL: 'http://painel-dev.assertivasolucoes.com.br/grupos-e-usuarios/grupos',
    SMS_URL: 'https://sms-dev.assertivasolucoes.com.br',
    GEOTIMIZE_URL: 'https://geotimize-dev.assertivasolucoes.com.br',
    BASECERTA_URL: 'https://basecerta-dev.assertivasolucoes.com.br',
    CREDITOMIX_URL: 'https://creditomix-dev.assertivasolucoes.com.br',
    VEICULOS_URL: 'https://veiculos-dev.assertivasolucoes.com.br',
    RECUPERE_URL: 'https://recupere-dev.assertivasolucoes.com.br',
    WEBPHONE_URL: 'https://webphone-dev.assertivasolucoes.com.br',
    CREDITO_URL: 'https://credito-dev.assertivasolucoes.com.br'
  });
};
export default development;