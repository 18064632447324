var _CommonsService;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { axiosInstance, getBaseUrl } from './baseService';
class CommonsService {
  static getCustomerUrl() {
    return "".concat(getBaseUrl(), "/customers");
  }
}
_CommonsService = CommonsService;
_defineProperty(CommonsService, "getCustomerList", _ref => {
  let {
    filter = '',
    pageNumber = 0,
    pageSize = 10
  } = _ref;
  return axiosInstance.get("".concat(_CommonsService.getCustomerUrl(), "/list?numPage=").concat(pageNumber, "&numPageSize=").concat(pageSize, "&filter=").concat(filter));
});
export default CommonsService;