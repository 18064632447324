import { styled } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
export const StyledIconButton = styled(IconButton)(_ref => {
  let {
    theme
  } = _ref;
  return {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  };
});
export const StyledCloseIcon = styled(ClearIcon)(_ref2 => {
  let {
    theme
  } = _ref2;
  return {
    cursor: 'pointer',
    color: '#9A00C7'
  };
});