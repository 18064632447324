export default {
  MORE_THAN: 'Horário inicial não pode ser maior que o horário final.',
  MORE_THAN_DATE: 'A data inicial não pode ser maior que a data final.',
  MORE_THAN_DATE_CURRENT: 'O mês e ano não podem ser maiores que o mês e ano atuais.',
  INVALID: 'Horário inválido.',
  INVALID_DATE: 'Data inválida.',
  OUT_OF_RANGE_FIRST: 'O horário não pode ser menor que',
  OUT_OF_RANGE_PAST_DATE: 'O mês e ano não podem ser menores que',
  OUT_OF_RANGE_FUTURE_DATE: 'não pode ser maior que',
  OUT_OF_RANGE_LAST: 'O horário não pode ser maior que',
  EMPTY: ''
};