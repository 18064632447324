import { useState } from 'react';
function useFormState(initialFormState) {
  const [form, setForm] = useState({
    ...initialFormState
  });
  const setValue = (name, value) => {
    setForm(prev => ({
      ...prev,
      [name]: value
    }));
  };
  const setValues = newValues => {
    setForm(newValues);
  };
  const resetValue = name => {
    const initialValue = initialFormState[name];
    setForm(prev => ({
      ...prev,
      [name]: initialValue
    }));
    setForm({
      ...initialFormState
    });
  };
  const resetValues = () => {
    setForm({
      ...initialFormState
    });
  };
  return {
    form,
    setForm,
    setValue,
    setValues,
    resetValue,
    resetValues
  };
}
export default useFormState;