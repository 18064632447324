import { applyDefaultEnvsWhenFound } from './applyEnvsWhenFound';

/* INFO: adicione aqui env globais que não mudam entre as envs development, test ou production.
 *  Assim evitamos repetir as mesmas em vários arquivos.
 */

/**
 * @description
 *
 * IMPORTANT: Não setar chaves de aplicações ou chaves secretas diretamente nessas variáveis de ambiente do assertiva-ui,
 * uma vez que os valores aqui setados tem o objetivo de evitar duplicação de endpoints ou serviços
 * comuns em todas as aplicações que utilizam o assertiva-ui, deixando essa parte internamente abstraída.
 * Os valores abaixo ficam  escritos direto no js porque o assertiva-ui não possui process.env
 * próprio, quando se utiliza o process.env no assertiva-ui, você utiliza o process.env da aplicação
 * que esta usando o assertiva-ui. Para chaves secretas e sensíveis setar essas informações nas aplicações.
 */
const env = () => {
  return applyDefaultEnvsWhenFound({
    CAPTCHA_SITE_KEY: '6LdlOM0UAAAAAKC6NDuOf2485l2ymBkQ_ztp6l-I',
    MAX_UPLOAD_PART_SIZE: 10000 * 1024 * 1024,
    // 10 GB,
    PRODUCT_NAME: undefined,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    // não setar valores sensíveis aqui
    OUR_PRIVACY_POLICY_URL: 'https://assertivasolucoes.com.br/politica-de-privacidade/'
  });
};
export default env;