import { styled } from '@material-ui/core/styles';
import ConfirmDialog from 'components/ConfirmDialog';
const DialogActionsWrapperStyles = {
  display: 'flex',
  gridGap: 24,
  padding: 2,
  paddingTop: 1
};
export const StyledResetMFADialog = styled(ConfirmDialog)(() => ({
  '& .MuiDialogContent-root p': {
    display: 'none'
  },
  '& .ResetMFADialog-content': {
    fontSize: '1rem'
  },
  '& .ResetMFADialog-actions-wrapper': DialogActionsWrapperStyles
}));