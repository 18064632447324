const css = `.dropzone-container {
  background-color: #ffffff;
  padding: 16px;
  min-height: 300px;
  position: relative;
  color: rgba(0, 0, 0, 0.3);
  cursor: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dropzone-container .file-input {
  display: none;
}
.dropzone-container .dropping-container {
  position: absolute;
  bottom: 0;
  border-radius: 4px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  background-color: #ededed;
  width: 100%;
  transition: all 0.2s ease-in-out;
}
.dropzone-container .dropzone-disable-pointer-events {
  pointer-events: none;
}
.dropzone-container button {
  pointer-events: all;
}
.dropzone-container.dragging button {
  pointer-events: none;
}
.dropzone-container .dropping-icon {
  font-size: 70px;
  height: 70px;
  color: rgba(0, 0, 0, 0.3);
}
.dropzone-container .dropping-icon.light {
  color: rgba(35, 47, 52, 0.12);
  margin-bottom: 8px;
}
.dropzone-container .dropping-text {
  color: rgba(0, 0, 0, 0.3);
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 16px;
}

.dropzone-container.small {
  min-height: 180px;
}

.dropzone-container.clickable {
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvcnVubmVyL3dvcmsvYXNzZXJ0aXZhLXVpL2Fzc2VydGl2YS11aS9zcmMvY29tcG9uZW50cy9Ecm9wem9uZSIsInNvdXJjZXMiOlsiRHJvcHpvbmUuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUVBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBOzs7QUFJSjtFQUNFOzs7QUFHRjtFQUNFIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
