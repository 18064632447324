import { isMoment } from 'moment/moment';
import AccessDays from 'constants/accessDays';
const isAllMoment = params => {
  return !params.some(p => !isMoment(p));
};
const allDaysSelected = days => {
  return days.length === 7;
};
const allDaysWeekdaySelected = days => {
  return !AccessDays.DAYS_WEEK.WEEKDAYS.some(d => !days.includes(d));
};
const existDayOfWeekday = days => {
  return AccessDays.DAYS_WEEK.WEEKDAYS.some(d => days.includes(d));
};
const existDayOfWeekend = days => {
  return AccessDays.DAYS_WEEK.WEEKENDS.some(d => days.includes(d));
};
const getDaysOfWeekday = days => {
  return AccessDays.DAYS_WEEK.WEEKDAYS.filter(d => days.includes(d));
};
const getDaysOfWeekend = days => {
  return AccessDays.DAYS_WEEK.WEEKENDS.filter(d => days.includes(d));
};
const dayWithAccent = day => {
  switch (day) {
    case 'terca':
      return 'terça';
    case 'sabado':
      return 'sábado';
    default:
      return day;
  }
};
const hoursAreEqual = (hourInitialWeekday, hourFinalWeekday, hourInitialWeekend, hourFinalWeekend) => {
  return hourInitialWeekday.isSame(hourInitialWeekend) && hourFinalWeekday.isSame(hourFinalWeekend);
};
const formatArrayToDisplay = array => {
  if (!array) return '';
  const aAccents = array.map(item => dayWithAccent(item));
  if (aAccents.length <= 1) return aAccents.toString();
  const others = aAccents.slice(0, aAccents.length - 1);
  const lastValue = [...aAccents].pop();
  return "".concat(others.join(', '), " e ").concat(lastValue || '');
};
const formatHour = hour => {
  const formatHoursPattern = 'HH';
  const formatMinutesPattern = 'mm';
  return "".concat(hour.format(formatHoursPattern), "h").concat(hour.format(formatMinutesPattern)).replace('h00', 'h');
};
const formatInterval = (dateInitial, dateFinal) => {
  return " das ".concat(formatHour(dateInitial), " \xE0s ").concat(formatHour(dateFinal));
};
export const formatMessageToHoursWithDays = (hourInitialWeekday, hourFinalWeekday, hourInitialWeekend, hourFinalWeekend, days) => {
  // da para melhorar bem mas acredito que vai ficar bem confuso o entendimento por se tratar de data
  // por isso fiz os passos detalhados, chamando funcoes separadas para cada coisa
  if (!isAllMoment([hourInitialWeekday, hourInitialWeekend, hourFinalWeekday, hourFinalWeekend])) {
    return '';
  }
  if (hoursAreEqual(hourInitialWeekday, hourFinalWeekday, hourInitialWeekend, hourFinalWeekend)) {
    if (allDaysSelected(days)) {
      return "todos os dias ".concat(formatInterval(hourInitialWeekday, hourFinalWeekday), ".");
    }
    return "".concat(formatArrayToDisplay(days) + formatInterval(hourInitialWeekday, hourFinalWeekday), ".");
  }
  if (allDaysSelected(days)) {
    return "de segunda \xE0 sexta ".concat(formatInterval(hourInitialWeekday, hourFinalWeekday), " e s\xE1bado e domingo ").concat(formatInterval(hourInitialWeekend, hourFinalWeekend), ".");
  }
  let result = '';
  if (existDayOfWeekday(days)) {
    if (allDaysWeekdaySelected(days)) {
      result += "de segunda \xE0 sexta ".concat(formatInterval(hourInitialWeekday, hourFinalWeekday));
    } else {
      result += formatArrayToDisplay(getDaysOfWeekday(days));
      result += "".concat(formatInterval(hourInitialWeekday, hourFinalWeekday));
    }
  }
  if (existDayOfWeekend(days)) {
    result += "".concat(result.length ? ' e ' : '', " ");
    result += formatArrayToDisplay(getDaysOfWeekend(days));
    result += "".concat(formatInterval(hourInitialWeekend, hourFinalWeekend));
  }
  return "".concat(result, ".");
};