import RuntimeEnvs from 'utils/RuntimeEnvs';
import development from './development';
import production from './production';
import test from './test';
const getAppEnvAvailable = () => {
  switch (process.env.REACT_APP_APP_ENV) {
    /* INFO: pode ter ficado meio redundante essa case com o retorno igual ao que é testado, 
      você pode perguntar: "nao poderia simplesmente devolver o conteúdo?",
      no entanto mantivemos assim para que o retorno fosse o esperado no ts, ou seja um dos três valores
      development, production ou  test, qualquer coisa diferente será apresentado um erro.
    */
    case 'development':
      return 'development';
    case 'production':
      return 'production';
    case 'test':
      return 'test';
    default:
      throw new Error('envs: é necessário adicionar a variável REACT_APP_APP_ENV como variável de ambiente em seus arquivos .env com um dos seguintes valores: development, test ou production');
  }
};
const getEnvs = () => {
  const environments = {
    test: test(),
    development: development(),
    production: production()
  };
  return {
    ...environments[getAppEnvAvailable()]
  };
};
const staticEnvironments = getEnvs();

/**
 * @description
 * Você pode dar um override nas variáveis de ambientes aqui presentes criando a sua variável de ambiente utilizando o prefixo
 * REACT_APP_ASSERTIVA. Por exemplo:
 *
 * Exemplo:
 * ```
 * - REACT_APP_ASSERTIVA_API_GATEWAY_URL = 'https://apigateway-temp.assertivasolucoes.com.br'
 * - REACT_APP_ASSERTIVA_GEOTIMIZE_URL = 'https://geotimize-temp.assertivasolucoes.com.br'
 * - etc.
 *```
 * Obs: para uma lista completa das variáveis de ambientes veja o type Environments, localize em /types/environments.ts no assertiva-ui.
 *
 * Ou você pode mudar as mesmas em tempo de execução:
 *
 * ```
 *  RuntimeEnvs.configure({ ... })
 * ```
 *
 */
const Envs = () => RuntimeEnvs.hasRuntimeEnvs() ? getEnvs() : staticEnvironments;
export { Envs };