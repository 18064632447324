const BASE_PROFILES = {
  ADMINISTRATOR: {
    ID: 1,
    NAME: 'Administrador'
  },
  FINANCIAL: {
    ID: 2,
    NAME: 'Financeiro'
  },
  OPERATOR: {
    ID: 3,
    NAME: 'Operador'
  },
  MANAGER: {
    ID: 4,
    NAME: 'Gerente'
  }
};
export const BACKOFFICE_BASE_PROFILES = {
  ADMINISTRATIVO: 'Administrativo',
  FINANCEIRO: 'Financeiro',
  GERENTE: 'Gerente',
  OPERADOR: 'Operador'
};
export default BASE_PROFILES;