function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { LS_PURPOSE_OF_USE_KEY } from 'constants/utils';
import htmlReactParser from 'html-react-parser';
import _chunk from 'lodash/chunk';
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _isNumber from 'lodash/isNumber';
import _set from 'lodash/set';
import moment from 'moment';
class FunctionUtils {
  static throwIfMissing(param, klass) {
    throw new Error("Missing parameter ".concat(param, " on class/function ").concat(klass));
  }

  /**
   *
   * @param array array que sera "fatiado"
   * @param page página que deseja retornar. Por default inicia na página 0 (zero)
   * @param rowsPerPage quantidade de items que terá cada página. Por default retorna 5 items por página
   * @returns "fatia" do array que será retornada
   */
  static sliceArrayPerPage() {
    let array = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    let rowsPerPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 5;
    if (!array) return [];
    return array.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }

  /**
   *
   * @param arr array que será fatiado
   * @param size tamanho de cada fatia
   * @returns retorna um novo array composto por outros array de tamanho n
   * @example chunks([1,2,3,4,5,6,7],3) => [[1,2,3],[4,5,6],[7]]
   */
  static chunks(arr, size) {
    if (!arr) return [];
    return _chunk(arr, size);
  }
  static scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  static formatBytes(bytes) {
    let decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    let binaryUnits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    if (bytes === 0) {
      return '0 Bytes';
    }
    const unitMultiple = binaryUnits ? 1024 : 1000;
    const unitNames = unitMultiple === 1024 // 1000 bytes in 1 Kilobyte (KB) or 1024 bytes for the binary version (KiB)
    ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'] : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
    return "".concat(parseFloat((bytes / unitMultiple ** unitChanges).toFixed(decimals)), " ").concat(unitNames[unitChanges]);
  }
  static humanizeFileName() {
    let fileName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let fileSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    let maxLengthName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;
    const extension = fileName.split('.').pop();
    const sizeString = _isNumber(fileSize) ? FunctionUtils.formatBytes(fileSize) : fileSize;
    return "".concat(fileName.length > maxLengthName ? "".concat(fileName.slice(0, maxLengthName), "...").concat(extension || '') : "".concat(fileName), " ").concat(sizeString ? "(".concat(sizeString, ")") : '');
  }

  /**
   * Essa função verifica se um elemento do DOM está com ellipsis (...) ou não
   * @param {Object} el
   */
  static checkEllipsis(el) {
    const styles = getComputedStyle(el);
    const widthEl = parseFloat(styles.width);
    const ctx = document.createElement('canvas').getContext('2d');
    if (ctx) {
      ctx.font = "".concat(styles.fontSize, " ").concat(styles.fontFamily);
      const text = ctx.measureText(el.innerText);
      return text.width > widthEl;
    }
    return false;
  }

  /**
   * Recebe uma variável e devolver o seu tipo.
   * O typeof do javascript devolver o typo 'object' para null ou array.
   * @param any
   */
  static getTypeOf(any) {
    var _toString$call;
    return (_toString$call = {}.toString.call(any)) === null || _toString$call === void 0 || (_toString$call = _toString$call.match(/\s([a-zA-Z]+)/)) === null || _toString$call === void 0 || (_toString$call = _toString$call[1]) === null || _toString$call === void 0 ? void 0 : _toString$call.toLowerCase();
  }

  /**
   *
   * @param value
   * @returns retorna true para '',[], {}, null e undefined
   */
  static isEmpty(value) {
    const typeData = FunctionUtils.getTypeOf(value);
    if (typeData === 'string' && !value) {
      return true;
    }
    if (typeData === 'array' && value.length === 0) {
      return true;
    }
    if (typeData === 'object' && Object.keys(value).length === 0) {
      return true;
    }
    if (typeData === 'null' || typeData === 'undefined') {
      return true;
    }
    return false;
  }

  /**
   *
   * @param str
   * @returns retorna um hash numérico com base na string de entrada. A entrada x sempre retornará um hash y.
   * @example hashCode({1})
   */
}
_defineProperty(FunctionUtils, "scrollToRef", ref => {
  if (ref) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  } else {
    console.warn('[ScrollToRef] : Ref is null or not valid.');
  }
});
_defineProperty(FunctionUtils, "hashCode", input => {
  if (!input) return 0;
  const str = JSON.stringify(input);
  const hash = Array.from(str).reduce((s, c) => "".concat(Math.imul(31, s)).concat(c.charCodeAt(0)), 0);
  return Math.abs(hash);
});
_defineProperty(FunctionUtils, "generateNotifyId", function () {
  let prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return "".concat(prefix).concat(moment().unix());
});
/**
 * Method used to update the parameter within an object,
 * context and instance. If the checkAttibrute attribute is not true,
 * it will not verify the existence of the property before entering the value
 * Parans object, name and value are required
 * @param {*} object
 * @param {string} name
 * @param {*} value
 * @param {boolean} [checkAttribute=]
 */
_defineProperty(FunctionUtils, "updateProperty", function (object, name, value) {
  let checkAttribute = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  const clonedObject = _cloneDeep(object);
  const infoText = 'updateProperty -> FunctionUtils -> Assertiva UI';
  if (!clonedObject) throw new Error("Empty object for to update attribute [object -> ".concat(infoText, "]"));
  if (!name) throw new Error("Empty name for to update attribute [object -> ".concat(infoText, "]"));
  if (checkAttribute && !_has(clonedObject, name)) throw new Error("".concat(name, " is not found [object -> ").concat(infoText, "]"));
  _set(clonedObject, name, value);
  return clonedObject;
});
_defineProperty(FunctionUtils, "parserStringToHTML", function () {
  let string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return htmlReactParser(string);
});
/**
 *
 * @param params
 * @returns retorna uma string formada pela concatenação do parâmetros e o term.
 * @example  joinParamsWith("João","de","Barro")('#') // "João#de#Barro".
 */
_defineProperty(FunctionUtils, "joinParamsWith", function () {
  for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
    params[_key] = arguments[_key];
  }
  return term => {
    if (!params) return '';
    return params.join(term);
  };
});
/**
 *  @description
 *  Retorna um array de string de tamanho 9 onde cada item do array será formato por um documento que deve ser rejeitado nas validações.
 *  @example
 *  - generateSequentialArrayNine("CPF") // [ "00000000000", "11111111111", "22222222222",..."99999999999"]
 *  - generateSequentialArrayNine("CNPJ") // [ "00000000000000", "11111111111111", "22222222222222",..."99999999999999"]
 */
_defineProperty(FunctionUtils, "generateRejectedDocuments", type => {
  return Array(10).fill(0).map((_, index) => index.toString().padEnd(type === 'CNPJ' ? 14 : 11, index.toString()));
});
_defineProperty(FunctionUtils, "clearLocalStorage", () => {
  const cachedPurposesOfUse = window.localStorage.getItem(LS_PURPOSE_OF_USE_KEY);
  window.localStorage.clear();
  if (cachedPurposesOfUse) {
    window.localStorage.setItem(LS_PURPOSE_OF_USE_KEY, cachedPurposesOfUse);
  }
});
/**
 * @description Retorna uma promise que será resolvida no tempo especificado em milisegundos.
 * @param {Number} ms - Tempo de espera
 * @example  await sleep(3000); // "esperar 3 segundos".
 */
_defineProperty(FunctionUtils, "sleep", ms => new Promise(r => setTimeout(r, ms)));
export default FunctionUtils;