import { sendAnalytics } from 'utils/analytics';
import AUDIO_URLS from './urlAudios';
import { DESKTOP_NOTIFICATIONS_LOGO } from './urlImages';
export const DESKTOP_NOTIFICATION_VARIANTS = {
  NOTIFY_SESSION_END: {
    title: 'Sua sessão está expirando',
    options: {
      body: 'Clique aqui para retornar ao portal.',
      tag: 'astv-session-end',
      requireInteraction: true,
      renotify: false,
      badge: DESKTOP_NOTIFICATIONS_LOGO,
      icon: DESKTOP_NOTIFICATIONS_LOGO
    },
    sound: AUDIO_URLS.ALERT_SESSION_END,
    analytics: {
      event: 'exibir-not',
      page: 'sessao'
    },
    onClick: () => {
      sendAnalytics({
        event: 'click-not',
        page: 'sessao'
      });
      window.focus();
    }
  }
};