import { useState, useRef } from 'react';
import useRulesValidation from 'hooks/useRulesValidation';
import ValidationUtils from 'utils/ValidationUtils';
import ErrorUtils from 'utils/ErrorUtils';
import Notifier from 'components/Notification/Notifier';
import LoadingManager from 'components/Loading/LoadingManager';
import MESSAGES from 'constants/messages';
import OAuth2Service from 'services/OAuth2Service';
const usePasswordChange = _ref => {
  let {
    hash,
    urlRedirect
  } = _ref;
  const recaptchaRef = useRef(null);
  const [state, setState] = useState({
    password: '',
    passwordCheck: '',
    loading: false,
    messages: {
      password: '',
      passwordCheck: undefined
    },
    showConfirmScreen: false,
    showPasswordCheck: false,
    showPassword: false,
    anchorEl: null,
    showPasswordValidatePopper: false
  });
  const [mfa, setMfa] = useState(null);
  const {
    errors,
    validate,
    clearError
  } = useRulesValidation([{
    field: 'password',
    messageError: 'Senha é obrigatória.',
    hasErrors: () => !state.password
  }, {
    field: 'password',
    messageError: MESSAGES.invalidRulesPassword,
    hasErrors: () => !ValidationUtils.isValidPassword(state.password)
  }, {
    field: 'passwordCheck',
    messageError: 'Confirmar senha é obrigatório.',
    hasErrors: () => !state.passwordCheck
  }, {
    field: 'passwordCheck',
    messageError: MESSAGES.invalidPasswordConfirmation,
    hasErrors: () => state.password !== state.passwordCheck
  }]);
  const onVerifiedCodeSucessMFA = () => {
    setState({
      ...state,
      password: '',
      passwordCheck: '',
      showConfirmScreen: true
    });
  };
  const doSubmit = () => {
    LoadingManager.show();
    OAuth2Service.changePassword({
      password: state.password,
      passwordCheck: state.passwordCheck,
      hash
    }).then(response => {
      const {
        data
      } = response;
      if (data !== null && data !== void 0 && data.mfa) {
        setMfa(data);
        return;
      }
      setState({
        ...state,
        password: '',
        passwordCheck: '',
        showConfirmScreen: true
      });
    }).catch(error => {
      Notifier.error(ErrorUtils.normalizeErrorMessage(error));
    }).finally(() => {
      LoadingManager.close();
    });
  };
  const onConfirm = () => {
    window.location.href = urlRedirect;
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (validate() && recaptchaRef) {
      recaptchaRef.current.execute();
    }
  };
  const handleState = (name, value) => {
    setState({
      ...state,
      [name]: value
    });
  };
  const toggleValidationPopper = () => {
    setState({
      ...state,
      showPasswordValidatePopper: true
    });
  };
  const toggleShowCheckPassword = () => {
    setState({
      ...state,
      showPasswordCheck: !state.showPasswordCheck
    });
  };
  const toggleShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword
    });
  };
  return {
    state,
    mfa,
    setState,
    errors,
    recaptchaRef,
    clearError,
    validate,
    handleState,
    toggleValidationPopper,
    toggleShowPassword,
    toggleShowCheckPassword,
    handleSubmit,
    onVerifiedCodeSucessMFA,
    doSubmit,
    onConfirm
  };
};
export default usePasswordChange;