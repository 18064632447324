function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import PubSub from 'pubsub-js';
import AUNotifier from 'components/Notification/Notifier';
import UserModel from 'models/UserModel';
import UserService from 'services/UserService';
import ErrorUtils from 'utils/ErrorUtils';
import LoadingManager from 'components/Loading/LoadingManager';
import AuthUtils from 'utils/AuthUtils';
import { EDIT_USERS_COMPANY_LEVEL, EDIT_CLIENTS_USERS, PASSWORD_CHANGE_REQUEST } from 'constants/permissions';
import CONSTANTS from '../constants';
const {
  USER_STATUS
} = CONSTANTS;
const NOTIFY_EDIT_USER_DIALOG = 'NOTIFY_EDIT_USER_DIALOG';
class EditUSerStore {
  constructor() {
    _defineProperty(this, "subscription", null);
    _defineProperty(this, "initialState", Object.freeze({
      user: new UserModel(),
      status: [],
      groups: [],
      profiles: [],
      errors: {
        hasErros: false,
        messages: {}
      },
      requestedNewPasswordChange: false,
      hasEditUserPermission: () => AuthUtils.getUser().hasPermission(EDIT_USERS_COMPANY_LEVEL, EDIT_CLIENTS_USERS),
      hasPasswordChangeRequestPermission: () => AuthUtils.getUser().hasPermission(PASSWORD_CHANGE_REQUEST)
    }));
    _defineProperty(this, "state", {
      ...this.initialState
    });
    _defineProperty(this, "customerId", 0);
    _defineProperty(this, "init", _ref => {
      let {
        customerId,
        user,
        groups,
        profiles,
        status
      } = _ref;
      // Save data from props to state if exist
      this.customerId = customerId;
      if (user && user.id) {
        this.state.user = new UserModel(user);
      }
      if (groups && groups.length) {
        this.state.groups = groups;
      }
      if (profiles && profiles.length) {
        this.state.profiles = profiles;
      }
      if (status && status.length) {
        this.state.status = status;
      }
      this.notify();
    });
    _defineProperty(this, "notify", () => {
      PubSub.publish(NOTIFY_EDIT_USER_DIALOG, {
        ...this.state
      });
    });
    _defineProperty(this, "subscribe", callback => {
      this.subscription = PubSub.subscribe(NOTIFY_EDIT_USER_DIALOG, (_, state) => {
        callback(state);
      });
    });
    _defineProperty(this, "unsubscribe", () => {
      if (this.subscription) {
        PubSub.unsubscribe(this.subscription);
      }
    });
    _defineProperty(this, "reset", () => {
      this.state = {
        ...this.initialState
      };
      this.notify();
    });
    _defineProperty(this, "handleGroup", groupId => {
      const group = this.state.groups.find(g => g.id && groupId && Number(g.id) === Number(groupId));
      if (group) {
        this.state.user.group = group;
      }
      this.notify();
    });
    _defineProperty(this, "handleProfile", profileId => {
      const profile = this.state.profiles.find(p => p.id && profileId && Number(p.id) === Number(profileId));
      if (profile) {
        this.state.user.profile = profile;
      }
      this.notify();
    });
    _defineProperty(this, "disabledDocAndPhoneByMFA", () => {
      return AuthUtils.getUser().getCompany().mfa;
    });
    _defineProperty(this, "handleUser", (name, value) => {
      this.state.user[name] = value;
      this.notify();
    });
    _defineProperty(this, "handleUserBirthDate", value => {
      this.state.user.birthdate = value;
      this.notify();
    });
    _defineProperty(this, "toggleStatus", statusId => {
      let newStatus = {};
      if (statusId === USER_STATUS.ACTIVE_ID) {
        newStatus = this.state.status.find(s => s.id === USER_STATUS.INACTIVE_ID);
      } else {
        newStatus = this.state.status.find(s => s.id === USER_STATUS.ACTIVE_ID);
      }
      if (newStatus) {
        this.state.user.status = newStatus;
      }
      this.notify();
    });
    _defineProperty(this, "clearError", () => {
      this.state.errors = this.initialState.errors;
      this.notify();
    });
    _defineProperty(this, "requestNewPassword", callback => {
      LoadingManager.show();
      UserService.requestNewPassword({
        customerId: this.customerId,
        email: this.state.user.email
      }).then(response => {
        AUNotifier.success(response.data.message);
        callback({
          ...this.state.user,
          status: {
            ...USER_STATUS.BLOCK_SECURITY
          }
        });
      }).catch(error => {
        AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
      }).finally(() => {
        LoadingManager.close();
      });
    });
    _defineProperty(this, "saveUser", callback => {
      if (this.validate()) {
        LoadingManager.show();
        UserService.updateUser({
          customerId: this.customerId,
          user: this.state.user
        }).then(response => {
          AUNotifier.success(response.data.message);
          callback(this.state.user);
        }).catch(error => {
          AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
        }).finally(() => {
          LoadingManager.close();
        });
      }
    });
    _defineProperty(this, "validate", () => {
      this.state.errors = this.state.user.isValid();
      this.notify();
      return !this.state.errors.hasErros;
    });
  }
}
export default new EditUSerStore();