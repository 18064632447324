export const VARIANTS = {
  BLOCKED: 'blocked',
  ERROR: 'error',
  INFO: 'info',
  REPORT: 'report',
  WARNING: 'warning',
  CLOSE_TO_DUE_DATE: 'closeToDueDate',
  DUE_DATE: 'dueDate',
  NEXT_TO_BLOCK_DATE: 'nextToBlockDate',
  OVERDUE_DATE: 'overdueDate'
};