function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { axiosInstance, getBaseUrl } from './baseService';
import { prepareUrl } from '../containers/UserGroup/utils/UserGroupUtils';
class GroupService {
  static putGroup(_ref) {
    let {
      customerId = '',
      groupId = '',
      params = ''
    } = _ref;
    return axiosInstance.put("".concat(getBaseUrl(), "/customers").concat(prepareUrl(customerId), "/groups/").concat(groupId, "/"), params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
  static postGroup(_ref2) {
    let {
      customerId,
      params
    } = _ref2;
    return axiosInstance.post("".concat(getBaseUrl(), "/customers").concat(prepareUrl(customerId), "/groups"), params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
_defineProperty(GroupService, "getSelectData", _ref3 => {
  let {
    customerId
  } = _ref3;
  return axiosInstance.get("".concat(getBaseUrl(), "/customers").concat(prepareUrl(customerId), "/select-data?type=1"));
});
_defineProperty(GroupService, "getGroupById", _ref4 => {
  let {
    customerId,
    groupId
  } = _ref4;
  return axiosInstance.get("".concat(getBaseUrl(), "/customers").concat(prepareUrl(customerId), "/groups/").concat(+groupId));
});
_defineProperty(GroupService, "getGroups", _ref5 => {
  let {
    customerId = ''
  } = _ref5;
  return axiosInstance.get("".concat(getBaseUrl(), "/customers").concat(prepareUrl(customerId), "/groups"));
});
export default GroupService;