var _ConnectionUtils;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable dot-notation */
const EFFECTIVE_TYPES = {
  FOUR_G: '4g',
  THREE_G: '3g',
  TWO_G: '2g',
  SLOW_TWO_G: 'slow-2g'
};
class ConnectionUtils {
  constructor(listenOnline, listenOffline, listenSlowlyConnection) {
    window.addEventListener('online', e => {
      if (!ConnectionUtils.isSlowly) {
        listenOnline(e);
      }
      ConnectionUtils.connected = true;
    });
    window.addEventListener('offline', e => {
      ConnectionUtils.connected = false;
      listenOffline(e);
    });
    if (window.navigator) {
      const {
        navigator
      } = window;
      const connection = navigator['connection'];
      if (connection) {
        connection.onchange = e => {
          const {
            effectiveType
          } = e.target;
          if (effectiveType === EFFECTIVE_TYPES.TWO_G || effectiveType === EFFECTIVE_TYPES.SLOW_TWO_G) {
            listenSlowlyConnection(e.target);
            ConnectionUtils.isSlowly = true;
          } else if (ConnectionUtils.connected && ConnectionUtils.isSlowly) {
            ConnectionUtils.isSlowly = false;
            dispatchEvent(new Event('online'));
          }
        };
      }
    }
  }
  static init(listenOnline, listenOffline, listenSlowlyConnection) {
    if (!ConnectionUtils.instance) {
      ConnectionUtils.instance = new ConnectionUtils(listenOnline, listenOffline, listenSlowlyConnection);
    }
    return ConnectionUtils.instance;
  }
}
_ConnectionUtils = ConnectionUtils;
_defineProperty(ConnectionUtils, "instance", void 0);
_defineProperty(ConnectionUtils, "connected", true);
_defineProperty(ConnectionUtils, "isSlowly", false);
export default ConnectionUtils;