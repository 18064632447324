import { styled } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import WhatsApp from '@material-ui/icons/WhatsApp';
export const StyledWhatsAppIcon = styled(WhatsApp)(_ref => {
  let {
    theme
  } = _ref;
  return {
    color: theme.palette.common.white,
    width: '24px',
    height: '24px'
  };
});
export const StyledWhatsAppIconButton = styled(IconButton)(_ref2 => {
  let {
    theme
  } = _ref2;
  return {
    color: theme.palette.common.white,
    backgroundColor: '#34AF23',
    width: '40px',
    height: '40px',
    '&:hover': {
      transition: '250ms background-color',
      backgroundColor: '#2E7D32'
    },
    '&.Mui-disabled': {
      backgroundColor: '#9E9E9E'
    }
  };
});