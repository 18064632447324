import Cookies from 'js-cookie';
import moment from 'moment';
import momenttz from 'moment-timezone';
import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';
import { COOKIES_OPTIONS } from 'constants/cookies';
import { getCookieName } from './CookiesUtil';
const ACCESS_TOKEN_MAX_AGE = 'accessTokenMaxAge';
const COUNTDOWN_READ = 'countdownRead';
const LOGIN_TIME = 'loginTime';
const REFRESH_TOKEN = 'refreshToken';
const TOKEN = 'accessToken';
export const countdownReadCookie = getCookieName(COUNTDOWN_READ);
export const dateToFinishSessionCookie = getCookieName(ACCESS_TOKEN_MAX_AGE);
export const loginTimeCookie = getCookieName(LOGIN_TIME);
export const refreshTokenCookie = getCookieName(REFRESH_TOKEN);
export const tokenCookie = getCookieName(TOKEN);
class Oauth2CookiesUtils {
  static clearCookies() {
    Cookies.remove(tokenCookie, COOKIES_OPTIONS);
    Cookies.remove(refreshTokenCookie, COOKIES_OPTIONS);
    Cookies.remove(loginTimeCookie, COOKIES_OPTIONS);
    Cookies.remove(countdownReadCookie, COOKIES_OPTIONS);
  }
  static getToken() {
    return Cookies.get(tokenCookie);
  }
  static getTokenAsAuthorization() {
    return "Bearer ".concat(Oauth2CookiesUtils.getToken() || '');
  }
  static getAccessTokenMaxAgeCookie() {
    return moment(Cookies.get(dateToFinishSessionCookie));
  }
  static getCountdownReadCookie() {
    const countdownRead = Cookies.get(countdownReadCookie);
    if (!_isUndefined(countdownRead)) {
      const read = _get(JSON.parse(Cookies.get(countdownReadCookie)), 'read', false);
      return read;
    }
    return false;
  }
  static setCountdownReadCookie(read) {
    Cookies.set(countdownReadCookie, {
      read
    }, COOKIES_OPTIONS);
  }
  static getLoginTimeCookie() {
    const loginTime = Cookies.get(loginTimeCookie);
    if (!_isUndefined(loginTime)) {
      return JSON.parse(loginTime);
    }
    return {};
  }
  static setLoginTimeCookie() {
    Cookies.set(loginTimeCookie, {
      loginTime: moment(momenttz().tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss.SSSS')).toISOString(),
      read: false
    }, {
      ...COOKIES_OPTIONS
    });
  }
  static setLoginTimeCookieAsRead() {
    const currentCookieValue = Oauth2CookiesUtils.getLoginTimeCookie();
    Cookies.set(loginTimeCookie, {
      ...currentCookieValue,
      read: true
    }, {
      ...COOKIES_OPTIONS
    });
  }
  static timeToFinishSession(diff) {
    const accessTokenMaxAge = Oauth2CookiesUtils.getAccessTokenMaxAgeCookie();
    if (accessTokenMaxAge.isValid()) {
      return accessTokenMaxAge.diff(moment(momenttz().tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss.SSSS')), diff);
    }
    return 0;
  }
}
export default Oauth2CookiesUtils;