function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import ProductUtils from 'utils/ProductUtils';
import UserAdapterUtils, { UNAVAILABLE_MSG } from './UserAdapterUtils';
class UserGuidingAdapter {
  constructor() {
    _defineProperty(this, "userForUserGuiding", {
      environment: UNAVAILABLE_MSG,
      appEnvironment: UNAVAILABLE_MSG,
      createdAt: UNAVAILABLE_MSG,
      email: UNAVAILABLE_MSG,
      hasAPI: false,
      isMigration: false,
      isTrial: false,
      name: UNAVAILABLE_MSG,
      profile: UNAVAILABLE_MSG,
      company: UNAVAILABLE_MSG
    });
  }
  adapt(user) {
    if (!user) throw new Error('[IntercomAdapter]: Failed map data for UserGuiding. Invalid user.');
    if (!user.email) throw new Error("[UserGuidingAdapter]: Failed map data for UserGuiding. Invalid 'email'.");
    this.userForUserGuiding.id = ProductUtils.isBackofficeInternoEnv() ? "backoffice-".concat(user.email) : user.email;
    this.userForUserGuiding.environment = UserAdapterUtils.getEnvironment();
    this.userForUserGuiding.appEnvironment = UserAdapterUtils.getSuffixByAppEnviroment();
    this.userForUserGuiding.createdAt = user.createdAt || '';
    this.userForUserGuiding.email = user.email;
    this.userForUserGuiding.hasAPI = user.hasAPI();
    this.userForUserGuiding.isMigration = user.isCompanyMigration();
    this.userForUserGuiding.isTrial = user.isTrial();
    this.userForUserGuiding.name = user.name;
    this.userForUserGuiding.company = user.company.name;
    this.userForUserGuiding.profile = UserAdapterUtils.contentOrDefaultValue(user.getBaseProfileName());
    return this.userForUserGuiding;
  }
}
export default new UserGuidingAdapter();