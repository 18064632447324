function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import MESSAGES from 'constants/messages';
import ValidationsHelper from 'helpers/ValidationsHelper';
import AuthUtils from 'utils/AuthUtils';
class UserModel {
  constructor() {
    let user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    _defineProperty(this, "id", '');
    _defineProperty(this, "name", '');
    _defineProperty(this, "cpf", '');
    _defineProperty(this, "birthdate", null);
    _defineProperty(this, "email", '');
    _defineProperty(this, "mobile", '');
    _defineProperty(this, "phone", '');
    _defineProperty(this, "group", {
      id: 0,
      name: ''
    });
    _defineProperty(this, "profile", {
      id: 0,
      name: ''
    });
    _defineProperty(this, "status", {
      id: 0,
      name: ''
    });
    _defineProperty(this, "permissions", []);
    _defineProperty(this, "mfa", {
      status: '',
      question: '',
      answer: ''
    });
    _defineProperty(this, "filterRulesBySensitiveData", rules => {
      if (AuthUtils.getUser().getCompany().mfa) {
        delete rules.cpf;
        delete rules.mobile;
      }
      return rules;
    });
    _defineProperty(this, "isValid", () => {
      let erros = {
        hasErros: true,
        messages: {}
      };
      const rules = {
        name: {
          required: MESSAGES.REQUIRED_NAME
        },
        email: {
          required: MESSAGES.REQUIRED_EMAIL,
          isEmail: MESSAGES.INVALID_EMAIL
        },
        cpf: {
          required: MESSAGES.REQUIRED_CPF,
          isDocument: MESSAGES.INVALID_CPF
        },
        mobile: {
          isMobilePhone: MESSAGES.INVALID_MOBILE_PHONE
        },
        phone: {
          isLandlinePhone: MESSAGES.INVALID_PHONE
        },
        'group.id': {
          required: MESSAGES.REQUIRED_GROUP
        },
        'profile.id': {
          required: MESSAGES.REQUIRED_PROFILE
        }
      };
      try {
        erros = ValidationsHelper.isValidByRules(this, this.filterRulesBySensitiveData(rules));
      } catch (e) {
        console.error(e);
        erros = {
          hasErros: true,
          messages: {}
        };
      }
      return erros;
    });
    this.id = user.id || '';
    this.name = user.name || '';
    this.cpf = user.cpf || '';
    this.birthdate = user.birthdate || null;
    this.mobile = user.mobile || '';
    this.phone = user.phone || '';
    this.email = user.email || '';
    this.group = user.group || {
      id: 0,
      name: ''
    };
    this.profile = user.profile || {
      id: 0,
      name: ''
    };
    this.mfa = user.mfa || {
      status: '',
      question: '',
      answer: ''
    };
    this.status = user.status || {
      id: 0,
      name: ''
    };
    this.permissions = user.permissions || [];
  }
}
export default UserModel;