import { styled } from '@material-ui/core';
import Box from '@material-ui/core/Box';
export const StyledWrapper = styled(Box)(_ref => {
  let {
    theme
  } = _ref;
  return {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  };
});
export const StyledWrapperContacts = styled(Box)(_ref2 => {
  let {
    theme
  } = _ref2;
  return {
    marginBottom: theme.spacing(0.5),
    textAlign: 'center'
  };
});
export const StyledWrapperPhones = styled(Box)(_ref3 => {
  let {
    theme
  } = _ref3;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      columnGap: '16px'
    }
  };
});
export const StyledLink = styled('a')({
  color: '#647485',
  textDecoration: 'none',
  lineHeight: '1.25rem',
  fontSize: '0.875rem',
  fontWeight: 400,
  textAlign: 'center',
  '&:hover': {
    color: '#4e5a66',
    transition: '.2 all'
  }
});