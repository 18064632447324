import { useState, useEffect } from 'react';
import { OPTION_ALL } from './types';
const useCheckboxSelect = _ref => {
  let {
    items,
    minimalChecks,
    checkAllOptionLabel,
    uncheckOptionLabel,
    onChange
  } = _ref;
  const [values, setValues] = useState([]);
  const [totalSelected, setTotalSelected] = useState(0);
  const [selectDisplayValue, setSelectedDisplayValue] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  useEffect(() => {
    setValues([...items]);
  }, [items]);
  useEffect(() => {
    setSelectedDisplayValue([...values.filter(item => item.checked).map(_ref2 => {
      let {
        name
      } = _ref2;
      return name;
    })]);
    setTotalSelected(values.filter(_ref3 => {
      let {
        checked
      } = _ref3;
      return checked;
    }).length);
  }, [values]);
  const isIndeterminate = () => {
    return totalSelected > 0 && totalSelected < values.length;
  };
  const isCheckedAll = () => {
    return values.length > 0 && totalSelected === values.length;
  };
  const validateBeforeUncheck = checked => {
    if (checked) return true;
    return !(minimalChecks > 0 && totalSelected - 1 < minimalChecks);
  };
  const toggleCheckItem = event => {
    const option = event.target.value.pop();
    let newValues = [...values];
    if (option === OPTION_ALL) {
      const checked = !checkedAll;
      if (!checked && minimalChecks) {
        return;
      }
      values.forEach((_, index) => {
        newValues[index].checked = checked;
      });
      newValues = [...newValues];
      setCheckedAll(checked);
      setValues(newValues);
    } else {
      const found = values.find(item => item.key === option);
      if (found) {
        const foundIndex = values.indexOf(found);
        const checked = !found.checked;
        if (!validateBeforeUncheck(checked)) return;
        newValues[foundIndex].checked = checked;
      }
      setValues(newValues);
    }
    setCheckedAll(newValues.every(item => item.checked));
    onChange(newValues);
  };
  const allOptionLabel = () => {
    if (minimalChecks) {
      return checkAllOptionLabel;
    }
    return isCheckedAll() ? uncheckOptionLabel : checkAllOptionLabel;
  };
  return {
    values,
    selectDisplayValue,
    toggleCheckItem,
    isCheckedAll,
    isIndeterminate,
    allOptionLabel
  };
};
export default useCheckboxSelect;