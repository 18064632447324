import { useLayoutEffect, useMemo } from 'react';
import { UnitTime } from 'types/session';
import { convertTimeToMinutes } from 'utils/SessionUtils';
import useAnimationInterval from './useAnimationInterval';
const useModaNotifierCountdown = () => {
  let animation;
  const {
    progress,
    runAnimation,
    timeLeft
  } = useAnimationInterval();
  const updateAnimation = () => {
    animation = !animation && runAnimation();
  };
  const MINUTES = Math.floor(convertTimeToMinutes(UnitTime.SECONDS, timeLeft));
  const SECONDS = (timeLeft % 60).toString().padStart(2, '0');
  const formatedTimeLeft = useMemo(() => {
    return MINUTES ? "".concat(MINUTES, ":").concat(SECONDS) : SECONDS.padStart(4, '0:');
  }, [MINUTES, SECONDS]);
  useLayoutEffect(() => {
    updateAnimation();
    return () => {
      if (animation) {
        clearInterval(animation);
      }
    };
  }, []);
  return {
    progress,
    timeLeft: formatedTimeLeft
  };
};
export default useModaNotifierCountdown;