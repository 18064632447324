function useCombinedRefs() {
  for (var _len = arguments.length, refs = new Array(_len), _key = 0; _key < _len; _key++) {
    refs[_key] = arguments[_key];
  }
  return target => {
    refs.forEach(ref => {
      if (!ref) return;
      if (typeof ref === 'function') {
        ref(target);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = target;
      }
    });
  };
}
export default useCombinedRefs;