export default {
  MORE_THAN: 'Horário inicial não pode ser maior que o horário final.',
  CANNOT_BE_SAME: 'Horário inicial e final não podem ser iguais.',
  MORE_THAN_DATE: 'A data inicial não pode ser maior que a data final.',
  MORE_THAN_DATE_CURRENT: 'não pode ser maior que a data de hoje.',
  INVALID: 'Horário inválido.',
  INVALID_DATE: 'Data inválida.',
  OUT_OF_RANGE_FIRST: 'O horário não pode ser menor que',
  OUT_OF_RANGE_PAST_DATE: 'não pode ser menor que',
  OUT_OF_RANGE_FUTURE_DATE: 'não pode ser maior que',
  OUT_OF_RANGE_LAST: 'O horário não pode ser maior que',
  EMPTY: ''
};