function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Envs } from 'constants/envs';
import { LS_PURPOSE_OF_USE_KEY } from 'constants/utils';
import { axiosInstance } from './baseService';
class PurposeService {
  constructor() {
    _defineProperty(this, "getPurposes", productId => {
      return axiosInstance.get("".concat(Envs().CUSTOMER_API_URL, "/lgpd/purpose-of-use").concat(productId ? "?productId=".concat(productId) : '')).then(res => {
        const purposes = res.data;
        window.localStorage.setItem(LS_PURPOSE_OF_USE_KEY, JSON.stringify(purposes));
        return res;
      }).catch(err => {
        const cachedPurposesOfUse = window.localStorage.getItem(LS_PURPOSE_OF_USE_KEY);
        if (cachedPurposesOfUse) {
          return {
            data: JSON.parse(cachedPurposesOfUse)
          };
        }
        throw err;
      });
    });
  }
}
export default new PurposeService();