import { applyDefaultEnvsWhenFound, applySpecificEnvsWhenFound } from './applyEnvsWhenFound';
import env from './env';
const production = () => {
  const {
    API_GATEWAY_URL,
    NOTIFIER_API_URL
  } = applyDefaultEnvsWhenFound({
    API_GATEWAY_URL: 'https://portalv3-k8s-prd.assertivasolucoes.com.br',
    NOTIFIER_API_URL: 'https://portalv3-ws-prd.assertivasolucoes.com.br/notifier'
  });
  return applySpecificEnvsWhenFound({
    ...env(),
    // api
    API_URL: 'https://api.assertivasolucoes.com.br',
    API_GATEWAY_URL,
    CADASTRO_API_URL: "".concat(API_GATEWAY_URL, "/cadastro-backoffice"),
    CAMPAIGNS_API_URL: "".concat(API_GATEWAY_URL, "/cadastro-backoffice/campaigns"),
    CUSTOMER_API_URL: "".concat(API_GATEWAY_URL, "/backoffice-cliente"),
    COMUNIKA_API_URL: "".concat(API_GATEWAY_URL, "/prd-"),
    RECUPERE_API_URL: "".concat(API_GATEWAY_URL, "/prd-"),
    NOTIFIER_API_URL,
    NOTIFIER_CUSTOMER_API_URL: "".concat(NOTIFIER_API_URL, "/backoffice-cliente"),
    NOTIFIER_CADASTRO_API_URL: "".concat(NOTIFIER_API_URL, "/cadastro-backoffice"),
    REPORT_API_URL: 'https://ws-prd.assertivasolucoes.com.br',
    // apps
    AUTENTICA_URL: 'https://autentica.assertivasolucoes.com.br',
    ASSINATURAS_URL: 'https://assinaturas.assertivasolucoes.com.br',
    PAINEL_URL: 'http://painel.assertivasolucoes.com.br',
    LOCALIZE_URL: 'https://localize.assertivasolucoes.com.br',
    CADASTRO_URL: 'http://painel.assertivasolucoes.com.br/grupos-e-usuarios/grupos',
    SMS_URL: 'https://sms.assertivasolucoes.com.br',
    GEOTIMIZE_URL: 'https://geotimize.assertivasolucoes.com.br',
    BASECERTA_URL: 'https://basecerta.assertivasolucoes.com.br',
    CREDITOMIX_URL: 'https://creditomix.assertivasolucoes.com.br',
    VEICULOS_URL: 'https://veiculos.assertivasolucoes.com.br',
    RECUPERE_URL: 'https://recupere.assertivasolucoes.com.br',
    WEBPHONE_URL: 'https://webphone.assertivasolucoes.com.br',
    CREDITO_URL: 'https://credito.assertivasolucoes.com.br'
  });
};
export default production;