import React from 'react';
const useSecondsCounter = () => {
  const [maxRemainingTime, setMaxRemainingTime] = React.useState(0);
  const counterSecondsFinished = React.useCallback(() => {
    setMaxRemainingTime(0);
  }, []);
  React.useEffect(() => {
    const timer = maxRemainingTime > 0 && setInterval(() => {
      setMaxRemainingTime(maxRemainingTime - 1);
    }, 1000);
    if (maxRemainingTime === 0) {
      counterSecondsFinished();
    }
    return () => clearInterval(timer);
  }, [maxRemainingTime, setMaxRemainingTime, counterSecondsFinished]);
  return {
    maxRemainingTime,
    counterSecondsFinished,
    setMaxRemainingTime
  };
};
export default useSecondsCounter;