import { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';

// Utils
import { FunctionUtils } from 'utils';

// Contants
import { FILTERS_KEYS, initialFilters, initialPagination } from './constants';

// Types

const useUsersTable = _ref => {
  let {
    paginatedUsers,
    allUsers,
    pagination,
    handlePagination
  } = _ref;
  const [filters, setFilters] = useState(initialFilters);
  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const hasAppliedFilters = Object.values(filters).some(value => !_isEmpty(value));
  const removeEmptyProperties = obj => {
    const newObj = {
      ...obj
    };
    Object.keys(newObj).forEach(key => _isEmpty(newObj[key]) && delete newObj[key]);
    return newObj;
  };
  const filtersChips = removeEmptyProperties({
    Nome: filters.name,
    'E-mail': filters.email,
    Status: filters.userStatus,
    Grupo: filters.group,
    Perfil: filters.profile,
    'Autenticação por dois fatores': filters.mfaStatus
  });
  const handleGroupFilter = (appliedGroupFilter, userGroup) => {
    if (!appliedGroupFilter || !userGroup) return true;
    if (appliedGroupFilter !== userGroup) return false;
    return true;
  };
  const filteredUsers = allUsers && allUsers.length > 0 && hasAppliedFilters ? allUsers.filter(user => [filters].some(_ref2 => {
    var _user$mfa, _user$group;
    let {
      name,
      email,
      userStatus,
      group,
      profile,
      mfaStatus
    } = _ref2;
    if (name && user.name !== name || email && user.email !== email || userStatus && user.status.name !== userStatus || profile && user.profile.name !== profile || mfaStatus && ((_user$mfa = user.mfa) === null || _user$mfa === void 0 ? void 0 : _user$mfa.status) !== mfaStatus || !handleGroupFilter(group, (_user$group = user.group) === null || _user$group === void 0 ? void 0 : _user$group.name)) return false;
    return true;
  })) : [];
  const usersToRender = hasAppliedFilters ? FunctionUtils.sliceArrayPerPage(filteredUsers, pagination.page, pagination.rowsPerPage) : paginatedUsers;
  const toggleFiltersDrawer = () => setOpenFiltersDrawer(!openFiltersDrawer);
  const clearFilters = () => {
    setFilters(initialFilters);
  };
  const removeFilter = filterKey => {
    const newFilters = {
      ...filters
    };
    newFilters[FILTERS_KEYS[filterKey]] = '';
    setFilters(newFilters);
  };
  const applyFilters = newFilters => {
    setFilters({
      ...filters,
      ...newFilters
    });
    toggleFiltersDrawer();
  };
  const emptyFiltersResult = paginatedUsers.length > 0 && usersToRender.length === 0;
  useEffect(() => {
    handlePagination('page', initialPagination.page);
  }, [filters]);
  return {
    filters,
    filtersChips,
    emptyFiltersResult,
    hasAppliedFilters,
    usersToRender,
    filteredUsers,
    openFiltersDrawer,
    applyFilters,
    clearFilters,
    removeFilter,
    toggleFiltersDrawer
  };
};
export default useUsersTable;