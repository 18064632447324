import makeStyles from '@material-ui/core/styles/makeStyles';
export const useStyles = makeStyles(_ref => {
  let {
    spacing
  } = _ref;
  return {
    root: {
      marginTop: spacing(1),
      width: '100%'
    },
    button: {
      height: 40,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(1),
      paddingLeft: spacing(1.4),
      font: 'inherit',
      fontSize: '16px',
      outline: 'none'
    },
    purposesContainer: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& hr': {
        position: 'absolute',
        width: '100%',
        bottom: '-1px',
        left: 0
      }
    },
    purpose: {
      cursor: 'pointer',
      padding: '8px',
      borderRight: '8px solid #fff',
      borderLeft: '8px solid #fff',
      position: 'relative',
      '&:first-child': {
        borderTop: '8px solid #fff'
      },
      '&:last-child': {
        borderBottom: '8px solid #fff'
      },
      '&:hover': {
        backgroundColor: 'rgba(217, 217, 217, 0.38)'
      },
      '&.selected': {
        backgroundColor: 'rgba(188,188,188,0.38)'
      }
    }
  };
});