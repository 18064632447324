import { styled } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Text from 'components/Text';
export const Wrapper = styled(Box)({
  display: 'flex',
  backgroundColor: 'white',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
});
export const LinksWrapper = styled(Box)(_ref => {
  let {
    theme
  } = _ref;
  return {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: '0.75rem',
    [theme.breakpoints.between(0, 1300)]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: '0.875rem'
    }
  };
});
const commonLinkStyle = {
  color: '#00ADB3',
  lineHeight: '1.25rem',
  cursor: 'pointer',
  fontSize: '0.9375rem',
  fontWeight: 400,
  textDecoration: 'none',
  textAlign: 'center',
  '&:hover': {
    color: '#008282',
    transition: '.2 all'
  }
};
export const StyledText = styled(Text)(commonLinkStyle);
export const StyledLink = styled('a')(commonLinkStyle);