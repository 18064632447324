import Cookies from 'js-cookie';
import { BO_IDENTIFY, DEV_IDENTIFY, NodeEnvironments, TST_IDENTIFY } from 'constants/cookies';
import ProductUtils from './ProductUtils';

/**
 *
 * COOKIES UTILS
 *
 */

export const getCookieName = cookieName => {
  return "".concat(cookieName).concat(ProductUtils.isBackofficeInternoEnv() ? BO_IDENTIFY : '').concat(getSuffixByEnvironment());
};
export const getSuffixByEnvironment = () => {
  const {
    REACT_APP_APP_ENV
  } = process.env;
  switch (REACT_APP_APP_ENV) {
    case NodeEnvironments.DEV:
      return DEV_IDENTIFY;
    case NodeEnvironments.TST:
      return TST_IDENTIFY;
    default:
      return '';
  }
};

/**
 *
 * COOKIES DATA MANAGEMENT
 *
 */

const clearItem = (name, options) => {
  if (Cookies.get(name)) {
    Cookies.remove(name, options);
  }
};
const getItem = name => {
  return Cookies.get(name);
};
const saveItem = (name, value, options) => {
  try {
    Cookies.set(name, value, options);
  } catch (e) {
    console.error(e);
  }
};
const CookiesUtil = {
  saveItem,
  getItem,
  clearItem
};
export default CookiesUtil;