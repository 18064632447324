var _ArrayUtils;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import _find from 'lodash/find';
const checkIfValidArray = array => {
  if (!Array.isArray(array)) {
    throw new Error('[ArrayUtils] Array inválido ou não informado!');
  }
};
class ArrayUtils {}
_ArrayUtils = ArrayUtils;
_defineProperty(ArrayUtils, "findByParams", (array, params) => {
  return _find(array, params);
});
_defineProperty(ArrayUtils, "replaceByParams", (array, params, newItem) => {
  checkIfValidArray(array);
  const itemOld = _ArrayUtils.findByParams(array, params);
  if (itemOld) {
    array.splice(array.indexOf(itemOld), 1, newItem);
  } else {
    throw new Error('[ArrayUtils] Não foi possível atualizar o array. O item a ser atualizado não foi encontrado.');
  }
});
_defineProperty(ArrayUtils, "removeByParams", (array, params) => {
  checkIfValidArray(array);
  const item = _ArrayUtils.findByParams(array, params);
  if (item) {
    array.splice(array.indexOf(item), 1);
  } else {
    throw new Error('[ArrayUtils] O item a ser removido não foi encontrado.');
  }
});
/**
 * Retorna o item da list segundo o parâmetro id
 * @param {number|string} id
 * @param {Array} list
 */
_defineProperty(ArrayUtils, "getListItem", (id, list) => {
  if (!id) throw new Error('Obrigatório id');
  if (!list || !Array.isArray(list)) throw new Error('Obrigatório list do tipo Array');
  return list.find(item => item.id === id);
});
export default ArrayUtils;