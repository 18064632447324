function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import StringUtils from './StringUtils';
class ReportUtils {}
_defineProperty(ReportUtils, "shortPreviewSearchKey", searchKey => {
  if (!searchKey) {
    return '';
  }
  if ('cpf' in searchKey || 'cnpj' in searchKey) {
    const document = searchKey.cpf || searchKey.cnpj;
    return StringUtils.formatDocument(document) || document;
  }
  if ('cnpj' in searchKey) {
    return StringUtils.formatDocument(searchKey.cnpj) || searchKey.cnpj;
  }
  if ('telefone' in searchKey) {
    return StringUtils.formatPhone(searchKey.telefone) || searchKey.telefone;
  }
  if ('email' in searchKey) {
    return searchKey.email;
  }
  if ('documento' in searchKey) {
    return StringUtils.formatDocument(searchKey.documento) || searchKey.documento;
  }
  if ('nomeOuRazaoSocial' in searchKey || 'cepOuNomeRua' in searchKey) {
    return searchKey.nomeOuRazaoSocial || StringUtils.formatZipCode(searchKey.cepOuNomeRua, false) || searchKey.cepOuNomeRua;
  }
  if ('id' in searchKey) {
    return searchKey.id;
  }
  return '';
});
export default ReportUtils;