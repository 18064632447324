import { styled } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
export const StyledPaperContainer = styled(Paper)(_ref => {
  let {
    padding = 24
  } = _ref;
  return {
    width: '100%',
    padding: "".concat(padding, "px"),
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.20), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14)'
  };
});