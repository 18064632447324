import { useCallback, useState } from 'react';
import { AULoadingManager, AUNotifier } from 'components';
import ProfileService from 'services/ProfileService';
import { AuthUtils, DateUtils, ErrorUtils, FunctionUtils, ValidationUtils } from 'utils';
import history from 'utils/browserHistory';
import MESSAGES from 'containers/EditOwnProfile/constants/MessagesConstants';
import EditProfileUtils from 'containers/EditOwnProfile/utils/EditProfileUtils';
import useFormState from 'hooks/useFormState';
import moment from 'moment';
const useEditProfile = () => {
  const [openPasswordChangeModal, setOpenPasswordChangeModal] = useState(false);
  const [mfa, setMFA] = useState(null);
  const methods = useFormState(EditProfileUtils.getInitialProfileFormState());
  const [profileErrors, setProfileErrors] = useState(EditProfileUtils.getInitialProfileErrors());
  const togglePasswordChangeModal = () => {
    setOpenPasswordChangeModal(prev => !prev);
  };
  const {
    form,
    setValues,
    resetValues
  } = methods;
  const checkAllErrors = useCallback(() => {
    const actualErrors = EditProfileUtils.getInitialProfileErrors();
    if (!form.name) {
      actualErrors.hasError = true;
      actualErrors.name.message = MESSAGES.NAME_IS_REQUIRED;
    }
    const momentDate = DateUtils.getAsMoment(form.birthdate);
    if (form.birthdate && !(momentDate !== null && momentDate !== void 0 && momentDate.isValid())) {
      actualErrors.hasError = true;
      actualErrors.birthdate.message = MESSAGES.BIRTHDATE_IS_INVALID;
    }
    if (momentDate !== null && momentDate !== void 0 && momentDate.isValid()) {
      const maxPastDate = moment().locale('pt-br').subtract(100, 'years');
      const minPastDate = moment().locale('pt-br').subtract(10, 'years');
      const isVeryOld = momentDate.isBefore(maxPastDate);
      const isVeryYoung = momentDate.isAfter(minPastDate);
      if (isVeryOld || isVeryYoung) {
        actualErrors.hasError = true;
        actualErrors.birthdate.message = MESSAGES.BIRTHDATE_NOT_ALLOWED;
      }
    }
    if (AuthUtils.getUser().getCompany().mfa === false) {
      if (form.mobile && !ValidationUtils.isValidPhone(form.mobile, 'mobile')) {
        actualErrors.hasError = true;
        actualErrors.mobile.message = MESSAGES.MOBILE_IS_INVALID;
      }
    }
    if (form.phone && !ValidationUtils.isValidPhone(form.phone, 'landline')) {
      actualErrors.hasError = true;
      actualErrors.phone.message = MESSAGES.PHONE_IS_INVALID;
    }
    setProfileErrors({
      hasError: actualErrors.hasError,
      name: actualErrors.name,
      phone: actualErrors.phone,
      mobile: actualErrors.mobile,
      birthdate: actualErrors.birthdate
    });
    return actualErrors.hasError;
  }, [form.mobile, form.email, form.phone, form.name, form.birthdate]);
  const resetErrors = () => {
    setProfileErrors(EditProfileUtils.getInitialProfileErrors());
  };
  const clearErrors = name => {
    setProfileErrors(prev => ({
      ...prev,
      [name]: {
        message: ''
      }
    }));
  };
  const onLogout = data => {
    AuthUtils.logout().then(() => {
      history.push('/');
      AUNotifier.success(data === null || data === void 0 ? void 0 : data.message);
    });
  };
  const sendNewPassword = useCallback(_ref => {
    let {
      oldPassword = '',
      newPassword = '',
      emailShared
    } = _ref;
    const ASSERTIVA = '~ASSERTIVA~';
    const {
      email
    } = form;
    AULoadingManager.show();
    ProfileService.sendNewPassword({
      oldPassword: btoa(FunctionUtils.joinParamsWith(email || emailShared, oldPassword)(ASSERTIVA)),
      newPassword: btoa(FunctionUtils.joinParamsWith(newPassword, newPassword)(ASSERTIVA))
    }).then(_ref2 => {
      let {
        data
      } = _ref2;
      if (data !== null && data !== void 0 && data.mfa) {
        setMFA(data);
      } else {
        if (openPasswordChangeModal) {
          togglePasswordChangeModal();
        }
        onLogout(data);
      }
    }).catch(error => AUNotifier.error(ErrorUtils.normalizeErrorMessage(error))).finally(() => AULoadingManager.close());
  }, [form.email, openPasswordChangeModal]);
  const updateOwnProfile = useCallback(callback => {
    if (checkAllErrors()) return;
    const payload = EditProfileUtils.prepapeEditProfilePayload(form);
    AULoadingManager.show();
    ProfileService.updateOwnProfile(payload).then(response => {
      AUNotifier.success(response.data.message);
      callback(response);
    }).catch(error => {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
    }).finally(() => AULoadingManager.close());
  }, [checkAllErrors, form.birthdate, form.name, form.mobile, form.phone]);
  const getOwnProfile = useCallback(() => {
    AULoadingManager.show();
    ProfileService.getOwnProfile().then(response => {
      setValues(response.data);
    }).catch(() => {
      resetValues();
    }).finally(() => {
      AULoadingManager.close();
    });
  }, []);
  return {
    ...methods,
    openPasswordChangeModal,
    togglePasswordChangeModal,
    sendNewPassword,
    updateOwnProfile,
    getOwnProfile,
    checkAllErrors,
    profileErrors,
    resetErrors,
    clearErrors,
    onLogout,
    mfa
  };
};
export default useEditProfile;