function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import MESSAGES from 'constants/messages';
import parse from 'html-react-parser';
class ErrorUtils {
  static normalizeErrorMessage(error) {
    var _error$response;
    let defaultMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : MESSAGES.GENERIC_ERROR;
    let message = '';
    if (error !== null && error !== void 0 && (_error$response = error.response) !== null && _error$response !== void 0 && _error$response.data) {
      const {
        data
      } = error.response;
      message = data.message || data.mensagem || data.resposta || data.error_description || data.error;
    }
    return message || defaultMessage;
  }
}
_defineProperty(ErrorUtils, "isControlledUncontrolledInput", (value, defaultValue) => {
  const isControlled = value !== null && value !== undefined;
  if (isControlled && defaultValue) {
    console.error(MESSAGES.CONTROLLED_UNCONTROLLED_INPUT_ERROR);
  }
  return isControlled;
});
_defineProperty(ErrorUtils, "handleMessageBreakRow", function (message) {
  let separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '. ';
  const splittedMessage = message.split(separator);
  return parse(splittedMessage.join('.<br>'));
});
export default ErrorUtils;