import { styled } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
export const StyledBackdrop = styled(Backdrop)({
  height: 'calc(100vh - 64px)',
  top: 64,
  zIndex: 1501
});
export const StyledAlertContainer = styled(Box)(_ref => {
  let {
    theme
  } = _ref;
  return {
    width: 300,
    borderRadius: '4px 4px 0px 0px',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: 88,
      left: 58
    }
  };
});
export const StyledAlertHeader = styled(Box)(_ref2 => {
  let {
    theme
  } = _ref2;
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: '#F5F5F5',
    padding: theme.spacing(3, 4)
  };
});
export const StyledButton = styled(Button)({
  minWidth: 0,
  padding: 0
});
export const StyledAlertContent = styled(Box)(_ref3 => {
  let {
    theme
  } = _ref3;
  return {
    padding: theme.spacing(2, 4, 4),
    background: '#FFF',
    color: '#212121',
    fontSize: theme.typography.pxToRem(16),
    '& h2': {
      margin: theme.spacing(0, 0, 3),
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      color: '#8A00C1'
    },
    '& p': {
      margin: theme.spacing(0, 0, 3),
      '&:last-child': {
        marginBottom: 0
      }
    },
    '& strong': {
      fontWeight: 700
    }
  };
});