import { useEffect, useState } from 'react';
import { AuthUtils } from 'utils';
import { isUndefined } from 'lodash';
import TrialService from 'services/TrialService';
export default function useTrialBalance() {
  const user = AuthUtils.getUser();
  const [balance, setBalance] = useState(undefined);
  useEffect(() => {
    let intervalId;
    if (user && user.isTrial() && isUndefined(balance)) {
      intervalId = setTimeout(() => {
        TrialService.getBalance().then(response => setBalance(response.data)).catch(error => console.error(error));
      }, 225);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return {
    balance
  };
}