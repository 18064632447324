import { styled } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
const navbarHeight = '64px';
export const StyledCentralizedWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
}));
export const StyledMessage = styled(Typography)(_ref => {
  let {
    theme
  } = _ref;
  return {
    color: '#F5F5F5',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 400,
    letterSpacing: '0.18px',
    lineHeight: '150%',
    textAlign: 'center'
  };
});
export const StyledMessageWrapper = styled(Box)(() => ({
  background: 'linear-gradient(126.86deg, #A124FA 5.09%, #BA03EF 87.45%)',
  borderRadius: '6px',
  boxShadow: '0px 2.96721px 2.96721px rgba(0, 0, 0, 0.14), 0px 4.45082px 1.48361px rgba(0, 0, 0, 0.12), 0px 1.48361px 7.41803px rgba(0, 0, 0, 0.2)',
  padding: '2.5rem',
  margin: 'auto',
  maxWidth: '826px'
}));
export const StyledTitle = styled(Typography)(_ref2 => {
  let {
    theme
  } = _ref2;
  return {
    color: '#8A00C1',
    fontSize: theme.typography.pxToRem(48),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(56),
    textAlign: 'center',
    textTransform: 'uppercase'
  };
});
export const StyledWrapper = styled(Paper)(() => ({
  backgroundColor: 'white',
  borderRadius: '4px',
  minHeight: "calc(100vh - ".concat(navbarHeight, ")"),
  padding: ' 24px',
  width: '100%'
}));