// FIXME: resolver problemas do eslint após refact.
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import isNull from 'lodash/isNull';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');
class DateUtils {
  static getTimeStamp() {
    return new Date().getTime();
  }
  static moment(date) {
    return moment(date);
  }
  static getAsMoment(date) {
    let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD/MM/YYYY';
    if (isNull(date)) return null;
    if (moment.isMoment(date)) {
      return date;
    }
    return moment(date, format);
  }
  static setTimer(_ref) {
    let {
      hour,
      minute
    } = _ref;
    return moment().set({
      hour,
      minute,
      second: 0,
      millisecond: 0
    }).toISOString();
  }
  static getTimer(date) {
    return this.moment(date).format('hh:mm');
  }
  static dateStringToISO(strDate) {
    const isDate = this.getFormatPtBr(strDate);
    if (isDate.isValid()) {
      return isDate.toISOString();
    }
    const newDate = this.moment(strDate);
    if (newDate.isValid()) {
      return newDate.toISOString();
    }
    throw new Error('Erro! Data Inválida. <dateUtils.js>');
  }
  static getFormatPtBr(strDate) {
    return moment(strDate, 'DD/MM/YYYY');
  }
  static convertDateBrToMoment(date) {
    if (!date) return moment();
    const dateSplited = date.split('/');
    return moment("".concat(dateSplited[2], "-").concat(dateSplited[1], "-").concat(dateSplited[0]));
  }
}
export default DateUtils;