export const ELEMENTS = {
  CENTRAL_AJUDA: 'central-ajuda',
  MINHA_PLATAFORMA: 'minha-plataforma',
  PROFILE: 'perfil',
  SAIR: 'sair',
  TOGGLE_BUTTON: 'toggle-button'
};
export const SUFIXES = {};
export const PREFIXES = {
  NAVBAR_ITEM: 'navbar-item',
  SIDEBAR: 'sidebar',
  SIDEBAR_ITEM: 'sidebar-item',
  SIDEBAR_SUBITEM: 'sidebar-subitem'
};